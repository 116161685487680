import React, { useEffect, useState } from 'react'
import JobsList from '../jobs/components/JobList';
import jobService from '../../services/JobService';
import companyService from '../../services/CompanyService';
import {HTTP_RESPONSE_STATUS} from '../../constants/http';
import CompanyList from '../companies/components/CompanyList';
const Talents = () => {
    const [latestJobs, setLatestJobs] = useState([]);
    const [companies, setCompanies] = useState([
    ]);

    const [isLoading, setIsLoading] = useState(false);
    const loadLatestJobs = () => {
        setIsLoading(true);
        jobService.getLatestJobs()
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setLatestJobs(resObj.data);
                } else {
                    setLatestJobs([]);
                }
            }
        })
        .catch(err => {
            setLatestJobs([]);
            setIsLoading(false);
        });
    };
    const loadFeaturedCompanies =() => {
        setIsLoading(true);
        companyService.getFeaturedCompanies()
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setCompanies(resObj.data);
                } else {
                    setCompanies([]);
                }
            }
        })
        .catch(err => {
            setCompanies([]);
            setIsLoading(false);
        });
    }
    useEffect(()=> {
        loadLatestJobs();
        loadFeaturedCompanies();
    }, []);
    return (
        <>
            <div className="why header_wrapper">
            </div>
            
            <div class="services" style={{background: "#eff3f7"}}>
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <div class="section_title_container">
                                <div class="section_subtitle">Tìm việc kỷ nguyên số</div>
                                <div class="section_title"><h2>Qui trình thực hiện </h2></div>
                            </div>
                        </div>
                    </div>
                    <div class="row services_row">                    
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <a href="https://me.topworks.vn/register?accType=1" target="_blank">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/user.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                    </div>
                                    <div class="service_title">Tạo tài khoản</div>
                                    <div class="service_text">
                                        <p>Tạo một tài khoản để sử dụng các dịch vụ được cá nhân hoá cho bạn.</p>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <a href="https://me.topworks.vn/account/resume-dashboard" target="_blank">

                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/curriculum.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                    </div>
                                    <div class="service_title">Cập nhật hồ sơ</div>
                                    <div class="service_text">
                                        <p>Cập nhật một hồ sơ đầy đủ về kiến thức, kỹ năng, kinh nghiệm làm việc...</p>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                <a href="/jobs">

                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/job-offer.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
    
                                </div>
                                <div class="service_title">Công việc phù hợp</div>
                                <div class="service_text">
                                    <p>Nền tảng công nghệ và đội ngũ chúng tôi sẽ tìm các công việc phù hợp nhất cho bạn</p>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="services">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <div class="section_title_container">
                                <div class="section_subtitle">Việc làm</div>
                                <div class="section_title"><h2>Công việc mới nhất</h2></div>
                            </div>
                        </div>
                    </div>
                    <div class="row services_row">     
                        <div className="col">
                            <div className="listings-container compact-list-layout margin-top-35">
                                <JobsList items={latestJobs}/>
                            </div>   
                        </div>               
                    </div>
                </div>
            </div>
            <div class="cta">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="cta_container d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start">
                                <div class="cta_content">
                                    <div class="cta_title">Gọi chúng tôi ngay bây giờ!</div>
                                    <div class="cta_text">Nhận được tư vấn và ưu đãi khi sử dụng dịch vụ của chúng tôi</div>
                                </div>
                                <div class="cta_phone ml-lg-auto">+84 90 866 77 96</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="services">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <div class="section_title_container">
                                <div class="section_subtitle">Tuyển dụng</div>
                                <div class="section_title"><h2>Công ty nổi bật</h2></div>
                            </div>
                        </div>
                    </div>
                    <div class="row services_row">                    
                        <div className="col">
                            <div className="companies-list">
                                <CompanyList items={companies}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default Talents


/**
 * <div class="slick-list draggable"><div class="slick-track" style={{opacity: 1, width: "2424px", transform: "translate3d(0px, 0px, 0px)"}}>
            <div class="freelancer slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false" style={{width: "334px"}} tabindex="0">

<div class="freelancer-overview">
    <div class="freelancer-overview-inner">
        
        <span class="bookmark-icon"></span>
        
        <div class="freelancer-avatar">
            <div class="verified-badge"></div>
            <a href="single-freelancer-profile.html" tabindex="0"><img src="images/user-avatar-big-01.jpg" alt=""/></a>
        </div>

        <div class="freelancer-name">
            <h4><a href="single-freelancer-profile.html" tabindex="0">Tom Smith <img class="flag" src="images/flags/gb.svg" alt="" data-tippy-placement="top" data-tippy="" data-original-title="United Kingdom"/></a></h4>
            <span>UI/UX Designer</span>
        </div>

        <div class="freelancer-rating">
            <div class="star-rating" data-rating="5.0"><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span></div>
        </div>
    </div>
</div>

<div class="freelancer-details">
    <div class="freelancer-details-list">
        <ul>
            <li>Location <strong><i class="icon-material-outline-location-on"></i> London</strong></li>
            <li>Rate <strong>$60 / hr</strong></li>
            <li>Job Success <strong>95%</strong></li>
        </ul>
    </div>
    <a href="single-freelancer-profile.html" class="btn btn-primary d-block" tabindex="0">View Profile <i class="icon-material-outline-arrow-right-alt"></i></a>
</div>
</div>
<div class="freelancer slick-slide slick-active" data-slick-index="1" aria-hidden="false" style={{width: "374px"}} tabindex="0">

<div class="freelancer-overview">
    <div class="freelancer-overview-inner">
        
        <span class="bookmark-icon"></span>
        
        <div class="freelancer-avatar">
            <div class="verified-badge"></div>
            <a href="single-freelancer-profile.html" tabindex="0"><img src="images/user-avatar-big-02.jpg" alt=""/></a>
        </div>

        <div class="freelancer-name">
            <h4><a href="#" tabindex="0">David Peterson <img class="flag" src="images/flags/de.svg" alt="" data-tippy-placement="top" data-tippy="" data-original-title="Germany"/></a></h4>
            <span>iOS Expert + Node Dev</span>
        </div>

        <div class="freelancer-rating">
            <div class="star-rating" data-rating="5.0"><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span></div>
        </div>
    </div>
</div>

<div class="freelancer-details">
    <div class="freelancer-details-list">
        <ul>
            <li>Location <strong><i class="icon-material-outline-location-on"></i> Berlin</strong></li>
            <li>Rate <strong>$40 / hr</strong></li>
            <li>Job Success <strong>88%</strong></li>
        </ul>
    </div>
    <a href="single-freelancer-profile.html" class="button button-sliding-icon ripple-effect" tabindex="0" style={{width: "334px"}}>View Profile <i class="icon-material-outline-arrow-right-alt"></i></a>
</div>
</div><div class="freelancer slick-slide slick-active" data-slick-index="2" aria-hidden="false" style={{width: "334px"}} tabindex="0">

<div class="freelancer-overview">
    <div class="freelancer-overview-inner">
        <span class="bookmark-icon"></span>        
        <div class="freelancer-avatar">
            <a href="single-freelancer-profile.html" tabindex="0"><img src="images/user-avatar-placeholder.png" alt=""/></a>
        </div>
        <div class="freelancer-name">
            <h4><a href="#" tabindex="0">Marcin Kowalski <img class="flag" src="images/flags/pl.svg" alt="" data-tippy-placement="top" data-tippy="" data-original-title="Poland"/></a></h4>
            <span>Front-End Developer</span>
        </div>

        <div class="freelancer-rating">
            <div class="star-rating" data-rating="4.9"><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span></div>
        </div>
    </div>
</div>

<div class="freelancer-details">
    <div class="freelancer-details-list">
        <ul>
            <li>Location <strong><i class="icon-material-outline-location-on"></i> Warsaw</strong></li>
            <li>Rate <strong>$50 / hr</strong></li>
            <li>Job Success <strong>100%</strong></li>
        </ul>
    </div>
    <a href="single-freelancer-profile.html" class="button button-sliding-icon ripple-effect" tabindex="0" style={{width: "334px"}}>View Profile <i class="icon-material-outline-arrow-right-alt"></i></a>
</div>
</div><div class="freelancer slick-slide" data-slick-index="3" aria-hidden="true" style={{width: "334px"}} tabindex="-1">

<div class="freelancer-overview">
        <div class="freelancer-overview-inner">
        <span class="bookmark-icon"></span>
        
        <div class="freelancer-avatar">
            <div class="verified-badge"></div>
            <a href="single-freelancer-profile.html" tabindex="-1"><img src="images/user-avatar-big-03.jpg" alt=""/></a>
        </div>

        <div class="freelancer-name">
            <h4><a href="#" tabindex="-1">Sindy Forest <img class="flag" src="images/flags/au.svg" alt="" data-tippy-placement="top" data-tippy="" data-original-title="Australia"/></a></h4>
            <span>Magento Certified Developer</span>
        </div>

        <div class="freelancer-rating">
            <div class="star-rating" data-rating="5.0"><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span></div>
        </div>
    </div>
</div>

<div class="freelancer-details">
    <div class="freelancer-details-list">
        <ul>
            <li>Location <strong><i class="icon-material-outline-location-on"></i> Brisbane</strong></li>
            <li>Rate <strong>$70 / hr</strong></li>
            <li>Job Success <strong>100%</strong></li>
        </ul>
    </div>
    <a href="single-freelancer-profile.html" class="button button-sliding-icon ripple-effect" tabindex="-1" style={{width: "334px"}}>View Profile <i class="icon-material-outline-arrow-right-alt"></i></a>
</div>
</div><div class="freelancer slick-slide" data-slick-index="4" aria-hidden="true" style={{width: "334px"}} tabindex="-1">

<div class="freelancer-overview">
        <div class="freelancer-overview-inner">
        <span class="bookmark-icon"></span>
        
        <div class="freelancer-avatar">
            <a href="single-freelancer-profile.html" tabindex="-1"><img src="images/user-avatar-placeholder.png" alt=""/></a>
        </div>

        <div class="freelancer-name">
            <h4><a href="#" tabindex="-1">Sebastiano Piccio <img class="flag" src="images/flags/it.svg" alt="" data-tippy-placement="top" data-tippy="" data-original-title="Italy"/></a></h4>
            <span>Laravel Dev</span>
        </div>

        <div class="freelancer-rating">
            <div class="star-rating" data-rating="4.5"><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span><span class="star half"></span></div>
        </div>
    </div>
</div>

<div class="freelancer-details">
    <div class="freelancer-details-list">
        <ul>
            <li>Location <strong><i class="icon-material-outline-location-on"></i> Milan</strong></li>
            <li>Rate <strong>$80 / hr</strong></li>
            <li>Job Success <strong>89%</strong></li>
        </ul>
    </div>
    <a href="single-freelancer-profile.html" class="button button-sliding-icon ripple-effect" tabindex="-1" style={{width: "334px"}}>View Profile <i class="icon-material-outline-arrow-right-alt"></i></a>
</div>
</div><div class="freelancer slick-slide" data-slick-index="5" aria-hidden="true" style={{width: "334px"}} tabindex="-1">

<div class="freelancer-overview">
        <div class="freelancer-overview-inner">
        <span class="bookmark-icon"></span>
        
        <div class="freelancer-avatar">
            <a href="single-freelancer-profile.html" tabindex="-1"><img src="images/user-avatar-placeholder.png" alt=""/></a>
        </div>

        <div class="freelancer-name">
            <h4><a href="#" tabindex="-1">Gabriel Lagueux <img class="flag" src="images/flags/fr.svg" alt="" data-tippy-placement="top" data-tippy="" data-original-title="France"/></a></h4>
            <span>WordPress Expert</span>
        </div>

        <div class="freelancer-rating">
            <div class="star-rating" data-rating="5.0"><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span></div>
        </div>
    </div>
</div>

<div class="freelancer-details">
    <div class="freelancer-details-list">
        <ul>
            <li>Location <strong><i class="icon-material-outline-location-on"></i> Paris</strong></li>
            <li>Rate <strong>$50 / hr</strong></li>
            <li>Job Success <strong>100%</strong></li>
        </ul>
    </div>
    <a href="single-freelancer-profile.html" class="button button-sliding-icon ripple-effect" tabindex="-1" style={{width: "334px"}}>View Profile <i class="icon-material-outline-arrow-right-alt"></i></a>
</div>
</div></div></div>
        </div>
 */