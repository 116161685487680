import React from 'react'
import { Switch as RouterSwitch, Route, Redirect } from 'react-router-dom'
import About from './pages/About';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Contact from './pages/Contact';
import FAQ from '../views/helps/FAQ';
import Helps from '../views/helps/Helps';
import Home from './home/Home';
import Blog from './blogs/Blog';
import BlogDetails from './blogs/BlogDetails';

import Services from './services/Services'
import Numerology from './numerology/Numerology'
import NumerologyProfileSummary from './numerology/ProfileSummary'
import Recruiters from './recruiters/Recruiters' 
import Companies from './companies/Companies'
import CompanyDetails from './companies/CompanyDetails'
import Jobs from './jobs/Jobs'
import JobDetails from './jobs/JobDetails'
import JobsFinder from './jobs/JobsFinder'

import Talents from './talents/Talents';
import TalentProfile from './talents/Profile';
import Login from './auth/Login';

import NotFound from '../views/pages/errors/NotFound';
import ServerError from '../views/pages/errors/ServerError';
import UnAuthorization from '../views/pages/errors/UnAuthorization';
import Promotions from '../views/promotions/Promotions';
import PromotionDetails from '../views/promotions/PromotionDetails';
import Products from '../views/products/Products';
import ProductDetails from '../views/products/ProductDetails';
import Pricing from '../views/pricing/Pricing';

const Layout = ({props}) => (
	<RouterSwitch>
		<Route exact path="/numerology" component={Numerology}/>
		<Route exact path="/numerology/profile-summary/:uuid" component={NumerologyProfileSummary}/>
        <Route exact path="/about" component={About}/>
		<Route exact path="/terms" component={Terms}/>
		<Route path="/helps" component={Helps}/>
		<Route exact path="/faq" component={FAQ}/>
		<Route exact path="/privacy" component={Privacy}/>
		<Route exact path="/make-appointment" component={Contact}/>
		<Route exact path="/contact" component={Contact}/>
		<Route exact path="/blogs" component={Blog}/>
		<Route exact path="/blogs/preview/details/:id" component={BlogDetails}/>
		<Route exact path="/blogs/details/:id" component={BlogDetails}/>
		<Route exact path="/services" component={Services}/>
		<Route exact path="/recruiters" component={Recruiters}/>
		<Route exact path="/jobs" component={Jobs}/>
		<Route exact path="/jobs/details/:id" component={JobDetails}/>
		<Route exact path="/jobs/finder" component={JobsFinder}/>
		<Route exact path="/jobs/it-viec" component={JobsFinder}/>
		<Route exact path="/jobs/top-dev" component={JobsFinder}/>
		<Route exact path="/jobs/vietnam-works" component={JobsFinder}/>
		<Route exact path="/companies" component={Companies}/>
		<Route exact path="/companies/details/:id" component={CompanyDetails}/>
		<Route exact path="/companies/:id" component={CompanyDetails}/>
		<Route exact path="/talents" component={Talents}/>
		<Route exact path="/talents/:id/profile" component={TalentProfile}/>
		<Route exact path="/promotions" component={Promotions}/>
		<Route exact path="/promotions/preview/details/:id" component={PromotionDetails}/>
		<Route exact path="/promotions/details/:id" component={PromotionDetails}/>
		<Route exact path="/products" component={Products}/>
		<Route exact path="/products/preview/details/:id" component={ProductDetails}/>
		<Route exact path="/products/details/:id" component={ProductDetails}/>
		<Route exact path="/pricing" component={Pricing}/>
		<Route exact path="/login" component={Login}/>
		<Route exact path="/make-an-appointment" component={Contact}/>
		<Route exact path="/404" component={NotFound}/>
		<Route exact path="/500" component={ServerError}/>
		<Route exact path="/403" component={UnAuthorization}/>
		<Route exact path="/" component={Home}/>
		<Route path="*" component={NotFound}/>
	</RouterSwitch>
)
export default Layout