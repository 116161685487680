import React, { useState, useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import CallToAction from '../../containers/CallToAction';
import CompanyList from '../companies/components/CompanyList';
import companyService from '../../services/CompanyService';
import {HTTP_RESPONSE_STATUS} from '../../constants/http';
const Recruiters = () => {
    const [companies, setCompanies] = useState([]);
    useEffect(()=> {
        companyService.getFeaturedCompanies()
        .then(response=> {
            if (response.status === HTTP_RESPONSE_STATUS.OK) {
                setCompanies([...response.data])
            }
          }).catch(err => {
      
          });
    },[]);
    return (
    <>
        <div className="why header_wrapper">
		{/*<div class="background_image" style="background-image:url(images/why.jpg)"></div>*/}
            <div className="container">
                <div className="row row-eq-height">
                    <div className="col-lg-6 order-lg-1 order-2">
                        <div className="why_image_container">
                            <div className="why_image"><img src="/images/home-background-02.jpg" alt=""/></div>
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-2 order-1">
                        <div className="why_content">
                            <div className="section_title_container">
                                <div className="section_subtitle">Tuyển dụng</div>
                                <div className="section_title"><h2>Kỷ nguyên số</h2></div>
                            </div>
                            <div className="why_text">
                                <p><strong>Tuyển dụng nhân tài</strong> là bài toán quang trọng và nan giải của <strong>các doanh nghiệp</strong> trong bất kỳ thời điểm nào. Chọn đúng nhân tài phù hợp với sự phát triển doanh nghiệp luôn làm các nhà quản trị trăn trở.
                                    <br/>Ngày nay, với sự phát triển của khoa học máy tính, việc số hoá toàn bộ hồ sơ ứng viên và bản mô tả công việc một cách nhanh chóng. Sự phát triển bùng nổ của các kỹ thuật trí tuệ nhân tạo, khoa học dữ liệu và các hệ thông thông minh giúp đánh giá bản mô tả công việc và hồ sơ ứng viên một cách chính xác đáng kinh ngạc.
                                    <br/>
                                   <strong>TopWORKS</strong> là nền tảng công nghệ kết nối nhân tài và doanh nghiệp được chúng tôi nghiên cứu dựa trên kỹ thuật về <strong>trí tuệ nhân tạo, hiểu ngôn ngữ tự nhiên và thần số học</strong> về khám phá bản thân cá nhân.
                                   <br/>Chúng tôi giúp cá nhân hiểu rõ bản thân và phát triển nghề nghiệp một cách hiệu quả.
                                   <br/>Chúng tôi giúp doanh nghiệp tìm đúng nhân tài và phát triển hệ thống nhân sự một cách bền vững.</p>

                            </div>
                            {/*
                            <div class="why_list">
                                <ul>
                                    <li class="d-flex flex-row align-items-center justify-content-start">
                                        <div class="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div class="icon"><img src="images/icon_1.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div class="why_list_content">
                                            <div class="why_list_title">Tự khám phá</div>
                                            <div class="why_list_text">Etiam ac erat ut enim maximus accumsan vel ac nisl</div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-row align-items-center justify-content-start">
                                        <div class="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div class="icon"><img src="images/icon_2.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div class="why_list_content">
                                            <div class="why_list_title">Không tâm linh</div>
                                            <div class="why_list_text">Ac erat ut enim maximus accumsan vel ac</div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-row align-items-center justify-content-start">
                                        <div class="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div class="icon"><img src="images/icon_3.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div class="why_list_content">
                                            <div class="why_list_title">Không kỳ vọng</div>
                                            <div class="why_list_text">Etiam ac erat ut enim maximus accumsan vel</div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-row align-items-center justify-content-start">
                                        <div class="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div class="icon"><img src="images/icon_3.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div class="why_list_content">
                                            <div class="why_list_title">Không chủ quan</div>
                                            <div class="why_list_text">Etiam ac erat ut enim maximus accumsan vel</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="services">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <div className="section_title_container">
                            <div className="section_subtitle">TopWORKS</div>
                            <div className="section_title"><h2>Nâng cao hiệu quả qui trình tuyển dụng</h2></div>
                        </div>
                    </div>
                </div>
                <div className="row services_row">                    
                    <div className="col-xl-4 col-md-6 service_col">
                        <div className="service text-center">
                            <div className="service">
                                <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div className="icon"><img src="images/icon_4.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                </div>
                                <div className="service_title">Bắt đầu</div>
                                <div className="service_text">
                                    <p>Kết nối với các tài năng mỗi ngày, tất cả đều được chuẩn bị trước cho nhu cầu của bạn</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6 service_col">
                        <div className="service text-center">
                            <div className="service">
                                <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div className="icon"><img src="images/icon_5.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                </div>
                                <div className="service_title">Đối sánh nhanh, kết quả tuyệt vời</div>
                                <div className="service_text">
                                    <p>Thuật toán thông minh giúp tạo tập ứng viên chất lượng theo nhu cầu tuyển dụng</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 service_col">
                        <div class="service text-center">
                            <div class="service">
                                <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div class="icon"><img src="/images/icon_6.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                </div>
                                <div class="service_title">Phản hồi cho bạn</div>
                                <div class="service_text">
                                    <p>Xây dụng đội ngũ trong mơ với người quản lý tận tâm hỗ trợ bạn từ A-Z.</p>
                                </div>
                            </div>
                        </div>
                    </div>
			    </div>
		    </div>        
	    </div>
        <CallToAction />
        <div class="services" style={{background: "#eff3f7"}}>
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <div class="section_title_container">
                                <div class="section_subtitle">Tuyển dụng</div>
                                <div class="section_title"><h2>Công ty nổi bật</h2></div>
                            </div>
                        </div>
                    </div>
                    <div class="row services_row">                    
                        <div className="col">
                            <div className="companies-list">
                                <CompanyList items={companies}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="services">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <div class="section_title_container">
                                <div class="section_subtitle">Đối sánh công việc với TopWORKS</div>
                                <div class="section_title"><h2>Tập trung phát triển doanh nghiệp, hãy để chúng tôi tìm đúng người cho bạn</h2></div>
                            </div>
                        </div>
                    </div>
                    <div class="row services_row">                    
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/icon_4.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                    </div>
                                    <div class="service_title">Hiểu ngôn ngữ tự nhiên</div>
                                    <div class="service_text">
                                        <p>Hiểu mô tả nội dung bản công việc và hồ sơ ứng viên theo nhiều góc cạnh khác nhau.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="images/icon_5.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                    </div>
                                    <div class="service_title">Thuật toán đối sánh</div>
                                    <div class="service_text">
                                        <p>Đánh giá độ thích hợp giữa bản mô tả công việc và hồ sơ ứng viên.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="images/icon_7.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                    </div>
                                    <div class="service_title">Thần số học</div>
                                    <div class="service_text">
                                        <p>Đánh giá các đặc tính của ứng viên dựa trên các báo cáo số học chi tiết.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>        
        </div>
        <div class="services" style={{background: "#eff3f7"}}>
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <div class="section_title_container">
                            <div class="section_subtitle">Đăng tin tuyển dụng</div>
                            <div class="section_title"><h2>Qui trình thực hiện </h2></div>
                        </div>
                    </div>
                </div>
                <div class="row services_row">                    
                    <div class="col-xl-4 col-md-6 service_col">
                        <div class="service text-center">
                            <div class="service">
                                <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div class="icon"><img src="/images/user.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                </div>
                                <div class="service_title">Tạo tài khoản</div>
                                <div class="service_text">
                                    <p>Tạo một tài khoản để sử dụng các dịch vụ được cá nhân hoá cho doanh nghiệp của bạn.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 service_col">
                        <div class="service text-center">
                            <div class="service">
                                <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div class="icon"><img src="/images/job-offer.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                </div>
                                <div class="service_title">Đăng tin tuyển dụng</div>
                                <div class="service_text">
                                    <p>Đăng tin tuyển dụng một cách chuyển nghiệp để tim kiếm tài năng phù hợp từ nền tảng TopWorks.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 service_col">
                        <div class="service text-center">
                            <div class="service">
                                <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div class="icon"><img src="/images/latents.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
							</div>
							<div class="service_title">Chọn tài năng</div>
							<div class="service_text">
								<p>TopWorks sẽ cung cấp cho bạn những tài năng phù hợp nhất để tuyển dụng.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        </div>
        <div class="prices">
            <div class="container">
                <div class="row mb-5">
                    <div class="col text-center">
                        <div class="section_title_container">
                            <div class="section_subtitle">TopWORKS</div>
                            <div class="section_title"><h2>Dịch vụ phục vụ nhu cầu tuyển dụng</h2></div>                            
                        </div>
                    </div>
                </div>
                <div class="row">                    
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Nguồn tài năng</div>
                            <div class="price_text">
                                <p>Đăng tin tuyển dụng và xem ứng viên phù hợp. Truy cập hồ sơ ứng viên từ DrWORK</p>
                                <ul>
                                    <li>Truy cập cơ sở dữ liệu hồ sơ</li>
                                    <li>Công nghệ AI đối sánh JD và Résumes</li>
                                    <li>Bộ lọc hiện đại, trao đổi với ứng viên</li>
                                    <li>Tải hồ sơ ứng viên</li>
                                </ul>
                            </div>
                            <div class="price_panel">Liên hệ</div>
                        </div>
                    </div>
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Săn tài năng</div>
                            <div class="price_text">
                                <p>Hãy để chúng tôi lo mọi thứ. Chỉ cần trả một khoản phí phần trăm cho mỗi lần thuê thành công.</p>
                                <ul>
                                    <li>Dịch vụ quản lý tuyển dụng theo nhu cầu</li>
                                    <li>Truy cập tập ứng viên săn sàn nhận việc</li>
                                </ul>
                            </div>
                            <div class="price_panel">Liên hệ</div>
                        </div>
                    </div>
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Quản trị nhân sự</div>
                            <div class="price_text">
                                <p>Thuê nhân tài theo nhu cầu hoặc từng dự án. Trả phí quản lý theo phần trăm.</p>
                            </div>
                            <div class="price_panel">Liên hệ</div>
                        </div>
                    </div>
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Sự kiện</div>
                            <div class="price_text">
                                <p>Theo nhu cầu tuyển dụng.</p>
                            </div>
                            <div class="price_panel">Liên hệ</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}
export default withTranslation()(Recruiters);