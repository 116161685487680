import React, { useState } from 'react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import {NEWS_DEFAULT_FEATURE_IMAGE}  from '../../../constants';
import {
    Link
  } from "react-router-dom";
const TrendingPostItem = ({t , item}) => {
    return (
        <li>
            <a className="widget-content" href={"/blogs/details/" + item.id}>
                <img src={item.featuredImageUrl?item.featuredImageUrl:NEWS_DEFAULT_FEATURE_IMAGE} alt={item.title} loading="lazy"/>
                <div class="widget-text">
                    <h5>{item.title}</h5>
                    <span>{moment(item.publishedAt).format('YYYY-MM-DD')}</span>
                </div>
            </a>
        </li>
    )
};
export default withTranslation()(TrendingPostItem)