///https://cool.zetgoo.com/api/zcore/countries/v1/243
import format from 'string-format';

const COUNTRY_API_V1 = {
    getAllCountries: "https://cool.zetgoo.com/api/zcore/countries/v1",
    getCountry: "https://cool.zetgoo.com/api/zcore/countries/v1/{0}",
    getAllCitiesByCountryId: "https://cool.zetgoo.com/api/zcore/countries/v1/{0}/cities"
};
export default {
    getAllCountries() {
        let headers = {
            "Content-Type": "application/json; charset=utf-8",
        };
        return fetch(COUNTRY_API_V1.getAllCountries, {
            method: 'GET',
            headers
          }).then(response => {
              if(response.status < 200 || response.status >= 300)
              {
                  throw response;
              }
              return response.json();
        }).catch(error => {
            throw error;
        });
    },
    getCountry(countryId) {
        let headers = {
            "Content-Type": "application/json; charset=utf-8",
        };
        const url = format(COUNTRY_API_V1.getCountry,countryId);
        return fetch(url, {
            method: 'GET',
            headers
          }).then(response => {
              if(response.status < 200 || response.status >= 300)
              {
                  throw response;
              }
              return response.json();
        }).catch(error => {
            throw error;
        });
    },
    getAllCitiesByCountryId(countryId) {
        let headers = {
            "Content-Type": "application/json; charset=utf-8",
        };
        const url = format(COUNTRY_API_V1.getAllCitiesByCountryId,countryId);
        return fetch(url, {
            method: 'GET',
            headers
          }).then(response => {
              if(response.status < 200 || response.status >= 300)
              {
                  throw response;
              }
              return response.json();
        }).catch(error => {
            throw error;
        });
    }
}