import React from 'react';
import { withTranslation } from "react-i18next";
const Languages = ({t, items}) => {
    return (
        <>
            <h3>{t('languages')}</h3>
            <div className="task-tags">
                {items.map( (item, idx) => (<span key={idx} >{item.name}</span>))}
            </div>
        </>
    );
}
export default withTranslation()(Languages)