import React, { useState, useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import JobsList from './components/JobList';
import JobCategryItem from './components/JobCategryItem';
import jobService from '../../services/JobService';
import globalMetricService from '../../services/GlobalMetricService';
import {HTTP_RESPONSE_STATUS} from '../../constants/http';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral';
import queryString from 'query-string';
const Jobs = ({t}) => {
    const [poluparCategories, setPopularCategories] = useState([]);
    const [latestJobs, setLatestJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState({});
    const history = useHistory();
    const [globalMetrics, setGlobalMetrics] = useState({});
    const loadLatestJobs = () => {
        setIsLoading(true);
        jobService.getLatestJobs()
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setLatestJobs(resObj.data);
                } else {
                    setLatestJobs([]);
                }
            }
        })
        .catch(err => {
            setLatestJobs([]);
            setIsLoading(false);
        });
    };
    const loadJobCategories = () => {
        setIsLoading(true);
        jobService.getPopularJobCategories()
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setPopularCategories(resObj.data);
                } else {
                    setPopularCategories([]);
                }
            }
        })
        .catch(err => {
            setPopularCategories([]);
            setIsLoading(false);
        });
    };
    const loadGlobalMetrics = () => {
        setIsLoading(true);
        globalMetricService.getLatestMetrics()
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setGlobalMetrics(resObj.data)
                }
            }
        })
        .catch(err => {
            setIsLoading(false);
        });
    };
    useEffect(()=> {
        loadLatestJobs();
        loadJobCategories();
        loadGlobalMetrics();
    }, []);
    const handleChangeFields = (e) => {
        values[e.target.name] = e.target.value;
        setValues({...values});
    };
    const handleSearch = (e) => {
        let query = queryString.stringify({...values, size: 10},
            {
                skipEmptyString: true,
                skipNull: true
            });
        history.push("/jobs/finder?" + query);
    };
    const handleEnterKeyDown = (e) => {
        if (e.key === 'Enter') {
            let query = queryString.stringify({...values, size: 10},
            {
                skipEmptyString: true,
                skipNull: true
            });
            history.push("/jobs/finder?" + query);
        }
    };
    return (
        <>
        <div className="header_wrapper">
        </div>
        <div className="intro-banner" data-background-image="images/home-background.jpg">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner-headline">
                        <h3>
                            <strong>{t('job_banner_title')}</strong>
                            <br/>
                            <span>{t('job_banner_sub_title')}</span>
                        </h3>
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col-md-12">
                    <form className="intro-banner-search-form margin-top-95">
                        

                        <div className="intro-search-field with-label">
                            <label for="q" class="field-title ripple-effect">{t('job_search_title_or_keywords')}</label>
                            <input name="q" className="border-0 w-100" type="text" placeholder={t('job_search_title_or_keywords_placeholder')} onChange={handleChangeFields} onKeyDown={handleEnterKeyDown}/>
                        </div>
                        <div className="intro-search-field with-autocomplete with-label">
                            <label for="location" className="field-title ripple-effect">{t('job_search_where')}</label>
                            <div className="input-with-icon">
                                <input name="location" type="text" placeholder={t('all')} className="w-100 d-block border-0" autocomplete="on" onChange={handleChangeFields} onKeyDown={handleEnterKeyDown}/>
                                <i className="fa fa-map-marker"></i>
                            </div>
                        </div>
                        <div className="intro-search-button">
                            <button className="button button_4" onClick={handleSearch}>{t('search')}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ul className="intro-stats margin-top-45 hide-under-992px">
                        <li>
                            <strong className="counter" style={{textTransform:"uppercase"}}>{globalMetrics.totalCompanies? numeral(globalMetrics.totalCompanies).format('+0a'): 0}</strong>
                            <span>{t('company')}</span>
                        </li>
                        <li>
                            <strong className="counter" style={{textTransform:"uppercase"}}>{globalMetrics.totalJobs? numeral(globalMetrics.totalJobs).format('+0a'): 0}</strong>
                            <span>{t('job')}</span>
                        </li>
                        <li>
                            <strong className="counter" style={{textTransform:"uppercase"}}>{globalMetrics.totalResumes? numeral(globalMetrics.totalResumes).format('+0a'): 0}</strong>
                            <span>{t('talent')}</span>
                        </li>
                    </ul>
                </div>
            </div>

        </div> 
    <div class="background-image-container" style={{backgroundImage: 'url("/images/home-background.jpg")'}}></div></div>
        <div class="services">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <div className="section_title_container">
                            <div className="section_subtitle">{t('jobs')}</div>
                            <div className="section_title"><h2>{t('jobs_latest')}</h2></div>
                        </div>
                    </div>
                </div>
                <div className="row services_row">
                    <div className="col">
                        <div className="listings-container compact-list-layout margin-top-35">
                            <JobsList items={latestJobs}/>
                        </div>   
                    </div>
                           
                    {/*<div class="col-xl-6 col-md-12 service_col">
                    <Card>
                        <Card.Img width={140} height={140} src="https://jobsgo.vn/media/img/employer/36654-100x100.jpg" />
                        <Card.Body>
                            <Card.Title>PHP Team Leader - Work at Da Lat City</Card.Title>
                            <Card.Subtitle>20-39K USD</Card.Subtitle>
                            <div>
                                
                            </div>
                            <div className="mb-1">
                                <Tags items={['leader', 'manager']}/>
                            </div>
                            <Button variant="primary">Ứng tuyển ngay</Button>
                        </Card.Body>
                    </Card>
</div>*/}
                    
                    
        
			</div>
		</div>
        </div>
        <div class="services" style={{background: "#eff3f7"}}>
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <div class="section_title_container">
                                <div class="section_subtitle">Tìm việc kỷ nguyên số</div>
                                <div class="section_title"><h2>Qui trình thực hiện </h2></div>
                            </div>
                        </div>
                    </div>
                    <div class="row services_row">                    
                    <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <a href="https://me.topworks.vn/register?accType=1" target="_blank">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/user.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                    </div>
                                    <div class="service_title">Tạo tài khoản</div>
                                    <div class="service_text">
                                        <p>Tạo một tài khoản để sử dụng các dịch vụ được cá nhân hoá cho bạn.</p>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <a href="https://me.topworks.vn/account/resume-dashboard" target="_blank">

                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/curriculum.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                    </div>
                                    <div class="service_title">Cập nhật hồ sơ</div>
                                    <div class="service_text">
                                        <p>Cập nhật một hồ sơ đầy đủ về kiến thức, kỹ năng, kinh nghiệm làm việc...</p>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                <a href="/jobs">

                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/job-offer.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
    
                                </div>
                                <div class="service_title">Công việc phù hợp</div>
                                <div class="service_text">
                                    <p>Nền tảng công nghệ và đội ngũ chúng tôi sẽ tìm các công việc phù hợp nhất cho bạn</p>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cta">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="cta_container d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start">
                            <div class="cta_content">
                                <div class="cta_title">Gọi chúng tôi ngay bây giờ!</div>
                                <div class="cta_text">Nhận được tư vấn và ưu đãi khi sử dụng dịch vụ của chúng tôi</div>
                            </div>
                            <div class="cta_phone ml-lg-auto">+84 90 866 77 96</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="services">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <div className="section_title_container">
                            <div className="section_subtitle">Việc làm theo ngành nghề</div>
                            <div className="section_title"><h2>Ngành nghề nổi bật</h2></div>
                        </div>
                    </div>
                </div>
                <div className="row services_row">                    
                    {
                        poluparCategories.map((item, idx) => <div className="col-xl-3 col-md-6">
                            <JobCategryItem key={idx} item={item}/>
                        </div>)
                    }
			    </div>
		    </div>
        </div>
    </>
    );
}
export default withTranslation()(Jobs)