import {HTTP_HEADER} from '../constants/http';
import {ROOT_URL} from '../config';
import * as authConstants from '../constants/auth';
import {fetchJson} from '../utils/fetch';
import format from 'string-format';
const PRODCT_API_V1 = {
    search: "/api/products/v1/search",
    searchAndCount: "/api/products/v1/search_and_count",
    getDetails: "/api/products/v1/{0}",
    previewProduct: "/api/products/v1/{0}/preview",
    getProductImage: "/api/products/v1/{0}/image",
};

function searchAndCount(params = {}) {
    let url = ROOT_URL + PRODCT_API_V1.searchAndCount + "?"+ new URLSearchParams(params).toString();
    return fetchJson(url, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function search(params = {}) {
    let url = ROOT_URL + PRODCT_API_V1.search + "?"+ new URLSearchParams(params).toString();
    return fetchJson(url, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getDetails(id) {
    return fetchJson(ROOT_URL + format(PRODCT_API_V1.getDetails,id), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function previewProduct(id) {
    return fetchJson(ROOT_URL + format(PRODCT_API_V1.previewProduct,id), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getProductImage(id) {
    return fetchJson(ROOT_URL + format(PRODCT_API_V1.getProductImage,id), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
export default {
    searchAndCount,
    search,
    getDetails,
    previewProduct,
    getProductImage
};