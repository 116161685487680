import React, { useState } from 'react';
import {withTranslation} from 'react-i18next';
import BlogListItem from './BlogListItem';
import Alert from 'react-bootstrap/Alert';
const BlogList = ({t, items}) => {
    return (
        <>
            {
                items.length === 0 && <Alert variant="warning">
                    {t('no_items_found')}
                </Alert>
            }
            {
                items.map((item, idx) => <BlogListItem key={idx} item={item} ids={items.map(x => x.id)}/>)
            }
        </>
    )
};
export default withTranslation()(BlogList)