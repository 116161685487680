import React, { useState, useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import BlogList from './components/BogList';
import TrendingPosts from './components/TrendingPosts';
import Pagination from './components/Pagination';
import newsService from '../../services/NewsService';
import {HTTP_RESPONSE_STATUS} from '../../constants/http';
import FeaturedNewsSlider from './components/FeaturedNewsSlider';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
const Blog = ({t}) => {
    const history = useHistory();
    const search = queryString.parse(useLocation().search);
    const [news, setNews] = useState([]);
    const [categories, setCategories] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(search.page?search.page:1);
    const [searchParams, setSearchParams] = useState({...search});
    const [pageSize, setPageSize] = useState(search.size?search.size:5);
    const [isLoading, setIsLoading] = useState(false);
    const loadNews = () => {
        setIsLoading(true);
        newsService.searchAndCount({...searchParams, page: currentPage, size: pageSize})
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setNews(resObj.data.items);
                    setCurrentPage(resObj.data.page);
                    setTotalPages(Math.ceil(resObj.data.total/pageSize));
                } else {
                    setNews([]);
                    setCurrentPage(1);
                    setTotalPages(0);
                }
            }
        })
        .catch(err => {
            setNews([]);
            setCurrentPage(1);
            setTotalPages(0);
            setIsLoading(false);
        });
    };
    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            setCurrentPage(1);
            let query = queryString.stringify({...searchParams, size: pageSize},
                {
                    skipEmptyString: true,
                    skipNull: true
                });
            history.push("/blogs?" + query);
            loadNews();
        }
    };
    const handleChangeFields = (e) => {
        searchParams[e.target.name] = e.target.value;
        setSearchParams({...searchParams});
    };
    const onPageChanged = (e, page) => {
        e.preventDefault();
        setCurrentPage(page);
        ///Call
        let query = queryString.stringify({...searchParams, page: page, size: pageSize},
            {
                skipEmptyString: true,
                skipNull: true
            });
        setIsLoading(true);
        newsService.search({...searchParams, page: page, size: pageSize})
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setNews(resObj.data);
                } else {
                    setNews([]);
                }
            }
        })
        .catch(err => {
            setNews([]);
            setIsLoading(false);
        });
        history.push("/blogs?" + query);
    };
    useEffect(() => {
        //Fetch total & count.
        newsService.getCategories()
        .then(response=> {
          if (response.status === HTTP_RESPONSE_STATUS.OK) {
            setCategories([...response.data])
          }
        }).catch(err => {
    
        });
		///Consider query from param status, pages, size, 
		loadNews();
	}, []);
    return (
    <>
        <div className="header_wrapper"></div>
        <div className="clearfix"></div>
        <div id="titlebar" className="gradient">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{t('news')}</h2>
                        <nav id="breadcrumbs" className="dark">
                            <ul>
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('news')}</li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
        <FeaturedNewsSlider />
        <div className="section gray">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-8">

                        <div className="section-headline margin-top-60 margin-bottom-35">
                            <h4>{t('recent_posts')}</h4>
                        </div>

                        <BlogList items={news}/>

                        <div className="clearfix"></div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pagination-container margin-top-10 margin-bottom-20">
                                    <Pagination limit={2} onPageChanged={onPageChanged} activePage={currentPage} pages={totalPages}/>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="col-xl-4 col-lg-4 content-left-offset">
                        <div className="sidebar-container margin-top-65">
                            
                            <div className="sidebar-widget margin-bottom-40">
                                <div className="input-with-icon">
                                    <input value={searchParams.q} name="q" className="input-search" type="text" placeholder={t('placeholder_search')} onKeyDown={handleSearch} onChange={handleChangeFields}/>
                                    <i className="fa fa-search" aria-hidden="true" onClick></i>
                                </div>
                            </div>
                            <TrendingPosts/>
                            


                            {/*<div class="sidebar-widget">
                                <h3>Social Profiles</h3>
                                <div class="freelancer-socials margin-top-25">
                                    <ul>
                                        <li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="Dribbble"><i class="icon-brand-dribbble"></i></a></li>
                                        <li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="Twitter"><i class="icon-brand-twitter"></i></a></li>
                                        <li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="Behance"><i class="icon-brand-behance"></i></a></li>
                                        <li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="GitHub"><i class="icon-brand-github"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            */}
                            {/*
                            <div class="sidebar-widget">
                                <h3>Tags</h3>
                                <div class="task-tags">
                                    <a href="#"><span>employer</span></a>
                                    <a href="#"><span>recruiting</span></a>
                                    <a href="#"><span>work</span></a>
                                    <a href="#"><span>salary</span></a>
                                    <a href="#"><span>tips</span></a>
                                    <a href="#"><span>income</span></a>
                                    <a href="#"><span>application</span></a>
                                </div>
                            </div>
                            */}

                        </div>
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}
export default withTranslation()(Blog)

/**
 * <div class="home d-flex flex-column align-items-start justify-content-end" style={{height: "625px"}}>
            <div class="parallax_background parallax-window" data-parallax="scroll" style={{backgroundImage: "url(images/blog.jpg)"}} data-image-src="images/blog.jpg" data-speed="0.8"></div>
            <div class="home_overlay"><img src="images/home_overlay.png" alt=""/></div>
            <div class="home_container">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="home_content">
                                <div class="home_title">Blog</div>
                                <div class="home_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="blog">
            <div class="container">
                <div class="row">
                    <div class="col">                        
                        <div class="blog_post">
                            <div class="blog_post_image"><img src="images/blog_1.jpg" alt=""/></div>
                            <div class="blog_post_date d-flex flex-column align-items-center justify-content-center">
                                <div class="date_day">19</div>
                                <div class="date_month">June</div>
                                <div class="date_year">2018</div>
                            </div>
                            <div class="blog_post_title"><a href="#">5 Reasons why you should choose a plastic surgeon</a></div>
                            <div class="blog_post_info">
                                <ul class="d-flex flex-row align-items-center justify-content-center">
                                    <li>by <a href="#">Dr. James Smith</a></li>
                                    <li>in <a href="#">Surgery</a></li>
                                    <li><a href="#">2 Comments</a></li>
                                </ul>
                            </div>
                            <div class="blog_post_text text-center">
                                <p>Odio ultrices ut. Etiam ac erat ut enim maximus accumsan vel ac nisl. Duis feugiat bibendum orci, non elementum urna. Cras sit amet sapien aliquam, fermentum dolor non, blandit purus. Donec blandit purus vitae eros fringilla accumsan. Nunc feugiat purus et posuere ornare. Vivamus molestie sem pretium ligula efficitur, vitae auctor tortor vestibulum. Ut interdum ante neque, sed vestibulum lorem dictum quis.</p>
                            </div>
                            <div class="blog_post_button text-center"><div class="button button_1 ml-auto mr-auto"><a href="#">read more</a></div></div>
                        </div>
                        <div class="blog_post">
                            <div class="blog_post_image"><img src="images/blog_2.jpg" alt=""/></div>
                            <div class="blog_post_date d-flex flex-column align-items-center justify-content-center">
                                <div class="date_day">19</div>
                                <div class="date_month">June</div>
                                <div class="date_year">2018</div>
                            </div>
                            <div class="blog_post_title"><a href="#">Cách tính số đường đời trong thần số học</a></div>
                            <div class="blog_post_info">
                                <ul class="d-flex flex-row align-items-center justify-content-center">
                                    <li>by <a href="#">Dr. James Smith</a></li>
                                    <li>in <a href="#">Surgery</a></li>
                                    <li><a href="#">2 Comments</a></li>
                                </ul>
                            </div>
                            <div class="blog_post_text text-center">
                                <p>Odio ultrices ut. Etiam ac erat ut enim maximus accumsan vel ac nisl. Duis feugiat bibendum orci, non elementum urna. Cras sit amet sapien aliquam, fermentum dolor non, blandit purus. Donec blandit purus vitae eros fringilla accumsan. Nunc feugiat purus et posuere ornare. Vivamus molestie sem pretium ligula efficitur, vitae auctor tortor vestibulum. Ut interdum ante neque, sed vestibulum lorem dictum quis.</p>
                            </div>
                            <div class="blog_post_button text-center"><div class="button button_1 ml-auto mr-auto"><a href="#">read more</a></div></div>
                        </div>
                        <div class="blog_post">
                            <div class="blog_post_image"><img src="images/blog_3.jpg" alt=""/></div>
                            <div class="blog_post_date d-flex flex-column align-items-center justify-content-center">
                                <div class="date_day">19</div>
                                <div class="date_month">June</div>
                                <div class="date_year">2018</div>
                            </div>
                            <div class="blog_post_title"><a href="#">Giới thiệu về nhân số học</a></div>
                            <div class="blog_post_info">
                                <ul class="d-flex flex-row align-items-center justify-content-center">
                                    <li>by <a href="#">Dr. James Smith</a></li>
                                    <li>in <a href="#">Surgery</a></li>
                                    <li><a href="#">2 Comments</a></li>
                                </ul>
                            </div>
                            <div class="blog_post_text text-center">
                                <p>Odio ultrices ut. Etiam ac erat ut enim maximus accumsan vel ac nisl. Duis feugiat bibendum orci, non elementum urna. Cras sit amet sapien aliquam, fermentum dolor non, blandit purus. Donec blandit purus vitae eros fringilla accumsan. Nunc feugiat purus et posuere ornare. Vivamus molestie sem pretium ligula efficitur, vitae auctor tortor vestibulum. Ut interdum ante neque, sed vestibulum lorem dictum quis.</p>
                            </div>
                            <div class="blog_post_button text-center"><div class="button button_1 ml-auto mr-auto"><a href="#">read more</a></div></div>
                        </div>

                    </div>
                </div>
                <div class="row page_nav_row">
                    <div class="col">
                        <div class="page_nav">
                            <ul class="d-flex flex-row align-items-center justify-content-center">
                                <li class="active"><a href="#">01.</a></li>
                                <li><a href="#">02.</a></li>
                                <li><a href="#">03.</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 */
/*<div class="before_and_after">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="section_title_container text-center">
                            <div class="section_subtitle">This is Dr Pro</div>
                            <div class="section_title"><h2>Before &amp; After Gallery</h2></div>
                        </div>
                    </div>
                </div>
                <div class="row before_and_after_row">
                    <div class="col">
                        <div class="ba_container">
                            <figure class="cd-image-container is-visible">
                                <img src="images/before.jpg" alt="Original Image"/>
                                <span class="cd-image-label is-hidden" data-type="original"></span>

                                <div class="cd-resize-img">
                                    <img src="images/after.jpg" alt="Modified Image"/>
                                    <span class="cd-image-label" data-type="modified"></span>
                                </div>

                                <span class="cd-handle"></span>
                            </figure>
                        </div>
                        <div class="ba_text text-center"><p>Nulla facilisi. Nulla egestas vel lacus sed interdum. Sed mollis, orci elementum eleifend tempor, nunc libero porttitor tellus.</p></div>
                        <div class="ba_button_container text-center">
                            <div class="button button_1 ba_button"><a href="#">see gallery</a></div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>*/