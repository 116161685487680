import React, { useState } from 'react';
import {withTranslation} from 'react-i18next';
import PromotionListItem from './PromotionListItem';
import Alert from 'react-bootstrap/Alert';
const PromotionList = ({t, items}) => {
    return (
        <>
            {
                items.length === 0 && <Alert variant="warning">
                    {t('no_items_found')}
                </Alert>
            }
            {
                items.map((item, idx) => <PromotionListItem key={idx} item={item} ids={items.map(x => x.id)}/>)
            }
        </>
    )
};
export default withTranslation()(PromotionList)