import {HTTP_HEADER} from './../constants/http';
import {ROOT_URL} from '../config';
import * as authConstants from '../constants/auth';
import {fetchJson} from '../utils/fetch';
import format from 'string-format';
import queryString from 'query-string';
const JOB_API_V1 = {
    getDetails: "/api/jobs/v1/{0}",
    getProfile: "/api/jobs/v1/{0}/profile",
    create: "/api/jobs/v1",
    getAllTypes: "/api/jobs/v1/types",
    getAllMajors: "/api/jobs/v1/majors",
    getAllLevels: "/api/jobs/v1/levels",
    getAllDegrees: "/api/jobs/v1/degrees",
    getAllPositions: "/api/jobs/v1/positions",
    getLatestJobs: "/api/jobs/v1/latest",
    getPopularJobCategories: "/api/jobs/v1/categories/popular",
    getDetails: "/api/jobs/v1/{0}/details",
    searchAndCount: "/api/jobs/v1/search_and_count",
    search: "/api/jobs/v1/search",
};
/*function getDetails() {
    let headers = {
        "Content-Type": "application/json; charset=utf-8",
    };
    headers[HTTP_HEADER.CLIENT_FINGERPRINT] = localStorage.getItem(HTTP_HEADER.CLIENT_FINGERPRINT);
    headers[HTTP_HEADER.CLIENT_ID] = localStorage.getItem(HTTP_HEADER.CLIENT_ID);
    headers[HTTP_HEADER.AUTHORIZATION] = "Bearer " + localStorage.getItem("X-Auth-Token");
    return fetch(ROOT_URL + JOB_API_V1.getDetails, {
        method: 'GET',
        headers
    }).then(response => {
        if(response.status < 200 ||  response.status>=300) {
            throw response;
        }
        return response.json();
    }).catch(error => {
        throw error;
    });
}*/
function getDetails(id) {
    return fetchJson(ROOT_URL + format(JOB_API_V1.getDetails, id), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getProfile(id) {
    return fetchJson(ROOT_URL + format(JOB_API_V1.getProfile, id), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getAllTypes() {
    return fetchJson(ROOT_URL + JOB_API_V1.getAllTypes, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getAllMajors() {
    return fetchJson(ROOT_URL + JOB_API_V1.getAllMajors, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getAllLevels() {
    return fetchJson(ROOT_URL + JOB_API_V1.getAllLevels, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getAllDegrees() {
    return fetchJson(ROOT_URL + JOB_API_V1.getAllDegrees, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getAllPositions() {
    return fetchJson(ROOT_URL + JOB_API_V1.getAllPositions, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function create(jobDto) {
    return fetchJson(ROOT_URL + JOB_API_V1.create, {
        method: 'POST',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN),
        body: JSON.stringify(jobDto)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getLatestJobs() {
    return fetchJson(ROOT_URL + JOB_API_V1.getLatestJobs, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function searchAndCount (params = {}) {
    let query = params? queryString.stringify(
        params,
        {
            skipEmptyString: true,
            skipNull: true
        }
    ): null;
    return fetchJson(ROOT_URL + JOB_API_V1.searchAndCount + (query? ("?"+query):""), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function search (params = {}) {
    let query = params? queryString.stringify(
        params,
        {
            skipEmptyString: true,
            skipNull: true
        }
    ): null;
    return fetchJson(ROOT_URL + JOB_API_V1.search + (query? ("?"+query):""), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getPopularJobCategories(limit = 8) {
    let query = "?limit=" + (limit?limit:8);
    return fetchJson(ROOT_URL + JOB_API_V1.getPopularJobCategories + query, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
export default {
    getAllTypes,
    getAllMajors,
    getAllLevels,
    getAllDegrees,
    getAllPositions,
    create,
    getLatestJobs,
    getDetails,
    getProfile,
    searchAndCount,
    search,
    getPopularJobCategories
}