///https://github.com/marmelab/admin-on-rest
import {HTTP_HEADER} from './../constants/http'
export const fetchJson = (url, options = {}) => {
    let requestHeaders = options.headers || {'Accept': 'application/json'} ;
    if (
        !requestHeaders['Content-Type'] &&
        !(options && options.body && options.body instanceof FormData)
    ) {
        requestHeaders['Content-Type'] = ['application/json'];
    }
    if (options.authToken) {
        requestHeaders['X-Authorization'] = options.authToken;
    }
    ///Extra Header
    if (localStorage.getItem(HTTP_HEADER.CLIENT_FINGERPRINT)) {
        requestHeaders[HTTP_HEADER.CLIENT_FINGERPRINT] = localStorage.getItem(HTTP_HEADER.CLIENT_FINGERPRINT);
    }
    if (localStorage.getItem(HTTP_HEADER.CLIENT_ID)) {
        requestHeaders[HTTP_HEADER.CLIENT_ID] = localStorage.getItem(HTTP_HEADER.CLIENT_ID);
    }
    return fetch(url, { ...options, headers: requestHeaders })
        .then(response =>{
            if (response.status === 500) {
                window.location.href="/500"  
                return;              
            }
            if (response.status === 401) {
                localStorage.removeItem("X-Auth-Token");
                //window.location.href="/login"
                return; 
            }
            if (response.status === 403) {
                window.location.href="/403"
                return; 
            }
            if (response.status < 200 || response.status > 300) {
                throw response;
            }
            //// Case 401 -> Login
            // Case 403 -> 403 page
            return response.json()
        }).catch (err => {
            console.log(err);
            throw err;
        });
};
const isValidObject = value => {
    if (!value) {
        return false;
    }
    const isArray = Array.isArray(value);
    const isBuffer = Buffer.isBuffer(value);
    const isObject =
        Object.prototype.toString.call(value) === '[object Object]';
    const hasKeys = !!Object.keys(value).length;

    return !isArray && !isBuffer && isObject && hasKeys;
};

export const flattenObject = (value, path = []) => {
    if (isValidObject(value)) {
        return Object.assign(
            {},
            ...Object.keys(value).map(key =>
                flattenObject(value[key], path.concat([key]))
            )
        );
    } else {
        return path.length ? { [path.join('.')]: value } : value;
    }
};