import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import ticketService from '../../services/TicketService';
import {HTTP_RESPONSE_STATUS} from '../../constants/http';
import { useAlert } from 'react-alert';
import * as validations from '../../constants/validations';
import validate from 'validate.js';
import * as utils from '../../utils';
import moment from 'moment';
const INIT_VALUES = {
    name: "",
    phone: "",
    email: "",
    scheduledAt: "",
    content: ""
};

/*const INIT_ERRORS = {
    name: "",
    phone: "",
    email: "",
    scheduledAt: "",
    content: ""
};*/
const constrainsts = {
    name: validations.FIELD_REQUIRED_STRING_160,
    email: validations.EMAIL,
    phone: validations.FIELD_REQUIRED_STRING_100,
    scheduledAt: {
        presence: {
            allowEmpty: false,
            message: "^field_required"
        },
        datetime: {
            dateOnly: false,
            message: "^date_required_format"
        }
    }
};
validate.extend(validate.validators.datetime, {
    // The value is guaranteed not to be null or undefined but otherwise it
    // could be anything.
    parse: function(value, options) {
      return +moment.utc(value);
    },
    // Input is a unix timestamp
    format: function(value, options) {
      var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DDThh:mm";
      return moment.utc(value).format(format);
    }
});
const AppointmentForm = ({t}) => {
    const [formValues, setFormValues] = useState({});
    //const [errors, setErrors] = useState({});
    const toaster = useAlert();

    const handleChangeFields = (e) => {
        formValues[e.target.name] = e.target.value;
        setFormValues({...formValues});
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        let reqData = {
            name: formValues.name?formValues.name: null,
            email: formValues.email?formValues.email: null,
            phone: formValues.phone?formValues.phone: null,
            scheduledAt: formValues.scheduledAt?formValues.scheduledAt: null,
            content: formValues.content? formValues.content: null
        };
        const fieldErrors = validate(reqData, constrainsts);
		if (fieldErrors) {
            toaster.show(t('dialog_message_invalid_request'));
            return;
        }
        ticketService.makeAnAppointment(reqData)
		.then(resObj => {
			if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
				toaster.show('Bạn đã đăng ký thành công!');
                setFormValues({...INIT_VALUES});
                //setErrors({...INIT_ERRORS});
			} else {
				toaster.show(t('dialog_message_invalid_request'));
			}
		})
		.catch(err => {
			toaster.show(t('dialog_message_invalid_request'));
        });
    };
    return (
        <>
            <div className="contact_form_container">
                <div className="contact_form_title">{t('make_an_appointment')}</div>
                <form className="contact_form" id="contact_form">
                    <div className="d-flex flex-row align-items-start justify-content-between flex-wrap">
                        <input type="text" name="name" className="contact_input" placeholder={t('fullname') + " (*)"} required="required" onChange={handleChangeFields} value={formValues.name}/>
                        <input type="email" name="email" className="contact_input" placeholder={t('email') + " (*)"} required="required" onChange={handleChangeFields} value={formValues.email}/>
                        <input type="tel" name="phone" className="contact_input" placeholder={t('phone') + " (*)"} required="required" onChange={handleChangeFields} value={formValues.phone}/>
                        <input type="datetime-local" name="scheduledAt" id="datepicker" className="contact_input datepicker" placeholder="dd/mm/yyyy hh:mm AM/PM" required="required" onChange={handleChangeFields} value={formValues.scheduledAt}/>
                        <textarea className="contact_input w-100" name="content" placeholder={t('content') + " (*)"} required="required" onChange={handleChangeFields} value={formValues.content}/>
                    </div>
                    <button class="button button_1 contact_button trans_200" onClick={handleSubmit}>{t('appointment_submit')}</button>
                </form>
            </div>
        </>
    );
};

export default withTranslation() (AppointmentForm);

