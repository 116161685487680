import React, { useEffect, useState } from 'react';
import {withTranslation} from 'react-i18next';
import companyService from "../../../services/CompanyService";
import * as constants from "../../../constants";
import {HTTP_RESPONSE_STATUS} from '../../../constants/http';
import * as utils from '../../../utils';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import authService from '../../../services/AuthService';
import resumeService from '../../../services/ResumeService';
import applicantService from '../../../services/ApplicantService';
import * as config from '../../../config';
import validate from 'validate.js';
import * as validations from '../../../constants/validations';
import Alert from 'react-bootstrap/Alert';
import { useHistory, useLocation } from 'react-router-dom';
const INIT_VALUES = {
	fullName: "",
	email: "",
	description: "",
};
const INIT_ERRORS = {
	fullName: "",
	email: "",
	description: "",
};
const constraints = {
    fullName: validations.FIELD_REQUIRED_STRING_100,
	email: validations.EMAIL,
	description: validations.FIELD_REQUIRED_STRING_500,
};
const ERRORS_MAP = {
	"1001": {
		field: "fullName",
		message: "field_required"
	},
	"1002": {
		field: "fullName",
		message: "field_too_long_max_100"
	},
	"1053": {
		field: "description",
		message: "field_required"
	},
	"1054": {
		field: "description",
		message: "field_too_long_max_500"
	},
	"1005": {
		field: "email",
		message: "field_required"
	},
	"1006": {
		field: "email",
		message: "field_email_incorrect"
	}
};
const JobListItem = ({t,item}) => {
    const location = useLocation();
    const [logo, setLogo] = useState(constants.COMPANY_LOGO_PLACEHOLDER);
    const [showApplicantModal, setShowApplicantModal] = useState(false);
    const [values, setValues] = useState({isApplied: false});
    const [errors, setErrors] = useState(INIT_ERRORS);
    const [alert, setAlert] = useState("");
    const handleChangeFields = (e) => {
        values[e.target.name] = e.target.value;
        setValues({...values});
	};
    useEffect(() => {
        if (item.company) {
            companyService.getLogo(item.company.id)
            .then(resObj => {
                //setIsLoading(false);
    
                if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                    if (resObj.data) {
                        setLogo(resObj.data.url?resObj.data.url: constants.COMPANY_LOGO_PLACEHOLDER);
                    } else {
                        setLogo(constants.COMPANY_LOGO_PLACEHOLDER);     
                    }
                }
            })
            .catch(err => {
                //setIsLoading(false);
                setLogo(constants.COMPANY_LOGO_PLACEHOLDER);
            });        
        }
        resumeService.getDetailsByMe()
            .then(resResume => {
                if (resResume.status ===  HTTP_RESPONSE_STATUS.OK) {
                    let isApplied = applicantService.isApplied(resResume.data.id);
                    setValues({...values, email: resResume.data.email, 
                        resumeId: resResume.data.id,
                        isApplied: isApplied,
                        fullName: resResume.data.lastName + " " + resResume.data.middleName + " " + resResume.data.firstName,
                    });
                }
            })
            .catch( err => {
                
            })
    },[item]);
    const handleModalClose = () => {
		setShowApplicantModal(false);
    };
    const handleApply = (e) => {
        e.preventDefault();
        if (values.isApplied === true) {
            return;
        }
        //Fetch Resume if isLoggin
        if (authService.isLoggin() === false) {
            let ok = window.confirm(t('you_have_to_login_to_apply'));
            if (ok) {
                const win = window.location=config.LOGIN_REDIRECT_TO+window.location.href;
			    win.focus();
            }
        } else {
            //Fetch Resume and userProfile
                setShowApplicantModal(true);
                ///Calll Apply
                let reqData = {
                    resumeId: values.resumeId,
                    jobId: item.id? item.id: null,
                    companyId: item.company.id? item.company.id: null,
                    fullName: values.fullName? values.fullName: null,
                    email: values.email? values.email: null,
                    description: values.description? values.description: null
                }
                //console.log(reqData);
                let fieldErrors = validate(reqData, constraints);
                if (fieldErrors) {
                    setErrors({...utils.convertErrors2Object(fieldErrors)});
                    return;
                }
                //console.log(fieldErrors);
                applicantService.create(reqData)
                .then(resObj => {
                    //console.log(resObj);
                    if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                        setErrors({...INIT_ERRORS});
                        setValues({...INIT_VALUES});
                        setAlert("");
                        setShowApplicantModal(false);
                    } else {
                        if (resObj.status === HTTP_RESPONSE_STATUS.INVALID_REQUEST) {
                            setErrors({...utils.createErrorsFromErrorCodes(ERRORS_MAP,resObj.errors)});
                        } else {
                            setAlert("try_again");
                        }
                    }
                })
                .catch (err => {
                    setAlert("try_again");
                })
            
        }
    };
    return (
        <>
            <a href={"/jobs/details/" + item.id} className="job-listing with-apply-button">

                <div className="job-listing-details">
                    <div className="job-listing-company-logo">
                        <img className="job-listing-company-logo" src={logo} alt=""/>
                    </div>

                    <div className="job-listing-description">
                        <h3 className="job-listing-title">{item.title}</h3>
                        <div className="job-listing-footer">
                            <ul>
                                <li><i className="fa fa-money" aria-hidden="true"></i>{t(utils.formatSalary(item.salaryFrom, item.salaryTo, item.currencyCode))}</li>
                                <li><i className="fa fa-building" aria-hidden="true"></i>{item.company?item.company.name: "Unknown"} 
                                    {
                                        (item.company?item.company.verified: false) &&
                                        <div className="verified-badge" data-tippy-placement="top" data-tippy="" data-original-title="Verified"></div>
                                    }
                                </li>
                                <li><i className="fa fa-globe" aria-hidden="true"></i> {item.company?item.company.city: "Unknown"}</li>
                                { item.type && <li><i className="fa fa-briefcase" aria-hidden="true"></i> {item.type?item.type.name: null}</li>}
                                <li><i className="fa fa-clock-o" aria-hidden="true"></i> {t(utils.formatPublishedAt(item.publishedAt))}</li>
                            </ul>
                        </div>
                    </div>
                    {
                        values.isApplied === true?
                            <span className="list-apply-button ripple-effect" >{t('job_applied')}</span>
                        :   <span className="list-apply-button ripple-effect" onClick={(e) => handleApply(e)}>{t('apply_now')}</span>

                    }
                </div>
            </a>
            <Modal size="lg" show={showApplicantModal} onHide={handleModalClose}>
				<Modal.Header closeButton>
				<Modal.Title>{item.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="lastName">
							<Form.Label>{t('fullname')}</Form.Label>
                            <Form.Control type="text" placeholder={t('fullname')} name="fullName" value={values.fullName} isInvalid={!!errors.fullName} onChange={handleChangeFields}/>
                            <Form.Control.Feedback type="invalid">{t(errors.fullName)}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId="email">
							<Form.Label>{t('email')}</Form.Label>
							<Form.Control type="email" name="email" placeholder="Email" value={values.email} isInvalid={!!errors.email} onChange={handleChangeFields}/>
                            <Form.Control.Feedback type="invalid">{t(errors.email)}</Form.Control.Feedback>
						</Form.Group>
                        <Form.Group controlId="summary">
							<Form.Label>{t('summary')}</Form.Label>
							<Form.Control as="textarea" name="description" placeholder={t('summary')} value={values.description} isInvalid={!!errors.description} onChange={handleChangeFields}/>
                            <Form.Control.Feedback type="invalid">{t(errors.description)}</Form.Control.Feedback>
						</Form.Group>
					</Form>
                    {
							alert.length >0 && <Alert className="mt-3" variant="danger">
								{t(alert)}
							</Alert>
					}
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={handleModalClose}>
					{t("cancel")}
				</Button>
				<Button variant="primary" onClick={handleApply}>
					{t("apply")}
				</Button>
				</Modal.Footer>
			</Modal>
        </>
    );
}
export default withTranslation()(JobListItem)