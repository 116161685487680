export const EMAIL = {
    presence: {
        allowEmpty: false,
        message: "^field_required"
    },
    email: {
        message: "^email_incorrect"
    }
};
export const PASSWORD = {
    presence: {
        allowEmpty: false,
        message: "^field_required"
    },
    length: {
        minimum: 6,
        maximum: 20,
        message: "^password_required"
    },
    format: {
        pattern: "[a-zA-Z0-9!@#$%^&*]{6,20}",
        message: "^password_required_format"
    }
};
/**
 * /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,20}$/
 * /^
  (?=.*\d)          // should contain at least one digit
  (?=.*[a-z])       // should contain at least one lower case
  (?=.*[A-Z])       // should contain at least one upper case
  [a-zA-Z0-9]{8,}   // should contain at least 8 from the mentioned characters
$/

 */
export const CONFIRM_PASSWORD = {
    presence: {
        allowEmpty: false,
        message: "^field_required"
    },
    equality: {
        attribute: "password",
        message: "^password_not_match"
    }
};
export const FIELD_REQUIRED_STRING_100 = {
    presence: {
        allowEmpty: false,
        message: "^field_required"
    },
    length: {
        maximum: 100,
        message: "^field_too_long_max_100"
    }
};
export const FIELD_REQUIRED_STRING_160 = {
    presence: {
        allowEmpty: false,
        message: "^field_required"
    },
    length: {
        maximum: 160,
        message: "^field_too_long_max_160"
    }
};
export const FIELD_REQUIRED_STRING_255 = {
    presence: {
        allowEmpty: false,
        message: "^field_required"
    },
    length: {
        maximum: 255,
        message: "^field_too_long"
    }
};
export const FIELD_REQUIRED_STRING_500 = {
    presence: {
        allowEmpty: false,
        message: "^field_required"
    },
    length: {
        maximum: 500,
        message: "^field_too_long_max_500"
    }
};
export const FIELD_REQUIRED_SELECT_ID = {
    presence: {
        allowEmpty: false,
        message: "^field_required"
    },
    numericality: {
        greaterThan: 0,
        message: "^field_required"
    }
};
export const URL = {
    presence: {
        allowEmpty: false,
        message: "^field_required"
    },
    url: {
        message: "^url_incorrect"
    }
};
