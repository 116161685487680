import React from 'react'
const Login = () => (
    <>
        <div className="header_wrapper" style={{marginBottom: "79px"}}>
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 offset-xl-3">


                        <div class="login-register-page">
                            <div class="welcome-text">
                                <h4>Chào mừng bạn đến với DrWork!</h4>
                                <span>Bạn không có tài khoản? <a href="/register">Đăng ký tài khoản!</a></span>
                            </div>					
                            <form method="post" id="login-form">
                                <div class="input-with-icon-left">
                                    <i class="icon-material-baseline-mail-outline"></i>
                                    <input type="text" class="input-text with-border" name="emailaddress" id="emailaddress" placeholder="Email Address" required=""/>
                                </div>

                                <div class="input-with-icon-left">
                                    <i class="icon-material-outline-lock"></i>
                                    <input type="password" class="input-text with-border" name="password" id="password" placeholder="Password" required=""/>
                                </div>
                                <a href="/forgot-password" class="forgot-password">Quên mật khẩu?</a>
                            </form>
                            <button class="button button_4" type="submit" form="login-form" style={{width: "504.156px"}}>Đăng nhập <i class="icon-material-outline-arrow-right-alt"></i></button>
                            <div class="social-login-separator"><span>Hoặc</span></div>
                            <div class="social-login-buttons">
                                <button class="facebook-login ripple-effect"><i class="fa fa-facebook-official" aria-hidden="true"></i> Facebook</button>
                                <button class="google-login ripple-effect"><i class="fa fa-google-plus-square" aria-hidden="true"></i> Google+</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
export default Login