import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import StarRatingComponent from 'react-star-rating-component';
import SimilarJobs from './components/SimilarJobs';
import jobService from './../../services/JobService';
import * as utils from '../../utils';
import companyService from "../../services/CompanyService";
import countryService from '../../services/CountryService';
import authService from '../../services/AuthService';
import resumeService from '../../services/ResumeService';
import applicantService from '../../services/ApplicantService';
import * as constants from "../../constants";
import {HTTP_RESPONSE_STATUS} from '../../constants/http';
import moment from 'moment';
import * as validations from '../../constants/validations';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import validate from 'validate.js';
import * as config from '../../config';

const INIT_VALUES = {
	fullName: "",
	email: "",
	description: "",
};
const INIT_ERRORS = {
	fullName: "",
	email: "",
	description: "",
};
const constraints = {
    fullName: validations.FIELD_REQUIRED_STRING_100,
	email: validations.EMAIL,
	description: validations.FIELD_REQUIRED_STRING_500,
};
const ERRORS_MAP = {
	"1001": {
		field: "fullName",
		message: "field_required"
	},
	"1002": {
		field: "fullName",
		message: "field_too_long_max_100"
	},
	"1053": {
		field: "description",
		message: "field_required"
	},
	"1054": {
		field: "description",
		message: "field_too_long_max_500"
	},
	"1005": {
		field: "email",
		message: "field_required"
	},
	"1006": {
		field: "email",
		message: "field_email_incorrect"
	}
};
const JobDetails = ({t, match}) => {
    const [details, setDetails] = useState({company: {}, type: {}});
    const [similarJobs, setSimilarJobs] = useState([]);
    const [logo, setLogo] = useState(constants.COMPANY_LOGO_PLACEHOLDER);
    const [country, setCountry] = useState({});
    const [showApplicantModal, setShowApplicantModal] = useState(false);
    const [values, setValues] = useState({isApplied: false});
    const [errors, setErrors] = useState(INIT_ERRORS);
    const [alert, setAlert] = useState("");
    const handleChangeFields = (e) => {
        values[e.target.name] = e.target.value;
        setValues({...values});
	};
    const loadDetails = () => {
        jobService.getProfile(match.params.id)
		.then( resObj => {
			if (resObj.status === "OK") {
                setDetails(resObj.data);
                countryService.getCountry(resObj.data.company.countryId)
                            .then(rs => {
                                setCountry(rs.data);
                            })
                companyService.getLogo(resObj.data.company.id)
                .then(rs => {
                    //setIsLoading(false);
        
                    if (rs.status === HTTP_RESPONSE_STATUS.OK) {
                        if (rs.data) {
                            setLogo(rs.data.url?rs.data.url: constants.COMPANY_LOGO_PLACEHOLDER);

                        } else {
                            setLogo(constants.COMPANY_LOGO_PLACEHOLDER);     
                        }
                    }
                })
                .catch(err => {
                    //setIsLoading(false);
                    setLogo(constants.COMPANY_LOGO_PLACEHOLDER);
                });  
			}
		})
		.catch (err => {

		});
    };
    useEffect( () => {
        loadDetails();
        resumeService.getDetailsByMe()
            .then (resResume => {
                if (resResume.status ===  HTTP_RESPONSE_STATUS.OK) {
                    let isApplied = applicantService.isApplied(resResume.data.id);
                    setValues({...values, email: resResume.data.email, 
                        resumeId: resResume.data.id,
                        isApplied: isApplied,
                        fullName: resResume.data.lastName + " " + resResume.data.middleName + " " + resResume.data.firstName,
                    });

                } else {
                    ///create resume before applying this job
                }
            })
            .catch( err => {
                
            })
    }, []);
    const handleModalClose = () => {
		setShowApplicantModal(false);
    };
    const handleApply = (e) => {
        e.preventDefault();
        //Fetch Resume if isLoggin
        if (authService.isLoggin() === false) {
            let ok = window.confirm(t('you_have_to_login_to_apply'));
            if (ok) {
                const win = window.location=config.LOGIN_REDIRECT_TO+window.location.href;
			    win.focus();
            }
        } else {
            //Fetch Resume and userProfile
                setShowApplicantModal(true);
                ///Calll Apply
                let reqData = {
                    resumeId: values.resumeId,
                    jobId: details.id? details.id: null,
                    companyId: details.company.id? details.company.id: null,
                    fullName: values.fullName? values.fullName: null,
                    email: values.email? values.email: null,
                    description: values.description? values.description: null
                }
                //console.log(reqData);
                let fieldErrors = validate(reqData, constraints);
                if (fieldErrors) {
                    setErrors({...utils.convertErrors2Object(fieldErrors)});
                    return;
                }
                //console.log(fieldErrors);
                applicantService.create(reqData)
                .then(resObj => {
                    //console.log(resObj);
                    if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                        setErrors({...INIT_ERRORS});
                        setValues({...INIT_VALUES});
                        setAlert("");
                        setShowApplicantModal(false);
                    } else {
                        if (resObj.status === HTTP_RESPONSE_STATUS.INVALID_REQUEST) {
                            setErrors({...utils.createErrorsFromErrorCodes(ERRORS_MAP,resObj.errors)});
                        } else {
                            setAlert("try_again");
                        }
                    }
                })
                .catch (err => {
                    setAlert("try_again");
                })
            
        }
    };
    return (
        <>
            <Modal size="lg" show={showApplicantModal} onHide={handleModalClose}>
				<Modal.Header closeButton>
				<Modal.Title>{details.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="lastName">
							<Form.Label>{t('fullname')}</Form.Label>
                            <Form.Control type="text" placeholder={t('fullname')} name="fullName" value={values.fullName} isInvalid={!!errors.fullName} onChange={handleChangeFields}/>
                            <Form.Control.Feedback type="invalid">{t(errors.fullName)}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId="email">
							<Form.Label>{t('email')}</Form.Label>
							<Form.Control type="email" name="email" placeholder="Email" value={values.email} isInvalid={!!errors.email} onChange={handleChangeFields}/>
                            <Form.Control.Feedback type="invalid">{t(errors.email)}</Form.Control.Feedback>
						</Form.Group>
                        <Form.Group controlId="summary">
							<Form.Label>{t('summary')}</Form.Label>
							<Form.Control as="textarea" name="description" placeholder={t('summary')} value={values.description} isInvalid={!!errors.description} onChange={handleChangeFields}/>
                            <Form.Control.Feedback type="invalid">{t(errors.description)}</Form.Control.Feedback>
						</Form.Group>
					</Form>
                    {
							alert.length >0 && <Alert className="mt-3" variant="danger">
								{t(alert)}
							</Alert>
					}
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={handleModalClose}>
					{t("cancel")}
				</Button>
				<Button variant="primary" onClick={handleApply}>
					{t("apply")}
				</Button>
				</Modal.Footer>
			</Modal>
            <div className="clearfix"></div>
            <div className="single-page-header" data-background-image="/images/single-job.jpg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="single-page-header-inner">
                                <div className="left-side">
                                    <div className="header-image"><a href={"/companies/" + (details.company ? details.company.id: "undefined")}><img className="w-100" src={logo} alt={details.company?details.company.name: "Unknown" }/></a></div>
                                    <div className="header-details">
                                        <h1>{details.title}</h1>
                                        <h5>{t('recruiter')}</h5>
                                        <ul>
                                            <li><a href={"/companies/" + (details.company ? details.company.id: "undefined")}><i className="fa fa-building" aria-hidden="true"></i> {details.company?details.company.name: "Unknown" }</a></li>
                                            {
                                                details.company.numOfReviews > 3 &&
                                            
                                            <li><div className="star-rating" data-rating={details.company.numOfReviews > 0? ((details.company.sumOfRating/details.company.numOfReviews).toFixed(2)): 0}><StarRatingComponent name="rating" starCount={5} value={details.company.numOfReviews > 0? (details.company.sumOfRating/details.company.numOfReviews): 0}/></div></li>
                                            }
                                            <li><img className="flag" src={"/images/flags/"+ (country.code?country.code.toLowerCase():"")+".svg"} alt={country.name} loading="lazy"/> {country.name}</li>
                                            { details.verified === true && <li><div className="verified-badge-with-title"><i className="fa fa-check" aria-hidden="true"></i> {t('verified')}</div></li> }

                                        </ul>
                                    </div>
                                </div>
                                <div className="right-side">
                                    <div className="salary-box">
                                        <div className="salary-type">{t('salary')}</div>
                                        <div className="salary-amount">{ t(utils.formatSalary(details.salaryFrom, details.salaryTo, details.currencyCode))}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="background-image-container" style={{backgroundImage: "url(/images/single-job.jpg)"}}></div></div>
            <div class="container">
                <div className="row">
                <div className="col-xl-8 col-lg-8 content-right-offset">

                    <div className="single-page-section">
                        <h3 className="margin-bottom-25"><strong>{t('job_description')}</strong></h3>
                        <div className="job-content pl-4" dangerouslySetInnerHTML={{__html: details.description}} />
                    </div>
                    <div class="single-page-section">
                        <h3 class="margin-bottom-25"><strong>{t('job_requirements')}</strong></h3>
                        <div className="job-content pl-4" dangerouslySetInnerHTML={{__html: details.requirements}} />
                    </div>
                    { details.benefits && 
                    <div className="single-page-section">
                        <h3 class="margin-bottom-25"><strong>{t('job_benefits')}</strong></h3>
                        <div className="job-content pl-4" dangerouslySetInnerHTML={{__html: details.benefits}} />
                    </div>
                    }
                    {/*<div class="single-page-section">
                        <h3 class="margin-bottom-30"><strong>{t('job_locations')}</strong></h3>
                        <div id="single-job-map-container">
                            <a href="#" id="streetView">Street View</a>
                        </div>
                    </div>*/}
                    {
                        similarJobs.length > 0 && 
                        <div class="single-page-section">
                        <h3 class="margin-bottom-25">{t('similar_jobs')}</h3>

                        <div class="listings-container grid-layout">
                                <SimilarJobs items={[1, 3, ,3]} />
                            </div>

                        </div>
                    }
                    </div>
                    <div class="col-xl-4 col-lg-4">
                <div className="sidebar-container">
                    <div className="button button_1 mb-2">
                        {
                            values.isApplied === false?
                            <a href="" onClick={handleApply}>{t('apply')} 
                                &nbsp;<i className="fa fa-arrow-right" aria-hidden="true"></i>
                            </a>
                            : 
                            <a href={config.MY_ACCOUNT+"/account/resume-dashboard"} target="_blank">{t('job_applied')} 
                                &nbsp;<i className="fa fa-arrow-right" aria-hidden="true"></i>
                            </a>
                        }
                        
                    </div>
                    <div className="sidebar-widget">
                        <div className="job-overview">
                            <div className="job-overview-headline">{t('summary')}</div>
                            <div className="job-overview-inner">
                                <ul>
                                    <li>
                                        { details.city && 
                                        <>
                                        <i className="fa fa-map-marker fa-lg" aria-hidden="true"></i>
                                        <span>{t('location')}</span>
                                        <h5>{details.city}</h5>
                                        </>
                                        }
                                    </li>
                                    <li>
                                        {details.type &&
                                        <>
                                        <i className="fa fa-briefcase fa-lg" aria-hidden="true"></i>
                                        <span>{t('job_type')}</span>
                                        <h5>{details.type?details.type.name:null}</h5>
                                        </>
                                        }
                                    </li>
                                    <li>
                                        <i class="fa fa-money fa-lg" aria-hidden="true"></i>
                                        <span>{t('salary')}</span>
                                        <h5>{t(utils.formatSalary(details.salaryFrom, details.salaryTo, details.currencyCode))}</h5>
                                    </li>
                                    <li>
                                        <i className="fa fa-calendar fa-lg" aria-hidden="true"></i>
                                        <span>{t('expired_at')}</span>
                                        <h5>{moment(details.expiredAt).format("MM/DD/YYYY")}</h5>
                                    </li>
                                    <li>
                                        <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                                        <span>{t('published_at')}</span>
                                        <h5>{t(utils.formatPublishedAt(details.publishedAt))}</h5>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/*<div class="sidebar-widget">
                        <h3>{t('bookmark_or_share')}</h3>

                        <button class="btn bookmark-button margin-bottom-25 pl-3">
                            <i class="fa fa-bookmark-o" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;
                            {t('bookmark')}
                            <span class="bookmark-text">Bookmark</span>
                            <span class="bookmarked-text">Bookmarked</span>
                        </button>

                        <div class="copy-url">
                            <input id="copy-url" type="text" value="" class="input w-100 with-border"/>
                            <button class="btn copy-url-button ripple-effect" data-clipboard-target="#copy-url" data-tippy-placement="top" data-tippy="" data-original-title="Copy to Clipboard"><i class="fa fa-clipboard" aria-hidden="true"></i></button>
                        </div>

                        <div class="share-buttons margin-top-25">
                            <div class="share-buttons-trigger"><i class="fa fa-share-alt" aria-hidden="true"></i></div>
                            <div class="share-buttons-content">
                                <span>{t('share_interesting')} <strong>{t('share_it')}</strong></span>
                                <ul class="share-buttons-icons">
                                    <li><a href="#" data-button-color="#3b5998" data-tippy-placement="top" data-tippy="" data-original-title="Share on Facebook" style={{backgroundColor: "rgb(59, 89, 152)"}}><i class="fa fa-facebook-square" aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-button-color="#1da1f2" data-tippy-placement="top" data-tippy="" data-original-title="Share on Twitter" style={{backgroundColor: "rgb(29, 161, 242)"}}><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-button-color="#0077b5" data-tippy-placement="top" data-tippy="" data-original-title="Share on LinkedIn" style={{backgroundColor: "rgb(0, 119, 181)"}}><i class="fa fa-linkedin-square" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                </div>*/}

                </div>
            </div>
                </div>
                
            </div>
        </>
    )
};
export default withTranslation()(JobDetails);