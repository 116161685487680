import React from 'react';
import {withTranslation} from 'react-i18next';
const CommentAdd = ({t, allowComment}) => {
    return (
        <>
            { allowComment && <div class="row">
                            <div class="col-xl-12">
                                <h3 class="margin-top-35 margin-bottom-30">{t('comment_add')}</h3>
                                <form method="post" id="add-comment">

                                    <div class="row">
                                        <div class="col-xl-6">
                                            <div class="input-with-icon-left no-border">
                                                <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                                <input type="text" class="input-text input-search" name="commentname" id="namecomment" placeholder="Name" required=""/>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="input-with-icon-left no-border">
                                                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                                <input type="text" class="input-text input-search" name="emailaddress" id="emailaddress" placeholder="Email Address" required=""/>
                                            </div>
                                        </div>
                                    </div>

                                    <textarea className="input-search h-auto" name="comment-content" cols="30" rows="5" placeholder="Comment"></textarea>
                                </form>
                                <button class="button button-sliding-icon ripple-effect margin-bottom-40" type="submit" form="add-comment" style={{width: "176.203px"}}>Add Comment <i class="icon-material-outline-arrow-right-alt"></i></button>
                            </div>
                        </div>
            }
        </>
    )
};
export default withTranslation()(CommentAdd)