import React, { useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';
import { withTranslation } from "react-i18next"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import * as config from '../config';
import authService from '../services/AuthService';
import * as validations from '../constants/validations';
import validate from 'validate.js';
import * as utils from '../utils';
import moment from 'moment';
import numerologyService from '../services/NumerologyService'
import { HTTP_RESPONSE_STATUS } from '../constants/http';
import Menu from "./Menu";
import {SUPPORT_LANGUAGES} from '../constants';
import languageService from '../services/LanguageService';

const INIT_VALUES = {
	lastName: "",
	firstName: "",
	middleName: "",
	birthday: "",
};
const INIT_ERRORS = {
	lastName: "",
	firstName: "",
	middleName: "",
	birthday: ""
};
const constrainsts = {
	lastName: validations.FIELD_REQUIRED_STRING_100,
	middleName: {
		length: {
			maximum: 100,
			message: "^field_too_long_max_100"
		}
	},
	firstName: validations.FIELD_REQUIRED_STRING_100,
    birthday: {
        presence: {
            allowEmpty: false,
            message: "^field_required"
        },
        datetime: {
            dateOnly: true,
            message: "^date_required_format"
        }
    }
};
// Before using it we must add the parse and format functions
// Here is a sample implementation using moment.js
// Before using it we must add the parse and format functions
// Here is a sample implementation using moment.js
validate.extend(validate.validators.datetime, {
    // The value is guaranteed not to be null or undefined but otherwise it
    // could be anything.
    parse: function(value, options) {
      return +moment.utc(value);
    },
    // Input is a unix timestamp
    format: function(value, options) {
      var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
      return moment.utc(value).format(format);
    }
});
const Header = ({t, i18n}) => {
	const [ scrolled, setScrolled ] = useState();
	const [showModal, setShowModal] = useState(false);
	const [values, setValues] = useState(INIT_VALUES);
	const [errors, setErrors] = useState(INIT_ERRORS);
	const [alert, setAlert] = useState("");
	const [language, setLanguage] = useState("vi");
	const history = useHistory();
	const classes = classNames('header','trans_400', {
		scrolled: scrolled,
	});
	const [selectedNavItem, setSelecedNavItem] = useState(1); 
	const [openMenu, setOpenMenu] = useState(false);
	const handleOpenMenu = (e) => {
		setOpenMenu(true);
	};
	const handleCloseMenu = (e) => {
		setOpenMenu(false);
	};
	const handleChangeFields = (e) => {
        values[e.target.name] = e.target.value;
        setValues({...values});
    };
	useEffect(_ => {
		let langCode = languageService.readLang();
		const rs = SUPPORT_LANGUAGES.find(item => item.code === langCode);
		if (rs) {
			setLanguage(rs.code);
		}
		const handleScroll = _ => { 
			if (window.pageYOffset > 30) {
				setScrolled(true)
			} else {
				setScrolled(false)
			}
		}
		window.addEventListener('scroll', handleScroll)
		return _ => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, []);
	const handleClose = () => {
		setValues({...INIT_VALUES});
		setErrors({...INIT_ERRORS});
		setShowModal(false);
	};
	const handleSend = (e) => {
		setAlert("");
		setErrors({});
		e.preventDefault();
        let reqData = {
			lastName: values.lastName?values.lastName: null,
			middleName: values.middleName?values.middleName: null,
			firstName: values.firstName?values.firstName: null,
            birthday: values.birthday?values.birthday: null,
        };
		const fieldErrors = validate(reqData, constrainsts);
		if (fieldErrors) {
			const flatErrrors = utils.convertErrors2Object(fieldErrors);
			setErrors({...flatErrrors});
            return;
		}
		numerologyService.createProfile(reqData)
		.then(resObj => {
			if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
				setValues({});
				setErrors({});
				setShowModal(false);
				history.push("/numerology/profile-summary/" + resObj.data);
			} else {
				setAlert('try_again');
			}
		})
		.catch (err => {
			setAlert('try_again');
		});
	};
	const handleRedirect = (e) => {
		e.preventDefault();
		if (authService.isLoggin()) {
			const win = window.open(config.MY_ACCOUNT, "_blank");
			win.focus();
		} else {
			//history.push(config.LOGIN_REDIRECT);
			window.location.replace(config.LOGIN_REDIRECT);

		}
	};
	const changeLanguage = (lang,e) => {
		e.preventDefault();
		setLanguage(lang);
		i18n.changeLanguage(lang);

	};
	const handleSelectNavItem = () => {

	};
	return (
		<>
		<header className={classes}>
			<div className="header_content d-flex flex-row align-items-center jusity-content-start trans_400">
				<div className="logo">
					<a href="/">
						<div>Top<span>WORKS</span></div>
						<div>{t('change_your_life')}</div>
					</a>
				</div>
				<nav className="main_nav">
					<ul className="d-flex flex-row align-items-center justify-content-start">
						<li className="active"><a href="/">{t('home')}</a></li>
						<li><a href="/jobs">{t('jobs')}</a></li>
						<li><a href="/recruiters">{t('recruiters')}</a></li>
						<li><a href="/talents">{t('resume')}</a></li>
						<li><a href="/blogs">{t('news')}</a></li>
						{/*<li><a href="/helps">{t('helps')}</a></li>*/}
					</ul>
				</nav>
				<div className="header_extra d-flex flex-row align-items-center justify-content-end ml-auto">
					
					{/*<div className="work_hours">Mo - Sat: 8:00am - 9:00pm</div>
					<div className="header_phone">+34 586 778 8892</div>*/}
					<button className="button button_1 intro_button pl-3 pr-3 mr-2" onClick={() => history.push('/numerology')}>{t('your_numerology')}</button>
					<div className="social header_social">
						<ul className="d-flex flex-row align-items-center justify-content-start">
							<li><a onClick={(e) => handleRedirect(e)}><i className="fa fa-user-circle-o" aria-hidden="true"></i></a></li>
							{/*<li>
								<Dropdown as="a">
									<Dropdown.Toggle as="i" className="fa fa-globe">
									</Dropdown.Toggle>
									<Dropdown.Menu>
										{
											SUPPORT_LANGUAGES.map((item,idx) => {
												if (item.code === language) {
													return <Dropdown.Item className="d-flex active" onClick={ (e) => changeLanguage(item.code,e)}><img src={item.flag} with="30" height="30" className="d-inline-block align-top mr-2" alt={t(item.name)}/><div className="my-auto">{t(item.name)}</div></Dropdown.Item>
												}
												return <Dropdown.Item className="d-flex" onClick={ (e) => changeLanguage(item.code,e)}><img src={item.flag} with="30" height="30" className="d-inline-block align-top mr-2" alt={t(item.name)}/><div className="my-auto">{t(item.name)}</div></Dropdown.Item>

											}) 
										}
										
									</Dropdown.Menu>
								</Dropdown>
									</li>*/}
							<li><a href="https://www.facebook.com/TopWorksVN" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
							<li><a href="https://twitter.com/TopWorksVN" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
						</ul>
					</div>
					<div className="hamburger" onClick={handleOpenMenu}><i className="fa fa-bars" aria-hidden="true"></i></div>

				</div>
			</div>
			{/*<Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
				<Modal.Title>{t('your_information')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						{alert.length > 0 && <Alert>{t(alert)}</Alert>}
						<Form.Group controlId="lastName">
							<Form.Label>{t('last_name')}</Form.Label>
							<Form.Control type="text" placeholder={t('last_name')} name="lastName" value={values.lastName} onChange={handleChangeFields}/>
							<small className="text-danger">{t(errors.lastName)}</small>
						</Form.Group>

						<Form.Group controlId="middleName">
							<Form.Label>{t('middle_name')}</Form.Label>
							<Form.Control type="text" placeholder={t('middle_name')} name="middleName" value={values.middleName} onChange={handleChangeFields}/>
							<small className="text-danger">{t(errors.middleName)}</small>
						</Form.Group>
						<Form.Group controlId="firstName">
							<Form.Label>{t('first_name')}</Form.Label>
							<Form.Control type="text" placeholder={t('first_name')} name="firstName" value={values.firstName} onChange={handleChangeFields}/>
							<small className="text-danger">{t(errors.firstName)}</small>
						</Form.Group>
						<Form.Group controlId="birthday">
							<Form.Label>{t('birthday')}</Form.Label>
							<Form.Control type="date" placeholder="mm/dd/yyy" name="birthday" value={values.birthday} onChange={handleChangeFields}/>
							<small className="text-danger">{t(errors.birthday)}</small>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					{t('cancel')}
				</Button>
				<Button variant="primary" onClick={handleSend}>
					{t('send')}
				</Button>
				</Modal.Footer>
			</Modal>
								*/}
		</header>
		<Menu open={openMenu} close={handleCloseMenu}/>
		</>
	)
}
export default withTranslation()(Header)