import React, { useEffect, useState } from 'react';
import companyService from "../../../services/CompanyService";
import * as constants from "../../../constants";
import {HTTP_RESPONSE_STATUS} from '../../../constants/http';
import * as utils from '../../../utils';
import {withTranslation} from 'react-i18next';

const JobListItem = ({t,item}) => {
    return (
        <>
            <a href={"/jobs/details/" + item.id} className="job-listing">
                <div className="job-listing-details">

                    <div className="job-listing-description">
                        <h3 className="job-listing-title">{item.title}</h3>

                        <div className="job-listing-footer">
                            <ul>
                                <li><i className="fa fa-globe"></i> {item.city}</li>
                                <li><i className="fa fa-briefcase"></i> {item.type?item.type.name: ""}</li>
                                <li><i className="fa fa-clock-o" aria-hidden="true"></i> {t(utils.formatPublishedAt(item.publishedAt))}</li>
                            </ul>
                        </div>
                    </div>

                </div>
                {/*						<span class="bookmark-icon"></span>
*/}
            </a>
        </>
    );
}
export default withTranslation()(JobListItem)