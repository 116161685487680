import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import Carousel from 'react-bootstrap/Carousel';
import RegisterForm from './components/RegisterForm';
import adsService from  '../../services/AdsService';
import globalMetricService from '../../services/GlobalMetricService';
import promotionService from '../../services/PromotionService';
import numeral from 'numeral';
import {HTTP_RESPONSE_STATUS} from '../../constants/http';
import {BUTTON_ACTIONS} from '../../constants';
import { Link } from 'react-router-dom';
import CallToAction from '../../components/CalToAction';
const styles = {
    backgroundColor: "black",
    color: "white",
    borderRadius: "50%",
    textDecoration: "none",
    padding: "20px"
};
const Home = ({t}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [adsItems, setAdsItems] = useState([]);
    const [globalMetrics, setGlobalMetrics] = useState({});
    const [promotions, setPromotions] = useState([]);
    const [selectedPromotion, setSetectedPromotion] = useState(-1);
    const loadGlobalMetrics = () => {
        setIsLoading(true);
        globalMetricService.getLatestMetrics()
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setGlobalMetrics(resObj.data)
                }
            }
        })
        .catch(err => {
            setIsLoading(false);
        });
    };
    useEffect( () => {
        loadGlobalMetrics();
        adsService.getPublicAds(3)
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    
                    setAdsItems(resObj.data);
                } else {
                    setAdsItems([]);
                }
            }
        })
        .catch(err => {
            setAdsItems([]);
            setIsLoading(false);
        });
        promotionService.getLatestPromotions()
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    let idx = Math.floor(Math.random() * resObj.data.length);
                    setSetectedPromotion(idx);
                    setPromotions(resObj.data);
                } else {
                    setPromotions([]);
                }
            }
        })
        .catch(err => {
            setPromotions([]);
            setIsLoading(false);
        });
    },[]);
    return (

        <>
            <div className="clearfix"></div>
            <div className="home">
                <Carousel
                    nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" style={{width: "24px", height: "24px"}}/>}
                    prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" style={{width: "24px", height: "24px"}}/>}>
                    {
                        adsItems.map( (item, idx) => {
                            return (
                                <Carousel.Item key={idx}>
                                    <img className="d-block w-100" style={{maxHeight: "80vh", objectFit: "cover"}} src={item.mediaUrl} 
                                    alt={item.title} loading="lazy"/>
                                    <div className="home_container">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col">
                                                    <div className="home_content">
                                                        <div className="home_subtitle">{item.subTitle}</div>
                                                        <div className="home_title" style={{color: "white"}}>{item.title}</div>
                                                        <div className="home_text">
                                                            <p>{item.description}</p>
                                                        </div>
                                                        <div className="home_buttons d-flex flex-row align-items-center justify-content-start">
                                                            {
                                                                <div className="button button_1 trans_200"><a href={item.linkToButton} target={item.openNewTab?"_blank": null}>{t(BUTTON_ACTIONS[item.button]?BUTTON_ACTIONS[item.button]: "None")}</a></div>
                                                            /*<div class="button button_1 trans_200"><a href="/numerology">{t('read_more')}</a></div>
                                                            <div class="button button_2 trans_200"><a href="/make-an-appointment">{t('make_an_appointment')}</a></div>
                                                            */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            )
                        })
                    }    
                    {/*<Carousel.Item>
                        <img className="d-block w-100" style={{maxHeight: "80vh", objectFit: "cover"}} src="https://res.cloudinary.com/topworks/image/upload/v1618468986/self-discovery.jpg" 
                        alt="Thay đổi cuộc sống với thần số học" loading="lazy"/>
                        <div className="home_container">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="home_content">
                                            <div className="home_subtitle">#1 Khám phá bản thân</div>
                                            <div className="home_title" style={{color: "white"}}>Thần số học</div>
                                            <div className="home_text">
                                                <p>Hiểu ro bản thân để cải thiện cuộc sống theo hướng tối ưu nhất</p>
                                            </div>
                                            <div class="home_buttons d-flex flex-row align-items-center justify-content-start">
                                                <div class="button button_1 trans_200"><a href="/numerology">{(t('read_more'))}</a></div>
                                                <div class="button button_2 trans_200"><a href="/make-an-appointment">{(t('make_an_appointment'))}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100" style={{maxHeight: "80vh", objectFit: "cover"}} src="https://res.cloudinary.com/topworks/image/upload/v1618469938/website/banners/find-jobs.jpg" 
                        alt="Thay đổi cuộc sống với thần số học" loading="lazy"/>
                        <div className="home_container">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="home_content">
                                            <div className="home_subtitle">#2 Tìm việc trong kỷ nguyên số</div>
                                            <div className="home_title" style={{color: "white"}}>Trí tuệ nhân tạo</div>
                                            <div className="home_text" style={{textColor: "#57ccc3"}}>
                                                <p>Công việc phù hợp nhất với kiến thức, kỹ năng, thái độ và số học của bạn.</p>
                                            </div>
                                            <div class="home_buttons d-flex flex-row align-items-center justify-content-start">
                                                <div class="button button_1 trans_200"><a href="/jobs/finder">{(t('read_more'))}</a></div>
                                                <div class="button button_2 trans_200"><a href="/make-an-appointment">{(t('make_an_appointment'))}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                    <img className="d-block w-100" style={{maxHeight: "80vh", objectFit: "cover"}} src="https://res.cloudinary.com/topworks/image/upload/v1618470833/website/banners/teamwork.jpg" 
                        alt="Thay đổi cuộc sống với thần số học" loading="lazy"/>
                        <div className="home_container">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="home_content">
                                            <div className="home_subtitle">#3 Xây dựng đội ngũ nhân sự hùng mạnh</div>
                                            <div className="home_title" style={{color: "white"}}>Trí tuệ nhân tạo</div>
                                            <div className="home_text" style={{textColor: "#57ccc3"}}>
                                                <p>Tìm kiếm ứng viên phù hợp nhất với doanh nghiệp</p>
                                            </div>
                                            <div class="home_buttons d-flex flex-row align-items-center justify-content-start">
                                                <div class="button button_1 trans_200"><a href="/recruiters">{(t('read_more'))}</a></div>
                                                <div class="button button_2 trans_200"><a href="/make-an-appointment">{(t('make_an_appointment'))}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>*/}
                </Carousel>
            </div>
            <div className="intro">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 intro_col">
                        <div className="intro_content">
                            <div className="section_title_container">
                                <div className="section_subtitle">{t('this_is_topworks')}</div>
                                <div className="section_title"><h2>{t('this_is_topworks_sub_title')}</h2></div>
                            </div>
                            <div className="intro_text">
                                <p>{t('topworks_summarization')}</p>
                            </div>
                            <div className="milestones">
                                <div className="row milestones_row">							
                                    <div className="col-md-4 milestone_col">
                                        <div className="milestone">
                                            <div className="milestone_counter" data-end-value="5000" data-sign-before="+" style={{textTransform:"uppercase"}}>{globalMetrics.totalCompanies? numeral(globalMetrics.totalCompanies).format('0a+'): 0}</div>
                                            <div className="milestone_text">{t('company')}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 milestone_col">
                                        <div className="milestone">
                                            <div className="milestone_counter" data-end-value="352" style={{textTransform:"uppercase"}}>{globalMetrics.totalResumes? numeral(globalMetrics.totalResumes).format('0a+'): 0}</div>
                                            <div className="milestone_text">{t('talent')}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 milestone_col">
                                        <div className="milestone">
                                            <div className="milestone_counter" data-end-value="718" style={{textTransform:"uppercase"}}>{globalMetrics.totalJobs? numeral(globalMetrics.totalJobs).format('0a+'): 0}</div>
                                            <div className="milestone_text">{t('job')}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 intro_col">
                        <RegisterForm />
                    </div>

                </div>
            </div>
        </div>
            <div class="services">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <div class="section_title_container">
                                <div class="section_subtitle">Nền tảng <strong>TopWORKS</strong></div>
                                <div class="section_title"><h2>Giải pháp nhân sự cho bạn</h2></div>
                            </div>
                        </div>
                    </div>
                    <div class="row services_row">                    
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/clipboard.svg" alt="https://www.flaticon.com/authors/prosymbols" loading="lazy"/></div>
                                    </div>
                                    <div class="service_title">Ứng viên tài năng</div>
                                    <div class="service_text">
                                        <p>- Nhiều lựa chọn nghề nghiệp hấp dẫn đang chờ bạn.
- Được xây dựng lộ trình thăng tiến phù hợp từng mảng công việc chức năng.
- Được đánh giá năng lực hiện tại.
- Được hỗ trợ ngân hàng bài học nâng cao kỹ năng và chuyên môn nghiệp vụ, để chủ động nâng cấp bản thân đạt được các mục tiêu cao hơn trong sự nghiệp.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/file.svg" alt="https://www.flaticon.com/authors/prosymbols" loading="lazy"/></div>
                                    </div>
                                    <div class="service_title">Đăng tin tuyển dụng</div>
                                    <div class="service_text">
                                        <p>- Tự đăng tuyển theo form mẫu/nhờ admin hỗ trợ
-  Tìm kiếm ứng viên  trực tiếp trên web
-   Lưu trữ hồ sơ về máy tính cá nhân hoặc thông qua email
Tính năng notification</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/curriculum.svg" alt="https://www.flaticon.com/authors/prosymbols" loading="lazy"/></div>
                                    </div>
                                    <div class="service_title">Cung ứng hồ sơ ứng viên</div>
                                    <div class="service_text">
                                        <p>Trợ lý AI sẵn sàng đáp ứng yêu cầu của Doanh nghiệp về chất lượng và độ tương thích môi trường làm việc của hồ sơ ứng viên.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/idea.svg" alt="https://www.flaticon.com/authors/prosymbols" loading="lazy"/></div>
                                    </div>
                                    <div class="service_title">Cung cấp lao động thuê ngoài</div>
                                    <div class="service_text">
                                        <p>Cung cấp nhân sự có chuyên môn cao trong lĩnh vực công nghệ thông tin, chăm sóc khách hàng,... tham gia vào dự án của Doanh nghiệp.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/calculator.svg" alt="https://www.flaticon.com/authors/prosymbols" loading="lazy"/></div>
                                    </div>
                                    <div class="service_title">Tư vấn giải pháp tối ưu nhân sự</div>
                                    <div class="service_text">
                                        <p>- Ứng dụng công nghệ
- Chiến lược công nghệ
- Quy trình đánh giá chất lượng nhân sự</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/files.svg" alt="https://en.wikipedia.org/wiki/Data" loading="lazy"/></div>
                                </div>
                                <div class="service_title">Những con số biết nói</div>
                                <div class="service_text">
                                    <p>Đánh giá chuyên sâu từ phân tích dữ liệu của hơn 500+ doanh nghiệp và 100.000+ hồ sơ ứng viên.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            {/*<div class="cta">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="cta_container d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start">
                                <div class="cta_content">
                                    <div class="cta_title">Gọi chúng tôi ngay bây giờ!</div>
                                    <div class="cta_text">Nhận được tư vấn và ưu đãi khi sử dụng dịch vụ của chúng tôi</div>
                                </div>
                                <div class="cta_phone ml-lg-auto">+84 90 866 77 96</div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>*/}
            <CallToAction title={t('cta_call_now')} phone={"+84 90 866 77 96"} text={t('cta_call_now_support_service')}/> 

            <div class="prices">
                <div class="container">
                    <div class="row">                    
                        <div class="col-lg-6 price_col">
                            <div class="price">
                                <div class="price_title">Số học cho cá nhân</div>
                                <div class="price_text">
                                    <p>Giúp bạn hiểu sâu sắc về chính bản thân mình và từ đó sẽ phát huy được năng lực sở trường cũng như cải thiện những điểm yếu, tập trung năng lực và bước vững chắc trên nấc thang thăng tiến sự nghiệp.</p>
                                </div>
                                {/*<div class="price_panel">{t('contact')}</div>*/}
                            </div>
                        </div>
                        {/*<div class="col-lg-6 price_col">
                            <div class="price">
                                <div class="price_title">Số học cho doanh nghiệp</div>
                                <div class="price_text">
                                    <p>Các báo cáo về số học sẽ giúp doanh nghiệp đánh giá tổng thể về tình hình nhân sự và có thể điều chỉnh để đạt hiệu quả cao trong quản ly nguồn lực của mình.</p>
                                </div>
                                <div class="price_panel">Miễn phí</div>
                            </div>
            </div>*/}
                        <div class="col-lg-6 price_col">
                            <div class="price">
                                <div class="price_title">Tìm kiếm công việc</div>
                                <div class="price_text">
                                    <p>Hơn 1000+ công việc trên hệ thống được cập nhật liên tục từ các đối tác, công ty ... Bạn sẽ dễ dàng tìm thấy công việc phù hợp dựa trên hệ thống tư vấn tự động của chúng tôi.</p>
                                </div>
                                {/*<div class="price_panel">{t('contact')}</div>*/}
                                </div>
                        </div>
                        <div class="col-lg-6 price_col">
                            <div class="price">
                                <div class="price_title">Tìm kiếm ứng viên</div>
                                <div class="price_text">
                                    <p>100.000+ ứng viên chất lượng đang tìm môi trường làm việc mới. Chúng tôi cung cấp danh sách các ứng viên phù hợp với nhu cầu tuyển dụng của bạn.</p>
                                </div>
                                {/*<div class="price_panel">{t('contact')}</div>*/}
                                </div>
                        </div>
                        <div class="col-lg-6 price_col">
                            <div class="price">
                                <div class="price_title">Tư vấn phát triển nghề nghiệp</div>
                                <div class="price_text">
                                    <p>Nền tảng sẽ phân tích số học cũng như hồ sơ kinh nghiệm làm việc của bạn. Dựa trên thông tin bạn cung cấp, nền tảng công nghệ sẽ phân tích đánh giá các khía cạnh và đồng thời chuyên gia tư vấn của chúng tôi sẽ giúp bạn trong việc lựa chọn nghề nghiệp phù hợp và định hướng lộ trình phát triển sự nghiệp của bạn theo hướng tối ưu nhất.</p>
                                </div>
                                {/*<div class="price_panel">{t('contact')}</div>*/}
                                </div>
                        </div>
                        <div class="col-lg-6 price_col">
                            <div class="price">
                                <div class="price_title">Hỗ trợ tuyển dụng</div>
                                <div class="price_text">
                                    <p>Dựa trên bản mô tả công việc, nền tảng của chúng tôi sẽ đánh giá các khía cạnh khác nhau để tìm ra các ứng viên phù hợp nhất cho doanh nghiệp. Đông thời, chúng tôi cũng hỗ trợ bạn qui trình tuyển chọn và đánh giá chất lượng ứng viên một cách nhanh chóng, hiệu quả.</p>
                                </div>
                                {/*<div class="price_panel">{t('contact')}</div>*/}
                                </div>
                        </div>
                        <div class="col-lg-6 price_col">
                            <div class="price">
                                <div class="price_title">Tư vấn công nghệ nhân sự</div>
                                <div class="price_text">
                                    <p>Chuyển đổi số là xu hướng bắt buộc hiện tại và tương lai. Việc ứng dụng công nghệ vào quản trị nhân sự và tuyển dụng là một nội dung trong quá trình chuyển đổi này. Chúng tôi đã ứng dụng công nghệ một cách hiệu quả vào hoạt động của mình và liên kết với các đối tác để xây dựng một giải pháp công nghệ hiệu quả nhất cho doanh nghiệp của bạn.</p>
                                </div>
                                {/*<div class="price_panel">{t('contact')}</div>*/}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                promotions.length > 0 &&
                <div class="extra">
                    <div class="parallax_background parallax-window" data-parallax="scroll" style={{backgroundImage: "url('https://res.cloudinary.com/topworks/image/upload/v1628633363/subcribe-newletters.jpg')"}} data-image-src="images/extra.jpg" data-speed="0.8"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="extra_container d-flex flex-row align-items-start justify-content-end">
                                    <div class="extra_content">
                                        <div class="extra_disc d-flex flex-row align-items-end justify-content-start">
                                            <div>{promotions[selectedPromotion].discountAmount}<span>%</span></div>
                                            <div>{t('discount')}</div>
                                        </div>
                                        <div class="extra_title">{promotions[selectedPromotion].name}</div>
                                        <div class="extra_text">
                                            <div dangerouslySetInnerHTML={{__html: promotions[selectedPromotion].shortDescription?promotions[selectedPromotion].shortDescription:""}} />
                                        </div>
                                        <div class="button button_1 extra_link trans_200"><Link to={"/promotions/details/"+ promotions[selectedPromotion].id}>{t('read_more')}</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            
        </>
    )
}
export default withTranslation()(Home)