import React from 'react';
import moment from "moment";
import { withTranslation } from "react-i18next";
const EducationHistoryItem = ({t, item}) => {
    return (
        <>
            <div className="boxed-list-item">
                <div className="item-image">
                    <img src="images/browse-companies-04.png" alt=""/>
                </div>							
                <div className="item-content">
                    <h4><a href="">{item.subject}</a></h4>
                    <div className="item-details margin-top-7">
                        <div className="detail-item"><a href=""><i class="fa fa-university" aria-hidden="true"></i> {item.school}</a></div>
                        <div className="detail-item"><i class="fa fa-calendar" aria-hidden="true"></i> {moment(item.dateFrom).format("MM/YYYY") + " - " + (item.dateTo?moment(item.dateTo).format("MM/YYYY"): t('now'))}</div>
                    </div>
                    <div className="item-description" dangerouslySetInnerHTML={{__html: item.achievements}} >
                    </div>
                </div>
            </div>
        </>
    );
}
export default withTranslation()(EducationHistoryItem)