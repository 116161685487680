import React from 'react'
import CallToAction from '../../containers/CallToAction'
import NumerologyForm from './components/NumerologyForm'
const Numerology = () => (
    <>
        <div className="why header_wrapper">
		{/*<div class="background_image" style="background-image:url(images/why.jpg)"></div>*/}
            <div class="container">
                <div class="row row-eq-height">
                    <div class="col-lg-6 order-lg-1 order-2">
                        <div class="why_image_container">
                            <div class="why_content">
                                <NumerologyForm />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 order-lg-2 order-1">
                        <div class="why_content">
                            <div class="section_title_container">
                                <div class="section_subtitle">Giới thiệu</div>
                                <div class="section_title"><h2>Thần số học</h2></div>
                            </div>
                            <div class="why_text">
                                <p><strong>Thần số học (Numerology)</strong> là môn khoa học nghiên cứu về <strong>khám phá bản thân</strong>.
                                Các ghi chép lịch sử về số học đã được tìm thấy ở Babylon và Ai Cập, và có bằng chứng về sự tồn tại của nó cách đây hàng nghìn năm ở Hy Lạp, La Mã, Nhật Bản và Trung Quốc. <br/>
                                Trải qua nhiều thời đại nghiên cứu, thần số học đã phát triển và được biết đến nhiều với tên tuổi của <strong>Pythagoras</strong>. Ông là một triết gia và nhà toán học người Hy Lạp sống trong thời đại 590 trước Công nguyên, đã xây dựng và bổ sung các triết lý và nghiên cứu toán học của riêng mình, do đó hình thành nên <strong>Hệ số Pythagoras</strong>.<br/>
                                    <strong>Từ những năm 1970</strong>, thần số học đã được mọi tầng lớp xã hội sử dụng như một công cụ trợ giúp được cá nhân hóa. Ngày nay, thần số học được nghiên cứu và sử dụng trên khắp thế giới và được hàng triệu người sử dụng để giúp thay đổi cuộc sống theo hướng tốt đẹp hơn.<br/>
                                    <strong>TopWORKS</strong> là nền tảng công nghệ được chúng tôi nghiên cứu dựa trên các tri thức truyền thống về <strong>số học của Pythagoras</strong> kết hợp với <strong>trí tuệ nhân tạo và khoa học dữ liệu</strong> để làm nên các báo cáo số học chi tiết đáng tin cậy.</p>
                            </div>
                            {/*
                            <div class="why_list">
                                <ul>
                                    <li class="d-flex flex-row align-items-center justify-content-start">
                                        <div class="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div class="icon"><img src="images/icon_1.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div class="why_list_content">
                                            <div class="why_list_title">Tự khám phá</div>
                                            <div class="why_list_text">Etiam ac erat ut enim maximus accumsan vel ac nisl</div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-row align-items-center justify-content-start">
                                        <div class="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div class="icon"><img src="images/icon_2.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div class="why_list_content">
                                            <div class="why_list_title">Không tâm linh</div>
                                            <div class="why_list_text">Ac erat ut enim maximus accumsan vel ac</div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-row align-items-center justify-content-start">
                                        <div class="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div class="icon"><img src="images/icon_3.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div class="why_list_content">
                                            <div class="why_list_title">Không kỳ vọng</div>
                                            <div class="why_list_text">Etiam ac erat ut enim maximus accumsan vel</div>
                                        </div>
                                    </li>
                                    <li class="d-flex flex-row align-items-center justify-content-start">
                                        <div class="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div class="icon"><img src="images/icon_3.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div class="why_list_content">
                                            <div class="why_list_title">Không chủ quan</div>
                                            <div class="why_list_text">Etiam ac erat ut enim maximus accumsan vel</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="services">
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <div class="section_title_container">
                            <div class="section_subtitle">Thần số học</div>
                            <div class="section_title"><h2>Nguyên lý hoạt động</h2></div>
                            <br/>
                            <p>Cách thức hoạt động của thần số học thực sự rất đơn giản, nó là quá trình chuyển các thông tin về <strong>ngày sinh, tên khai sinh và một số thuộc tính </strong>liên quan đến bạn thành các con <strong>số tự nhiên</strong>. 
                                Bạn có thể dễ dàng xác định con số đường đời của mình và những thông tin như biểu hiện, tính cách và tâm hồn của bạn.
                                Ý tưởng đằng sau của thần số học là vũ trụ và cuộc sống của bạn bị ảnh hưởng bởi ngày sinh, tên sinh và nhiều yếu tố khác xung quanh bạn. Theo hướng tiếp cận này, một báo cáo số học tổng hợp có thể cung cấp một cách tự khám phá sâu sắc theo cách mà những con số này kết hợp với nhau cần được giải thích đúng. Do đó, nó có thể cung cấp những thông tin chi tiết chính xác một cách đáng kinh ngạc về ai đó.</p>
                            <p><strong>Có 6 con số quan trọng trong biểu đồ số học của bạn</strong></p>
                        </div>
                    </div>
                </div>
                <div class="row services_row">                    
                    <div class="col-xl-4 col-md-6 service_col">
                        <div class="service text-center">
                            <div class="service">
                                <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div class="icon"><img src="images/icon_4.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                </div>
                                <div class="service_title">Đường đời</div>
                                <div class="service_text">
                                    <p>Mô tả hành trình cuộc đời của bạn, là những gì bạn đã, đang và sẽ đi qua. Cuộc đời là trường học, và con số Đường đời giúp chúng ta nhìn nhận rõ hơn các bài học trong cuộc đời mình.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 service_col">
                        <div class="service text-center">
                            <div class="service">
                                <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div class="icon"><img src="images/icon_5.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                </div>
                                <div class="service_title">Tên khai sinh</div>
                                <div class="service_text">
                                    <p>Tên khai sinh mang nguồn năng lượng rung động được đến từ bên ngoài và cả cảm nhận từ chính bạn đối với họ tên của mình. Nó giúp bạn trả lời được câu hỏi sứ mệnh cuộc đời của bạn là gì?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 service_col">
                        <div class="service text-center">
                            <div class="service">
                                <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div class="icon"><img src="images/icon_6.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                </div>
                                <div class="service_title">Thái độ</div>
                                <div class="service_text">
                                    <p>Thể hiện phản ứng của con người đối với chính mình, với người khác và với thế giới. Thái độ được biểu hiện ra bên ngoài thông qua lời nói và ngôn ngữ cơ thể.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 service_col">
                        <div class="service text-center">
                            <div class="service">
                                <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div class="icon"><img src="images/icon_7.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                </div>
                                <div class="service_title">Nội tâm</div>
                                <div class="service_text">
                                    <p>Đại diện cho những mong muốn, khát khao một cách tự nhiên và bản năng nhất của bạn.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 service_col">
                        <div class="service text-center">
                            <div class="service">
                                <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div class="icon"><img src="images/icon_8.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                </div>
                                <div class="service_title">Tương tác</div>
                                <div class="service_text">
                                    <p>Thể hiện “con người bên ngoài” của bạn, cách bạn ứng xử, đối đãi với người khác và đối với thế giới.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 service_col">
                        <div class="service text-center">
                            <div class="service">
                                <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                    <div class="icon"><img src="images/icon_3.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
							</div>
							<div class="service_title">Ngày sinh</div>
							<div class="service_text">
								<p>Cho thấy cách bạn quan sát, cảm nhận thế giới và những ấn tượng của người khác về bạn.</p>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>        
	</div>
        <CallToAction />
        <div class="services">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <div class="section_title_container">
                                <div class="section_subtitle">Thần số học</div>
                                <div class="section_title"><h2>Ứng dụng thực tiễn</h2></div>
                                <br/>
                                <p>Thần số học cũng giống như các môn khoa học khác như kinh tế học, toán học, xã hội học ... 
                                    Việc nghiên cứu lý thuyết phải dẫn đến quá trình ứng dụng vào thực tiễn đem lại lợi ích cho cá nhân và xã hội theo hướng tích cực hơn. Với mục tiêu cao cả nhất là làm cho cuộc sống ngày càng tốt đẹp hơn. 
                                    Tuy nhiên, bất cứ môn khoa học nào cũng có mặt tích cực và tiêu cực của một vấn đề. Đặc biệt, thần số học là môn khoa học mà bản thân nó là sự tổng hợp mang tính bản chất và tính dự báo tương lai. Nó bao gồm hai phần: <strong>i) phần cơ bản - tính chất tự nhiên nhất của mỗi cá nhân và ii) phần linh hoạt - có khả năng biến thiên theo sự điều chỉnh cá nhân. </strong> 
                                    Phần cơ bản bạn có thể xác định một cách dể dàng thông qua các thao tác toan học cơ bản và các tài liệu nghiên cứu liên quan.
                                    Tuy nhiên, phần linh hoạt bạn cần phải nghiên cứu chuyên sâu và làm các phân tích số học phức tạp để hiểu rõ một cách sâu sắc. Bạn có thể tìm đến các chuyên gia nghiên cứu tư vấn để giúp bạn một cách hiệu quả hơn.
                                    </p>
                                <p><strong>Những vấn đề cần lưu ý khi ứng dụng thần số học</strong></p>
                            </div>
                        </div>
                    </div>
                    <div class="row services_row">                    
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="images/icon_4.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                    </div>
                                    <div class="service_title">Tự khám phá</div>
                                    <div class="service_text">
                                        <p><strong>"Biết người biết ta, trăm trận không nguy" </strong> (theo Tôn Tử). Việc hiểu rõ bản thân sẽ giúp chúng ta điều chỉnh cuộc sống tốt đẹp hơn. Hiểu rõ đối phương giúp mối quan hệ bền vững và phát triển.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="images/icon_5.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                    </div>
                                    <div class="service_title">Mang tính bổ sung</div>
                                    <div class="service_text">
                                        <p>Khi bạn đang lạc lõng và không thể định hướng, hãy tìm đến tâm linh để có đức tin. Và thần số học giúp giải thích các vấn đề của bạn và tìm hướng đi tích cực cho mình.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="images/icon_7.svg" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                    </div>
                                    <div class="service_title">Không chủ quan</div>
                                    <div class="service_text">
                                        <p>Chủ quan là nguyên nhân dẫn đến của hầu hết các thất bại. Hãy luôn tĩnh táo trước những điểm mạnh và điểm yếu mà thần số học chỉ ra cho bạn. Hãy sử dụng chúng một cách hiệu quả.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>        
        </div>
        <div class="prices">
            <div class="container">
                <div class="row mb-5">
                    <div class="col text-center">
                        <div class="section_title_container">
                            <div class="section_subtitle">Thần số học</div>
                            <div class="section_title"><h2>Dịch vụ số học của TopWORKS</h2></div>
                            <br/>
                            <p>Thần số học cũng giống như các môn khoa học khác như kinh tế học, toán học, xã hội học ... 
                                Việc nghiên cứu lý thuyết phải dẫn đến quá trình ứng dụng vào thực tiễn đem lại lợi ích cho cá nhân và xã hội theo hướng tích cực hơn. Với mục tiêu cao cả nhất là làm cho cuộc sống ngày càng tốt đẹp hơn. 
                                Tuy nhiên, bất cứ môn khoa học nào cũng có mặt tích cực và tiêu cực của một vấn đề. Đặc biệt, thần số học là môn khoa học mà bản thân nó là sự tổng hợp mang tính bản chất và tính dự báo tương lai. Nó bao gồm hai phần: <strong>i) phần cơ bản - tính chất tự nhiên nhất của mỗi cá nhân và ii) phần linh hoạt - có khả năng biến thiên theo sự điều chỉnh cá nhân. </strong> 
                                Phần cơ bản bạn có thể xác định một cách dể dàng thông qua các thao tác toán học cơ bản và các tài liệu nghiên cứu liên quan.
                                Tuy nhiên, phần linh hoạt bạn cần phải nghiên cứu chuyên sâu và làm các phân tích số học phức tạp để hiểu rõ một cách sâu sắc. Bạn có thể tìm đến các chuyên gia nghiên cứu tư vấn để giúp bạn một cách hiệu quả hơn.</p>
                            <p><strong>Sản phẩm của chúng tôi</strong></p>
                        </div>
                    </div>
                </div>
                <div class="row">                    
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Tóm tắt số học cá nhân</div>
                            <div class="price_text">
                                <p>Các con số chính trong thần số học của bạn và phân tích chính liên quan.</p>
                            </div>
                            <div class="price_panel">Miễn phí</div>
                        </div>
                    </div>
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Báo cáo số học chi tiết</div>
                            <div class="price_text">
                                <p>Báo cáo này tiết lộ hơn 40 khí cạnh khác nhau trong biểu đồ số học của bạn.</p>
                            </div>
                            <div class="price_panel">From $3500</div>
                        </div>
                    </div>
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Dự báo số học theo năm</div>
                            <div class="price_text">
                                <p>Bao gồm chi tiết chu kỳ hàng năm trong 2 năm và 12 tháng tiếp theo.</p>
                            </div>
                            <div class="price_panel">From $1500</div>
                        </div>
                    </div>
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Báo cáo so sánh quan hệ</div>
                            <div class="price_text">
                                <p>So sánh năm con số cốt lõi của bạn với đối tác, bạn bè hoặc thành viên gia đình của bạn.</p>
                            </div>
                            <div class="price_panel">From $200</div>
                        </div>
                    </div>
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Báo cáo phát triển tài năng</div>
                            <div class="price_text">
                                <p>Tư vấn về nghề nghiệp và kinh doanh. Kiểm tra 74 đặc điểm và 39 cách xưng hô dựa trên các số được tìm thấy trong biểu đồ của bạn.</p>
                            </div>
                            <div class="price_panel">From $5500</div>
                        </div>
                    </div>
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Dự báo số học 9 năm</div>
                            <div class="price_text">
                                <p>Integer aliquet congue libero, eu gravida odio ultrces ut. Etiam ac erat ut enim maximus accumsan vel ac nisl.</p>
                            </div>
                            <div class="price_panel">From $5500</div>
                        </div>
                    </div>
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Báo cáo số học tổng hợp</div>
                            <div class="price_text">
                                <p>Kết hợp bốn bài báo cáo chuyên sâu, cá nhân nhất thành một báo cáo duy nhất để cung cấp cho bạn phạm vi thông tin rộng nhất về bạn và tương lai của bạn.</p>
                            </div>
                            <div class="price_panel">Từ 350K/giờ</div>
                        </div>
                    </div>
                    <div class="col-lg-6 price_col">
                        <div class="price">
                            <div class="price_title">Tư vấn trực tiếp</div>
                            <div class="price_text">
                                <p>Chuyên gia của chúng tôi sẽ giải đáp thắc mắc về hồ sơ số học của bạn. Chuyên gia sẽ thảo luận để hỗ trợ bạn trong việc phát huy các điểm mạnh và khắc phục điểm thiếu sót của bạn.</p>
                            </div>
                            <div class="price_panel">Từ 350K/giờ</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
export default Numerology