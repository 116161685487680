import React from 'react'
import { withTranslation } from "react-i18next"

const CallToAction = ({t}) => (
    <div className="cta">
		<div className="container">
			<div className="row">
				<div className="col">
					<div className="cta_container d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start">
						<div className="cta_content">
							<div className="cta_title">Đặt lịch tư vấn ngay!</div>
							<div className="cta_text">Nhận báo cáo phân tích chi tiết và tư vấn hữu ích cho cuộc sống và sự nghiệp của bạn</div>
						</div>
						<div className="cta_phone ml-lg-auto">+84 90 866 77 96</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)
export default withTranslation(CallToAction)