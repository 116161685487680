import React from 'react'
import CompanyListItem from './CompanyListItem'
const CompanyList = ({items}) => {
    return (
    <>
        <div className="companies-list">
        {items.map( (item, idx) => (<CompanyListItem key={idx} item={item} />))}
        </div>
    </>
)}
export default CompanyList