import React, { useState } from 'react';
const LetterList = ({items, onSelect, defaultValue}) => {
    const [value, setValue] = useState(defaultValue?defaultValue: "");
    return (
    <>
        <div className="letters-list">
            {
                items.map( (item, idx) => (
                    <a key={idx} href="" className={value === item? "current": ""} onClick={ (e) => {onSelect(e,item); setValue(item);}}>{item}</a>))
            }

        </div>
    </>
)}
export default LetterList