import {ROOT_URL} from '../config';
import {fetchJson} from '../utils/fetch';
import * as authConstants from '../constants/auth';
import format from 'string-format';

const GLOBAL_METRIC_API_V1 = {
    getLatestMetrics: "/api/global_metrics/v1/latest"
};

function getLatestMetrics() {
    return fetchJson(ROOT_URL + GLOBAL_METRIC_API_V1.getLatestMetrics, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
export default {
    getLatestMetrics
};