import React from 'react';
import { withTranslation } from "react-i18next";
import EmployeeHistoryItem from './EmployeeHistoryItem';
const EmployeeHistory = ({t, items}) => {
    return (
        <>
            <div className="boxed-list-headline">
                <h3><i class="fa fa-briefcase" aria-hidden="true"></i> {t('employment_history')}</h3>
            </div>
            <ul className="boxed-list-ul">
                {items.map( (item, idx) => (<li key={idx} ><EmployeeHistoryItem item={item} /></li>))}
            </ul>
        </>
    );
}
export default withTranslation()(EmployeeHistory)