import React, { useState } from 'react'
import { withTranslation } from "react-i18next"
import ticketService from '../services/TicketService';
import { HTTP_RESPONSE_STATUS } from '../constants/http';
import { useAlert } from 'react-alert';
import validate from 'validate.js';
const NewsLetter = ({t}) => {
	const [email, setEmail] = useState("");
	const alert = useAlert();
	const subcribe = (e) => {
		e.preventDefault();
		let err = validate.single(email, {presence: true, email: true});
		if (err) {
			alert.show(t('email_incorrect'));
			return;
		}
		ticketService.subcribe({email: email})
		.then(resObj => {
			if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
				alert.show(t('subscribe_success'));
				setEmail("");
			} else {
				alert.show(t('try_again'));
			}
		})
		.catch(err => {
			alert.show(t('try_again'));
		});
	}; 
	return (
		<div className="newsletter">
			<div className="parallax_background parallax-window" style={{backgroundImage: "url(/images/newsletter.jpg)"}} data-parallax="scroll" data-image-src="images/newsletter.jpg" data-speed="0.8"></div>
			<div className="container">
				<div className="row">
					<div className="col text-center">
						<div className="newsletter_title">{t('subscribe_newsletter')}</div>
					</div>
				</div>
				<div className="row newsletter_row">
					<div className="col-lg-8 offset-lg-2">
						<div className="newsletter_form_container">
							<form class="newsletter_form">
								<input type="email" className="newsletter_input" placeholder={t('email')} required="required" value={email} onChange={(e) => setEmail(e.target.value)}/>
								<button onClick={subcribe} className="newsletter_button">{t('subscribe')}</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
};
export default withTranslation()(NewsLetter)