import React, { useState, useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import RelatedPostItem from './RelatedPostItem';
import newsService from '../../../services/NewsService';
import {HTTP_RESPONSE_STATUS} from '../../../constants/http';
/// Independence -> call API
const RelatedPosts = ({t, id}) => {
    const [items, setItems] = useState([]);
    useEffect(()=> {
        newsService.getRelatedNews(id,2)
        .then(response=> {
            if (response.status === HTTP_RESPONSE_STATUS.OK) {
                setItems([...response.data])
            }
          }).catch(err => {
      
          });
    },[]);
    return (
        <>
            {items.length > 0 && 
            <div className="col-xl-12">
                <h3 className="margin-top-40 margin-bottom-35">{t('news_related_posts')}</h3>
            </div>
            }
            {
                items.map((item, idx) => <RelatedPostItem key={idx} item={item}/>)
            }
        </>
    )
};
export default withTranslation()(RelatedPosts)