import React, { useState } from 'react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import {NEWS_DEFAULT_FEATURE_IMAGE}  from '../../../constants';
import {
    Link
  } from "react-router-dom";
const RelatedPostItem = ({t , item}) => {
    return (
        <div className="col-xl-6">
            <Link className="blog-compact-item-container" to={"/blogs/details/" + item.id}>
                <div className="blog-compact-item">
                    <img src={item.featuredImageUrl?item.featuredImageUrl:NEWS_DEFAULT_FEATURE_IMAGE} alt={item.title} loading="lazy"/>
                    <span className="blog-item-tag">{item.tags}</span>
                    <div className="blog-compact-item-content">
                        <ul className="blog-post-tags">
                            <li>{moment(item.publishedAt).format('YYYY-MM-DD')}</li>
                        </ul>
                        <h3>{item.title}</h3>
                        <p>{item.excerpt}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
};
export default withTranslation()(RelatedPostItem)