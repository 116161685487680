import {ROOT_URL} from '../config';
import * as authConstants from '../constants/auth';
import {fetchJson} from '../utils/fetch';
const TICKET_API_V1 = {
    subscribe: "/api/tickets/v1/subcribe",
    register: "/api/tickets/v1/register",
    makeAnAppointment: "/api/tickets/v1/make_appointment",

};

export default {
    register(dto) {
        return fetchJson(ROOT_URL + TICKET_API_V1.register, {
            method: 'POST',
            authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN),
            body: JSON.stringify(dto)
          }).then(response => {
            return response;
        }).catch(error => {
            throw error;
        });
    },
    subcribe(dto) {
        return fetchJson(ROOT_URL + TICKET_API_V1.subscribe, {
            method: 'POST',
            authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN),
            body: JSON.stringify(dto)
          }).then(response => {
            return response;
        }).catch(error => {
            throw error;
        });
    },
    makeAnAppointment(dto) {
        return fetchJson(ROOT_URL + TICKET_API_V1.makeAnAppointment, {
            method: 'POST',
            authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN),
            body: JSON.stringify(dto)
          }).then(response => {
            return response;
        }).catch(error => {
            throw error;
        });
    }
};