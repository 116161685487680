import React from 'react';
import { withTranslation } from "react-i18next";
import AppointmentForm from '../appointments/AppointmentForm';
const Contact = ({t}) => (
	<>
	<div className="header_wrapper">
	</div>
    <div className="contact">
		<div class="container">
			<div class="row">
				<div class="col-lg-6">
					<AppointmentForm />
				</div>
				<div class="col-lg-5 offset-lg-1 contact_col">
					<div class="contact_content">
						<div class="contact_content_title">{t('get_touch_us')}</div>
						<div class="contact_content_text">
							<p>{t('get_touch_us_msg')}</p>
						</div>
						<div class="direct_line d-flex flex-row align-items-center justify-content-start">
							<div class="direct_line_title text-center">{t('call_now')}</div>
							<div class="direct_line_num text-center">+84 90 866 77 96</div>
						</div>
						<div class="contact_info">
							<ul>
								<li class="d-flex flex-row align-items-start justify-content-start">
									<div>{t('address')}</div>
									<div>{t('get_touch_us_address')}</div>
								</li>
								<li class="d-flex flex-row align-items-start justify-content-start">
									<div>Phone</div>
									<div>+84 90 866 77 96</div>
								</li>
								<li class="d-flex flex-row align-items-start justify-content-start">
									<div>E-mail</div>
									<div>locvuk@gmail.com</div>
								</li>
							</ul>
						</div>
						<div className="contact_social">
							<ul className="d-flex flex-row align-items-center justify-content-start">
								<li><a href="https://www.facebook.com/TopWorksVN" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
								<li><a href="https://twitter.com/TopWorksVN" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
								<li><a href="https://www.linkedin.com/in/top-works-50569320b" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="row google_map_row">
				<div className="col">
					<div className="contact_map">						
						<div className="map">
							<div id="google_map" className="google_map">
								<div className="map_container">
									<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15679.111145051773!2d106.69801200000002!3d10.751602000000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaf44ba5757e28d36!2sTopWORKS!5e0!3m2!1svi!2s!4v1618454038522!5m2!1svi!2s" style={{border: "0", height: "100%", width: "100%"}} allowfullscreen="" loading="lazy"></iframe>
								</div>
							</div>
						</div>

					</div>

				</div>
			</div>
		</div>
	</div>
	</>
)
export default withTranslation()(Contact)