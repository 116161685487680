import { withTranslation } from 'react-i18next';
import ListGroup from 'react-bootstrap/ListGroup';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useRouteMatch,
    Link
  } from "react-router-dom";
import FileContent from './components/FileContent';
const routes = [
    {
      path: "/helps/dieu-khoan-su-dung",
      exact: true,
      component: FileContent,
      url: "/Terms.md"
    },
    {
        path: "/helps/chinh-sach-bao-mat-quyen-rieng-tu",
        exact: true,
        component: FileContent,
        url: "/Privacy.md"
    },
    {
        path: "/helps/mua-hang-truc-tuyen",
        exact: true,
        component: FileContent,
        url: "/BuyOnline.md"
    },
    {
        path: "/helps/bao-hanh",
        exact: true,
        component: FileContent
    },
    {
        path: "/helps/thanh-toan",
        exact: true,
        component: FileContent,
        url: "/Payments.md"
    },
    {
        path: "/helps/van-chuyen-giao-hang",
        exact: true,
        component: FileContent
    }
  ];
function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        render={( props) => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} url={route.url} routes={route.routes} />
        )}
      />
    );
}
const Helps = ({t}) => {
    return (
        <>
            <div className="header_wrapper"></div>
            <div className="clearfix"></div>
            <div id="titlebar" className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <h2>{t('helps')}</h2>

                            <nav id="breadcrumbs" className="dark">
                                <ul>
                                    <li><a href="/">{t('home')}</a></li>
                                    <li>{t('helps')}</li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
             <Router>
            <div className="user_account mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <ListGroup variant="flush">
                                <ListGroup.Item className="pl-0"><Link to={"/helps/dieu-khoan-su-dung"}><i className="fa fa-file-text-o mr-2"></i>{t('terms_conditions')}</Link></ListGroup.Item>
                                <ListGroup.Item className="pl-0"><Link to="/helps/chinh-sach-bao-mat-quyen-rieng-tu"><i className="fa fa-user-secret mr-2"></i>{t('privacy')}</Link></ListGroup.Item>
                                <ListGroup.Item className="pl-0"><Link to="/helps/mua-hang-truc-tuyen"><i className="fa fa-cart-plus mr-2"></i>{t('helps_buy_online')}</Link></ListGroup.Item>
                                <ListGroup.Item className="pl-0"><Link to="/helps/thanh-toan"><i className="fa fa-credit-card mr-2"></i>{t('helps_payments')}</Link></ListGroup.Item>
                            </ListGroup>                            
                        </div>
                        <div className="col-lg-9">
                            <Switch>
                                {/*<Route path={"/helps/dieu-khoan-su-dung"} exact>
                                    <FileContent url="/Terms.md"/>
                                </Route>
                                <Route path={"/helps/chinh-sach-bao-mat-quyen-rieng-tu"} exact>
                                    <FileContent url="/Privacy.md"/>
    </Route>*/}
                                {routes.map((route, index) => (
                                    // Render more <Route>s with the same paths as
                                    // above, but different components this time.
                                    <RouteWithSubRoutes key={index} {...route} url={route.url}/>
                                ))}
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
            </Router>
        </>
    );
};
export default withTranslation() (Helps);