import React, { useEffect, useState } from 'react';
import {withTranslation} from 'react-i18next';
import {Helmet} from "react-helmet";
import newsService from '../../services/NewsService';
import {HTTP_RESPONSE_STATUS} from '../../constants/http';
import {useLocation, useHistory } from 'react-router-dom';
import CommentAdd from './components/CommentAdd';
import Comments from './components/Comments';
import moment from 'moment';
import queryString from 'query-string';
import {NEWS_DEFAULT_FEATURE_IMAGE}  from '../../constants'
import TrendingPosts from './components/TrendingPosts';
import RelatedPosts from './components/RelatedPosts';

const BlogDetails = ({t,match}) => {
    const history = useHistory();
    const location = useLocation();
    const [details, setDetails] = useState({});
    const [preview, setPreview] = useState(false);
    const [nextPost, setNextPost] = useState(-1);
    const [nextDetails, setNextDetails] = useState({});
    const [prevPost, setPrevPost] = useState(-1);
    const [prevDetails, setPrevDetails] = useState({});
    const [searchParams, setSearchParams] = useState({});

    const ids = function() {
        const queryValues = queryString.parse(location.search);
        if (queryValues.ids) {
            const ids = queryValues.ids.split(',');
            return ids;
        }
        return [];
    }();
    useEffect(() => {
        if (match.path.includes("preview")) {
            setPreview(true);
            newsService.previewNews(match.params.id)
            .then(response=> {
                if (response.status === HTTP_RESPONSE_STATUS.OK) {
                    setDetails(response.data);
                } else {
                    history.push('/404')
                }
            }).catch(err => {

            });
        } else {
            if (match.params.id) {
                
                /*newsService.getNews(match.params.id)
                .then(response=> {
                    if (response.status === HTTP_RESPONSE_STATUS.OK) {
                        setDetails(response.data);
                    } else {
                        history.push('/404')
                    }
                }).catch(err => {
    
                });*/
                loadNews(match.params.id);
            }
        }
    }, []);
    const loadNextDetails = (id) => {
        newsService.getNewsBasicInfo(id)
        .then(response=> {
            if (response.status === HTTP_RESPONSE_STATUS.OK) {
                setNextDetails(response.data);
            } else {
            }
        }).catch(err => {
        });
    };
    const loadPrevDetails = (id) => {
        newsService.getNewsBasicInfo(id)
        .then(response=> {
            if (response.status === HTTP_RESPONSE_STATUS.OK) {
                setPrevDetails(response.data);
            } else {
            }
        }).catch(err => {
        });
    };
    const loadNews = (id) => {
        newsService.getNews(id)
        .then(response=> {
            if (response.status === HTTP_RESPONSE_STATUS.OK) {
                setDetails(response.data);
                /// Calc next Post
                let idx = ids.findIndex((element) => element === id);
                if (idx !== undefined) {
                    let prevIdx = idx - 1 >= 0 ? ids[idx - 1]: -1;
                    ///Load Next Post
                    if (prevIdx>0) {
                        loadPrevDetails(prevIdx);
                        setPrevPost(prevIdx);
                    }
                    let nextIdx = idx + 1 <ids.length? ids[idx + 1]: -1;
                    ///
                    if (nextIdx > 0) {
                        loadNextDetails(nextIdx);
                        setNextPost(nextIdx);
                    }

                }
            } else {
                history.push('/404')
            }
        }).catch(err => {

        });
    }
    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            console.log('do validate')
            let query = queryString.stringify({...searchParams, size: 5},
                {
                    skipEmptyString: true,
                    skipNull: true
                });
            history.push("/blogs?" + query);
        }
    };
    const handleChangeFields = (e) => {
        searchParams[e.target.name] = e.target.value;
        setSearchParams({...searchParams});
    };
    return (
        <>
            <Helmet>
                
                <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://topworks.vn/blogs/${details.id}"
                        },
                        "headline": "${details.title}",
                        "image": "${details.featuredImageUrl?details.featuredImageUrl: NEWS_DEFAULT_FEATURE_IMAGE}",
                        "datePublished": "${details.publishedAt}",
                        "dateModified":"${details.publishedAt}",
                        "publisher": {
                            "@type": "Organization",
                            "name": "TopWorks",
                            "logo": {
                              "@type": "ImageObject",
                              "url": "https://topworks.vn/logo.jpg"
                            }
                        }
                    }
                `}
                </script>
                <title>{details.title}</title>
                <meta name="keywords" content={details.tags}/>
                <meta name="description" content={details.excerpt}/>
                <meta name="content" content={details.excerpt}/>
                <link rel="canonical" href="https://topworks.vn" />
            </Helmet>
            <div className="header_wrapper"></div>
            <div className="clearfix"></div>
            <div id="titlebar" className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>{t('news')}</h2>
                            <nav id="breadcrumbs" className="dark">
                                <ul>
                                    <li><a href="/">{t('home')}</a></li>
                                    <li><a href="/blogs">{t('news')}</a></li>
                                    <li>{details.title}</li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    
                    <div className="col-xl-8 col-lg-8">
                        <div className="blog-post single-post">

                            <div className="blog-post-thumbnail">
                                <div className="blog-post-thumbnail-inner">
                                    <span className="blog-item-tag">{details.tags? details.tags: ""}</span>
                                    <img src={details.featuredImageUrl?details.featuredImageUrl: NEWS_DEFAULT_FEATURE_IMAGE} alt={details.title?details.title:""} loading="lazy"/>
                                </div>
                            </div>

                            <div className="blog-post-content">
                                <h1 className="margin-bottom-10"><b>{details.title?details.title:""}</b></h1>

                                <div className="blog-post-info-list margin-bottom-20">
                                    <a href="#" class="blog-post-info">{moment(details.publishedAt).format('YYYY-MM-DD, h:mm:ss a')}</a>
                                    {details.numOfComments && <a href="#" class="blog-post-info">{details.numOfComments? details.numOfComments: 0}</a>}
                                </div>

                                <div dangerouslySetInnerHTML={{__html: details.content?details.content:""}} />
                                {/*
                                <div class="share-buttons margin-top-25">
                                    <div class="share-buttons-trigger"><i class="fa fa-share-alt" aria-hidden="true"></i></div>
                                    <div class="share-buttons-content">
                                        <span>{t('share_interesting')} <strong>{t('share_it')}</strong></span>
                                        <ul class="share-buttons-icons">
                                            <li><a href="#" data-button-color="#3b5998" data-tippy-placement="top" data-tippy="" data-original-title="Share on Facebook" style={{backgroundColor: "rgb(59, 89, 152)"}}><i class="fa fa-facebook-square" aria-hidden="true"></i></a></li>
                                            <li><a href="#" data-button-color="#1da1f2" data-tippy-placement="top" data-tippy="" data-original-title="Share on Twitter" style={{backgroundColor: "rgb(29, 161, 242)"}}><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                            <li><a href="#" data-button-color="#0077b5" data-tippy-placement="top" data-tippy="" data-original-title="Share on LinkedIn" style={{backgroundColor: "rgb(0, 119, 181)"}}><i class="fa fa-linkedin-square" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>*/}
                            </div>
                            
                        </div>	
                        { (prevPost >=0 || nextPost >=0) &&
                        <><ul id="posts-nav" className="margin-top-0 margin-bottom-60">
                        
                            { nextPost >=0 && <li className="next-post pl-1">
                                <a href={"/blogs/details/" + nextPost +"?ids=" + ids.toString()}>
                                    <span>{t('news_next_post')}</span>
                                    <strong>{nextDetails.title}</strong>
                                </a>
                            </li>
                            }
                            { prevPost >=0 && <li className="prev-post pr-1">
                                <a href={"/blogs/details/" + prevPost +"?ids=" + ids.toString()}>
                                    <span>{t('news_previous_post')}</span>
                                    <strong>{prevDetails.title}</strong>
                                </a>
                            </li>
                            }
                        </ul>
                        <div className="clearfix"/>
                        </>
                        }
                        { preview && <Comments allowComment={details.allowComment} />}
                        { preview && <CommentAdd allowComment={details.allowComment} />}
                        <div className="row">
                            
                            
                            <RelatedPosts id = {match.params.id}/>
                            {/*<div class="col-xl-6">
                                <a href="pages-blog-post.html" class="blog-compact-item-container">
                                    <div class="blog-compact-item">
                                        <img src="images/blog-02a.jpg" alt=""/>
                                        <span class="blog-item-tag">Recruiting</span>
                                        <div class="blog-compact-item-content">
                                            <ul class="blog-post-tags">
                                                <li>29 June 2019</li>
                                            </ul>
                                            <h3>How to "Woo" a Recruiter and Land Your Dream Job</h3>
                                            <p>Appropriately empower dynamic leadership skills after business portals. Globally myocardinate interactive.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-xl-6">
                                <a href="pages-blog-post.html" class="blog-compact-item-container">
                                    <div class="blog-compact-item">
                                        <img src="images/blog-03a.jpg" alt=""/>
                                        <span class="blog-item-tag">Marketing</span>
                                        <div class="blog-compact-item-content">
                                            <ul class="blog-post-tags">
                                                <li>10 June 2019</li>
                                            </ul>
                                            <h3>11 Tips to Help You Get New Clients Through Cold Calling</h3>
                                            <p>Compellingly embrace empowered e-business after user friendly intellectual capital. Interactively front-end.</p>
                                        </div>
                                    </div>
                                </a>
                    </div>*/}
                        </div>
                        
                    </div>

                    <div className="col-xl-4 col-lg-4 content-left-offset">
                        <div className="sidebar-container">
                            <div className="sidebar-widget margin-bottom-40">
                                <div className="input-with-icon">
                                    <input id="autocomplete-input" name="q" className="input-search" type="text" placeholder={t('placeholder_search')} onKeyDown={handleSearch} onChange={handleChangeFields}/>
                                    <i className="fa fa-search" aria-hidden="true" onClick></i>
                                </div>
                            </div>

                            <TrendingPosts exclusiveId={match.params.id}/>


                            

                            <div class="sidebar-widget">
                                <h3>Tags</h3>
                                <div class="task-tags">
                                    <a href="#"><span>employer</span></a>
                                    <a href="#"><span>recruiting</span></a>
                                    <a href="#"><span>work</span></a>
                                    <a href="#"><span>salary</span></a>
                                    <a href="#"><span>tips</span></a>
                                    <a href="#"><span>income</span></a>
                                    <a href="#"><span>application</span></a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};
export default withTranslation()(BlogDetails)