import React, { useState, useEffect } from 'react';
const Pagination = ({activePage, limit, pages, onPageChanged}) => {
    
    /*const maxPrevItems = function () {
        return Math.floor((limit - 1) / 2);
    }();
    const maxNextItems = function () {
        return Math.ceil((limit - 1) / 2);
    }();
    const range = function () {
        return activePage + maxNextItems;
    }();
    const beforeDots = function () {
        return false && activePage > maxPrevItems + 1;
      }();
    
    const afterDots = function () {
        return false && activePage < pages - maxNextItems;
      }();
    const computedLimit = function () {
        return limit - afterDots - beforeDots;
    }();
    const lastItem = function () {
        return range >= pages ? pages : range - afterDots;
      }();
    const itemsAmount = function () {
        return pages < computedLimit ? pages : computedLimit;
    }();
    */
    /*var items = function () {
    if (activePage - maxPrevItems <= 1) {
      return Array.from({
        length: itemsAmount
      }, function (v, i) {
        return { idx: i + 1, type: "normal"};
      });
    } else {
      return Array.from({
        length: itemsAmount
      }, function (v, i) {
        return { idx: lastItem - 1, type: "normal"};
      }).reverse();
    }
  }();*/
    const items = function() {
        if (pages > 1) {
            const startPage = activePage > limit ? activePage - limit : 1
    	    const endPage = activePage + limit > pages ? pages : activePage + limit
            let items = [];
            /// Add prev
            if (activePage > 1) {
                items.push({
                    idx: activePage - 1,
                    type: "prev"
                });
            }
            for (let iPage = startPage; iPage <= endPage ;iPage++) {

                    items.push({
                        type: "normal",
                        idx: iPage
                    });
            }
            /*if (activePage - maxPrevItems <= 1) {
                for (let i = 0; i < itemsAmount; i++) {
                    items.push({
                        idx: i + 1,
                        type: "normal"
                    });
                }
            }*/
            /*for (let i = 0; i < limit; i++) {
                if (activePage + i < pages ) {
                    items.push({
                        type: "normal",
                        idx: activePage + i
                    });
                }
            }*/
            /// Add next
            if (activePage < pages) {
                items.push({
                    idx: activePage + 1,
                    type: "next"
                });
            }
            return items;
        }
        return [];
    }();
    return (
        <nav className="pagination">
            <ul>
                {
                    items.map((item, index) => {
                        if (item.type === "prev") {
                            return (
                                <li key={index} className="pagination-arrow"><a onClick={(e) => onPageChanged(e, item.idx)} class="ripple-effect"><i className="fa fa-arrow-left"></i></a></li>
                            );
                        }
                        if (item.type === "next") {
                            return (
                                <li key={index} className="pagination-arrow"><a onClick={(e) => onPageChanged(e, item.idx)} class="ripple-effect"><i className="fa fa-arrow-right"></i></a></li>
                            );
                        }
                        return (
                            <li key={index} >
                              <a
                                className={activePage=== item.idx?"current-page ripple-effect":"ripple-effect"}
                                onClick={(e) => onPageChanged(e, item.idx)}
                              >
                                {item.idx}
                              </a>
                            </li>);
                    })
                }
            </ul>
        </nav>
    );
};
export default Pagination
/**
 * <li className="pagination-arrow"><a href="#" class="ripple-effect"><i className="fa fa-arrow-left"></i></a></li>
                <li><a href="#" class="current-page ripple-effect">1</a></li>
                <li><a href="#" class="ripple-effect">2</a></li>
                <li><a href="#" class="ripple-effect">3</a></li>
                <li className="pagination-arrow"><a href="#" class="ripple-effect"><i className="fa fa-arrow-right"></i></a></li>
 */