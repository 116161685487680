import React, { useState } from 'react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import {NEWS_DEFAULT_FEATURE_IMAGE}  from '../../../constants';

import {
    Link
  } from "react-router-dom";
const BlogListItem = ({t , item, ids}) => {
    return (
        <>
            <Link className="blog-post" to={"/blogs/details/" + item.id +"?ids=" + ids.toString()}>
                <div className="blog-post-thumbnail">
                    <div className="blog-post-thumbnail-inner">
                        {/*<span className="blog-item-tag"></span>*/}
                        <img src={item.featuredImageUrl?item.featuredImageUrl:NEWS_DEFAULT_FEATURE_IMAGE} alt={item.title} loading="lazy"/>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="blog-post-date">{moment(item.publishedAt).format('YYYY-MM-DD, h:mm:ss a')}</span>
                    <h3>{item.title}</h3>
                    <p>{item.excerpt}</p>
                </div>
                <div class="entry-icon"></div>
            </Link>
            {/*<a href="pages-blog-post.html" className="blog-post">
                
            </a>*/}
        </>
    )
};
export default withTranslation()(BlogListItem)