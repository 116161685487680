import {HTTP_HEADER} from '../constants/http';
import {ROOT_URL} from '../config';
import * as authConstants from '../constants/auth';
import {fetchJson} from '../utils/fetch';
import format from 'string-format';
const PROMOTIONS_API_V1 = {
    search: "/api/promotions/v1/search",
    searchAndCount: "/api/promotions/v1/search_and_count",
    getDetails: "/api/promotions/v1/{0}",
    previewPromotion: "/api/promotions/v1/{0}/preview",
    getPromotionBanner: "/api/promotions/v1/{0}/banner",
    getLatest: "/api/promotions/v1/latest"
};

function searchAndCount(params = {}) {
    let url = ROOT_URL + PROMOTIONS_API_V1.searchAndCount + "?"+ new URLSearchParams(params).toString();
    return fetchJson(url, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function search(params = {}) {
    let url = ROOT_URL + PROMOTIONS_API_V1.search + "?"+ new URLSearchParams(params).toString();
    return fetchJson(url, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getDetails(id) {
    return fetchJson(ROOT_URL + format(PROMOTIONS_API_V1.getDetails,id), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function previewPromotion(id) {
    return fetchJson(ROOT_URL + format(PROMOTIONS_API_V1.previewPromotion,id), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getPromotionBanner(id) {
    return fetchJson(ROOT_URL + format(PROMOTIONS_API_V1.getPromotionBanner,id), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getLatestPromotions(limit = 6) {
    return fetchJson(ROOT_URL + PROMOTIONS_API_V1.getLatest + "?limit" + limit, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
export default {
    searchAndCount,
    search,
    getDetails,
    previewPromotion,
    getPromotionBanner,
    getLatestPromotions
};