import React, { useState, useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import Pagination from '../../components/Pagination';
import PromotionList from './components/PromotionList';
import promotionService from '../../services/PromotionService';
import {HTTP_RESPONSE_STATUS} from '../../constants/http';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

const Promotions = ({t}) => {
    const history = useHistory();
    const search = queryString.parse(useLocation().search);
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(search.page?search.page:1);
    const [searchParams, setSearchParams] = useState({...search});
    const [pageSize, setPageSize] = useState(search.size?search.size:5);
    const [isLoading, setIsLoading] = useState(false);
    const loadPromotions = () => {
        setIsLoading(true);
        promotionService.searchAndCount({...searchParams, page: currentPage, size: pageSize})
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setItems(resObj.data.result);
                    setCurrentPage(resObj.data.page);
                    setTotalPages(Math.ceil(resObj.data.total/pageSize));
                } else {
                    setItems([]);
                    setCurrentPage(1);
                    setTotalPages(0);
                }
            }
        })
        .catch(err => {
            setItems([]);
            setCurrentPage(1);
            setTotalPages(0);
            setIsLoading(false);
        });
    };
    useEffect(() => {
		loadPromotions();
	}, []);
    const onPageChanged = (e, page) => {
        e.preventDefault();
        //setCurrentPage(page);
    };
    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            setCurrentPage(1);
            let query = queryString.stringify({...searchParams, size: pageSize},
                {
                    skipEmptyString: true,
                    skipNull: true
                });
            history.push("/promotions?" + query);
            loadPromotions();
        }
    };
    const handleChangeFields = (e) => {
        searchParams[e.target.name] = e.target.value;
        setSearchParams({...searchParams});
    };
    return (
    <>
        <div className="header_wrapper"></div>
        <div className="clearfix"></div>
        <div id="titlebar" className="gradient">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{t('promotions')}</h2>
                        <nav id="breadcrumbs" className="dark">
                            <ul>
                                <li><a href="/">{t('home')}</a></li>
                                <li>{t('promotions')}</li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
        <div className="section gray">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-8">

                        <div className="section-headline margin-top-60 margin-bottom-35">
                            <h4>{t('recent_posts')}</h4>
                        </div>
                        {
                            <PromotionList items={items}/>
                        }

                        <div className="clearfix"></div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pagination-container margin-top-10 margin-bottom-20">
                                    <Pagination limit={2} onPageChanged={onPageChanged} activePage={currentPage} pages={totalPages}/>
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className="col-xl-4 col-lg-4 content-left-offset">
                        <div className="sidebar-container margin-top-65">
                            
                            <div className="sidebar-widget margin-bottom-40">
                                <div className="input-with-icon">
                                    <input value={searchParams.q} name="q" className="input-search" type="text" placeholder={t('placeholder_search')} onKeyDown={handleSearch} onChange={handleChangeFields}/>
                                    <i className="fa fa-search" aria-hidden="true" onClick></i>
                                </div>
                            </div>
                            {/*<TrendingPosts/>*/}
                        </div>
                    </div>

                </div>
            </div>
        </div>
     </>
    )
}
export default withTranslation()(Promotions);