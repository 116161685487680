import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import CallToAction from '../../containers/CallToAction';
import numerologyService from '../../services/NumerologyService';
import { HTTP_RESPONSE_STATUS } from '../../constants/http';
import { useHistory } from 'react-router-dom';
import * as utils from '../../utils'
import NumberBox from './components/NumberBox';
import moment from 'moment';
import AppointmentForm from '../appointments/AppointmentForm';
import {NUMEROLOGY_IMAGES, NUMEROLOGY_IMAGE_DEFAULT} from '../../constants';
const ProfileSummary = ({t, match}) => {
    const [details, setDetails] = useState({});
    const [profileImage, setProfileImage] = useState(NUMEROLOGY_IMAGE_DEFAULT);
    const history = useHistory();
    useEffect(() => {
        numerologyService.getDetails(match.params.uuid)
        .then(resObj => {
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                setDetails({...resObj.data});
                let srcImage = NUMEROLOGY_IMAGES[resObj.data.lifePath.number]?NUMEROLOGY_IMAGES[resObj.data.lifePath.number]: NUMEROLOGY_IMAGE_DEFAULT;
                setProfileImage(srcImage);
            } else if (resObj.status === HTTP_RESPONSE_STATUS.ZERO_RESULTS) {
                history.push('/404');
            }
        })
        .catch(err => {

        });
    },[]);
    return(
    <>
        <div className="why header_wrapper">
            <div className="container">
                <div className="row row-eq-height">
                    <div className="col-lg-6 order-lg-1 order-2">
                        <div className="why_image_container">
                        
                            <div className="why_image"><img src={profileImage} alt=""/></div>
                            {/*<div className="why_image"><img src="/images/why_1.jpg" alt=""/></div>*/}
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-2 order-1">
                        <div className="why_content">
                            <div className="section_title_container">
                                <div className="section_subtitle">{t('numerology')}</div>
                                <div className="section_title"><h2>{t('overview_about_you')}</h2></div>
                            </div>
                            <div className="why_text">
                                <p>{details.summary}</p>
                            </div>
                            <div className="why_list">
                                <ul>
                                    <li className="d-flex flex-row align-items-center justify-content-start">
                                        <div className="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div className="icon"><img src="/images/user.svg" title="Nhóm người" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div className="why_list_content">
                                            <div className="why_list_title">{utils.formatName(details.lastName, details.middleName, details.firstName, "vi")}</div>
                                        </div>
                                    </li>
                                    <li className="d-flex flex-row align-items-center justify-content-start">
                                        <div className="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div className="icon"><img src="/images/birthday-cake.svg" title="Nhóm người" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div className="why_list_content">
                                            <div className="why_list_title">{moment(details.birthday).format("MM/DD/YYYY")}</div>
                                        </div>
                                    </li>
                                    <li className="d-flex flex-row align-items-center justify-content-start">
                                        <div className="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div className="icon"><img src="/images/brain.svg" title="Nhóm người" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div className="why_list_content">
                                            <div className="why_list_title">{details.group}</div>
                                            <div className="why_list_text"></div>
                                        </div>
                                    </li>
                                    <li className="d-flex flex-row align-items-center justify-content-start">
                                        <div className="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div className="icon"><img src="/images/road.svg" title="Số đường đời" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div className="why_list_content">
                                            <div className="why_list_title">{details.name}</div>
                                            <div className="why_list_text"></div>
                                        </div>
                                    </li>
                                    {/*
                                    <li className="d-flex flex-row align-items-center justify-content-start">
                                        <div className="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div className="icon"><img src="/images/darts.svg" title="Số tên khai sinh" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div className="why_list_content">
                                            <div className="why_list_title">Không kỳ vọng</div>
                                            <div className="why_list_text">Etiam ac erat ut enim maximus accumsan vel</div>
                                        </div>
                                    </li>
                                    <li className="d-flex flex-row align-items-center justify-content-start">
                                        <div className="icon_container d-flex flex-column align-items-center justify-content-center">
                                            <div className="icon"><img src="/images/view.svg" title="Số ngày sinh" alt="https://www.flaticon.com/authors/prosymbols"/></div>
                                        </div>
                                        <div className="why_list_content">
                                            <div className="why_list_title">Không chủ quan</div>
                                            <div className="why_list_text">Etiam ac erat ut enim maximus accumsan vel</div>
                                        </div>
                                    </li>
                                    */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CallToAction />
        <div className="services">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <div className="section_title_container">
                            <div className="section_subtitle">Thần số học</div>
                            <div className="section_title"><h2>6 con số chủ đạo của bạn</h2></div>
                        </div>
                    </div>
                </div>
                <div className="row services_row">                    
                    <div className="col-xl-4 col-md-6 service_col">
                        <NumberBox number={details.lifePath?details.lifePath.number:null} 
                            title={'Đường đời'}
                            description={"Mô tả hành trình cuộc đời của bạn, là những gì bạn đã, đang và sẽ đi qua. Cuộc đời là trường học, và con số Đường đời giúp chúng ta nhìn nhận rõ hơn các bài học trong cuộc đời mình."}
                        />
                    </div>
                    <div className="col-xl-4 col-md-6 service_col">
                        <NumberBox number={details.expression?details.expression.number:null} 
                            title={'Tên khai sinh'}
                            description={"Tên khai sinh mang nguồn năng lượng rung động được đến từ bên ngoài và cả cảm nhận từ chính bạn đối với họ tên của mình. Nó giúp bạn trả lời được câu hỏi sứ mệnh cuộc đời của bạn là gì?"}
                        />
                    </div>
                    <div className="col-xl-4 col-md-6 service_col">
                        <NumberBox number={details.attitude?details.attitude.number:null} 
                            title={'Thái độ'}
                            tips={details.attitude?details.attitude.summary:null}
                            description={"Thể hiện phản ứng của con người đối với chính mình, với người khác và với thế giới. Thái độ được biểu hiện ra bên ngoài thông qua lời nói và ngôn ngữ cơ thể."}
                        />
                    </div>
                    <div className="col-xl-4 col-md-6 service_col">
                        <NumberBox number={details.soulUrge?details.soulUrge.number:null} title={"Nội tâm"}
                            tips={details.soulUrge?details.soulUrge.summary:null}
                            description={"Con số đại diện cho những mong muốn, khát khao một cách tự nhiên và bản năng nhất của bạn."}
                        />
                        
                    </div>
                    <div class="col-xl-4 col-md-6 service_col">
                        <NumberBox number={details.personality?details.personality.number:null} title={"Nhân cách"} tips={details.personality?details.personality.summary:null}
                            description={"Thể hiện “con người bên ngoài” của bạn, cách bạn ứng xử, đối đãi với người khác và đối với thế giới."}
                        />
                    </div>
                    <div class="col-xl-4 col-md-6 service_col">
                        <NumberBox number={details.dayOfBirth?details.dayOfBirth.number:null} 
                            title={'Ngày sinh'}
                            description={"Cho thấy cách bạn quan sát, cảm nhận thế giới và những ấn tượng của người khác về bạn."}
                        />
				</div>

			</div>
		</div>        
	</div>
    <div className="contact">
		<div className="container">
			<div className="row">
				<div className="col-lg-6">
					<AppointmentForm />
				</div>
				<div className="col-lg-5 offset-lg-1 contact_col">
					<div className="contact_content">
						<div className="contact_content_title">Buy Me A Coffee</div>
						<div className="contact_content_text">
							<p>{t('buy_coffee_msg')}</p>
						</div>
						<div className="direct_line d-flex flex-row align-items-center justify-content-start">
							<div className="direct_line_title text-center">Momo</div>
							<div className="direct_line_num text-center">0933242572</div>
						</div>
                        <div className="direct_line d-flex flex-row align-items-center justify-content-start">
							<div className="direct_line_title text-center" alt="https://www.blockchain.com/btc/address/3G6SV8ovtgUvFsrYer5tbqxj7qvKEA3PQF">Bitcoin</div>
                            {/*                        https://www.blockchain.com/btc/address/3G6SV8ovtgUvFsrYer5tbqxj7qvKEA3PQF
*/}
							<div className="direct_line_num text-center" style={{fontSize: "14px"}}><a style={{color:"white"}} href="https://www.blockchain.com/btc/address/3G6SV8ovtgUvFsrYer5tbqxj7qvKEA3PQF" target="_blank">3G6SV8ovtgUvFsrYer5tbqxj7qvKEA3PQF</a></div>
						</div>
                        <div className="direct_line d-flex flex-row align-items-center justify-content-start">
							<div className="direct_line_title text-center" alt="https://ethplorer.io/address/0xf0be5bb86c88b60da4ae132004c42698200f78a7">ETH</div>
							<div className="direct_line_num text-center" style={{fontSize: "12px"}}><a style={{color:"white"}} href="https://ethplorer.io/address/0xf0be5bb86c88b60da4ae132004c42698200f78a7" target="_blank">0xf0be5bb86c88b60da4ae132004c42698200f78a7</a></div>
						</div>
                        <div className="direct_line d-flex flex-row align-items-center justify-content-start">
							<div className="direct_line_title text-center">XRP</div>
							<div className="direct_line_num text-center" style={{fontSize: "14px"}}>rNFugeoj3ZN8Wv6xhuLegUBBPXKCyWLRkB</div>
						</div>
                        <div className="direct_line d-flex flex-row align-items-center justify-content-start">
							<div className="direct_line_title text-center">TRX</div>
							<div className="direct_line_num text-center" style={{fontSize: "14px"}}>TS2wHi1kM73f36nfYFd8Z7pj2sRRaYDA9X</div>
						</div>

                        {/*<div class="direct_line d-flex flex-row align-items-center justify-content-start">
							<div class="direct_line_title text-center">ZaloPay</div>
							<div class="direct_line_num text-center">0982427172</div>
						</div>
                        <div class="direct_line d-flex flex-row align-items-center justify-content-start">
							<div class="direct_line_title text-center">VCB BANK</div>
							<div class="direct_line_num text-center">0982427172</div>
						</div>
                        <div class="direct_line d-flex flex-row align-items-center justify-content-start">
							<div class="direct_line_title text-center">Bitcoin</div>
							<div class="direct_line_num text-center">0982427172</div>
						</div>
						<div class="direct_line d-flex flex-row align-items-center justify-content-start">
							<div class="direct_line_title text-center">Ethereum</div>
							<div class="direct_line_num text-center">0982427172</div>
						</div>
                        <div class="direct_line d-flex flex-row align-items-center justify-content-start">
							<div class="direct_line_title text-center">XRP</div>
							<div class="direct_line_num text-center">0982427172</div>
                                </div>*/}
					</div>
				</div>
			</div>
		</div>
	</div>
    </>
    )
};
export default withTranslation()(ProfileSummary)

////https://tracuuthansohoc.com/xem-online/?view=c89e8a368b39f5dbaa7b5b24c4bcaddc6257770