import {HTTP_HEADER} from '../constants/http';
import {ROOT_URL} from '../config';
import * as authConstants from '../constants/auth';
import {fetchJson} from '../utils/fetch';
import format from 'string-format';
const NEWS_API_V1 = {
    getCategories: "/api/news/v1/categories",
    createCategory: "/api/news/v1/categories",
    search: "/api/news/v1/search",
    search_and_count: "/api/news/v1/search_and_count",
    getNews: "/api/news/v1/{0}", 
    getNewsBasicInfo: "/api/news/v1/{0}/basic_info", 
    getRelatedNews: "/api/news/v1/{0}/related_news", 
    previewNews: "/api/news/v1/{0}/preview",
    getFeaturedNews: "/api/news/v1/featured",
    getTrendingNews: "/api/news/v1/trending"
};
function getNews(id) {
    let headers = {
        "Content-Type": "application/json; charset=utf-8",
    };
    headers[HTTP_HEADER.CLIENT_FINGERPRINT] = localStorage.getItem(HTTP_HEADER.CLIENT_FINGERPRINT);
    headers[HTTP_HEADER.CLIENT_ID] = localStorage.getItem(HTTP_HEADER.CLIENT_ID);
    headers[HTTP_HEADER.AUTHORIZATION] = "Bearer " + localStorage.getItem("X-Auth-Token");
    return fetch(ROOT_URL + format(NEWS_API_V1.getNews,id), {
        method: 'GET',
        headers
    }).then(response => {
        if(response.status < 200 ||  response.status>=300) {
            throw response;
        }
        return response.json();
    }).catch(error => {
        throw error;
    });
}
function getNewsBasicInfo(id) {
    let headers = {
        "Content-Type": "application/json; charset=utf-8",
    };
    headers[HTTP_HEADER.CLIENT_FINGERPRINT] = localStorage.getItem(HTTP_HEADER.CLIENT_FINGERPRINT);
    headers[HTTP_HEADER.CLIENT_ID] = localStorage.getItem(HTTP_HEADER.CLIENT_ID);
    headers[HTTP_HEADER.AUTHORIZATION] = "Bearer " + localStorage.getItem("X-Auth-Token");
    return fetch(ROOT_URL + format(NEWS_API_V1.getNewsBasicInfo,id), {
        method: 'GET',
        headers
    }).then(response => {
        if(response.status < 200 ||  response.status>=300) {
            throw response;
        }
        return response.json();
    }).catch(error => {
        throw error;
    });
}
function previewNews(id) {
    let headers = {
        "Content-Type": "application/json; charset=utf-8",
    };
    headers[HTTP_HEADER.CLIENT_FINGERPRINT] = localStorage.getItem(HTTP_HEADER.CLIENT_FINGERPRINT);
    headers[HTTP_HEADER.CLIENT_ID] = localStorage.getItem(HTTP_HEADER.CLIENT_ID);
    headers[HTTP_HEADER.AUTHORIZATION] = "Bearer " + localStorage.getItem("X-Auth-Token");
    return fetch(ROOT_URL + format(NEWS_API_V1.previewNews,id), {
        method: 'GET',
        headers
    }).then(response => {
        if(response.status < 200 ||  response.status>=300) {
            throw response;
        }
        return response.json();
    }).catch(error => {
        throw error;
    });
}
function searchAndCount(params = {}) {
    let url = ROOT_URL + NEWS_API_V1.search_and_count + "?"+ new URLSearchParams(params).toString();
    return fetchJson(url, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function search(params = {}) {
    let url = ROOT_URL + NEWS_API_V1.search + "?"+ new URLSearchParams(params).toString();
    /*if (query) {
        url = url + "?" + query;
    }*/
    return fetchJson(url, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getCategories() {
    return fetchJson(ROOT_URL + NEWS_API_V1.getCategories, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};
function createCategory(name, parentId = null) {
    return fetchJson(ROOT_URL + NEWS_API_V1.createCategory, {
        method: 'POST',
        body: JSON.stringify({name:name, parentId: parentId}),
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};
function getFeaturedNews(limit = 3) {
    let query = "?limit=" + (limit?limit:3);
    return fetchJson(ROOT_URL + NEWS_API_V1.getFeaturedNews + query, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};
function getTrendingNews(limit = 3) {
    let query = "?limit=" + (limit?limit:3);
    return fetchJson(ROOT_URL + NEWS_API_V1.getTrendingNews + query, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};
function getRelatedNews(id, limit = 3) {
    let url = ROOT_URL + format(NEWS_API_V1.getRelatedNews,id)+ "?limit=" + (limit?limit:3);
    return fetchJson(url, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};
export default {
    getCategories,
    createCategory,
    searchAndCount,
    search,
    getNews,
    getNewsBasicInfo,
    previewNews,
    getTrendingNews,
    getFeaturedNews,
    getRelatedNews
};