import React, { useEffect, useState } from 'react';
import {withTranslation} from 'react-i18next';
import StarRatingComponent from 'react-star-rating-component';
import companyService from "../../../services/CompanyService";
import * as constants from "../../../constants";
import {HTTP_RESPONSE_STATUS} from '../../../constants/http';
const CompanyListItem = ({t, item}) => {
    const [photoUrl, setPhotoUrl] = useState(constants.COMPANY_LOGO_PLACEHOLDER);
    useEffect( () => {
        companyService.getLogo(item.id)
        .then(resObj => {
            //setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setPhotoUrl(resObj.data.url?resObj.data.url: constants.COMPANY_LOGO_PLACEHOLDER);
                }/*else {
                    setLogo(constants.COMPANY_LOGO_PLACEHOLDER);     
                }*/
            }
        })
        .catch(err => {
            //setIsLoading(false);
            setPhotoUrl(constants.COMPANY_LOGO_PLACEHOLDER);
        });  
    },[]);
    return (
        <>
            <a href={"/companies/details/" + item.id} className="company">
                <div class="company-inner-alignment">
                    <span class="company-logo"><img src={photoUrl} loading="lazy" alt={item.name}/></span>
                    <h4>{item.name}</h4>
                    { item.numOfReviews > 3 ? 
                        <>
                            <div className="star-rating" data-rating={item.numOfReviews > 0? ((item.sumOfRating/item.numOfReviews).toFixed(2)): 0}>
							<StarRatingComponent name="rating" starCount={5} value={item.numOfReviews > 0? ((item.sumOfRating/item.numOfReviews).toFixed(2)): 0}/>
                            </div>
                        </>
                        : <span class="company-not-rated">{t('company_min_votes_required')}</span>
                    }
				</div>
            </a>
        </>
    );
}
export default withTranslation()(CompanyListItem)