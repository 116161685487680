import React, { useState, useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import TrendingPostItem from './TrendingPostItem';
import newsService from '../../../services/NewsService';
import {HTTP_RESPONSE_STATUS} from '../../../constants/http';
/// Independence -> call API
const TrendingPosts = ({t, exclusiveId}) => {
    const [items, setItems] = useState([]);
    useEffect( () => {
        newsService.getTrendingNews()
        .then(resObj=> {
          if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
            setItems(resObj.data.filter(item => item.id != exclusiveId ));
          }
        }).catch(err => {
            setItems([]);
        });
    },[]);
    return (
        <>
            {
                items.length > 0 &&
            <div class="sidebar-widget">
                <h3>{t('news_trending_posts')}</h3>
                <ul className="widget-tabs">
                    {
                        items.map((item, idx) => <TrendingPostItem key={idx} item={item}/>)
                    }
                </ul>
            </div>
            }
        </>
    )
};
export default withTranslation()(TrendingPosts)