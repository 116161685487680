import React from 'react';
import { withTranslation } from "react-i18next";

const NotFound = ({t}) => {
    return (
        <>
            <div className="header_wrapper">
            </div>
            <div className="clearfix"></div>
            <div id="titlebar" className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <h2>{t('not_found_404')}</h2>

                            <nav id="breadcrumbs" className="dark">
                                <ul>
                                    <li><a href="/">{t('home')}</a></li>
                                    <li>{t('not_found_404')}</li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">

                <div className="row">
                    <div className="col-xl-12">

                        <section id="not-found" className="center margin-top-50 margin-bottom-25">
                            <h2>404 <i className="fa fa-question-circle-o"></i></h2>
                            <p>{t('not_found_subtitle')}</p>
                        </section>

                        <div className="row">
                            <div className="col-xl-8 offset-xl-2">
                                    <div className="intro-banner-search-form not-found-search margin-bottom-50">
                                        <div className="intro-search-field ">
                                            <input name="keywords" className="border-0" type="text" style={ {width:"100%"}} placeholder={t('what_you_looking_for')}/>
                                        </div>
                                        <div className="intro-search-button">
                                            <button className="button ripple-effect">{t('search')}</button>
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className="margin-top-70"></div>
        </>
    );
}
export default withTranslation()(NotFound)