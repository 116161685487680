import {ROOT_URL} from '../config'
import {fetchJson} from '../utils/fetch'
import * as authConstants from '../constants/auth'
import format from 'string-format';
const COMPANY_API_V1 = {
    getProfile: "/api/companies/v1/{0}/profile",
    getLogo: "/api/companies/v1/{0}/logo",
    getOpenJobs: "/api/companies/v1/{0}/open_jobs",
    getPhotos: "/api/companies/v1/{0}/photos",
    createReview: "/api/companies/v1/{0}/reviews",
    searchReviews: "/api/companies/v1/{0}/reviews/search",
    searchAndCountReviews: "/api/companies/v1/{0}/reviews/search_and_count",
    searchAndCount: "/api/companies/v1/search_and_count",
    search: "/api/companies/v1/search",
    getFeaturedCompanies: "/api/companies/v1/featured"
};
function getProfile(id) {
    return fetchJson(ROOT_URL + format(COMPANY_API_V1.getProfile, id), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getLogo(id) {
    return fetchJson(ROOT_URL + format(COMPANY_API_V1.getLogo,id), {
        method: 'GET',
        //authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getOpenJobs(id) {
    return fetchJson(ROOT_URL + format(COMPANY_API_V1.getOpenJobs,id), {
        method: 'GET',
        //authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};
function getPhotos(id) {
    return fetchJson(ROOT_URL + format(COMPANY_API_V1.getPhotos,id), {
        method: 'GET',
        //authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};
function searchAndCount (params={}) {
    const query = new URLSearchParams(params);
    return fetchJson(ROOT_URL + COMPANY_API_V1.searchAndCount+ (query? ("?" + query.toString()):""), {
        method: 'GET'
    }).then(response => {
        if(response.status < 200 ||  response.status>=300) {
            throw response;
        }
        return response;
    }).catch(error => {
        throw error;
    });
};
function search (params={}) {
    const query = new URLSearchParams(params);
    return fetchJson(ROOT_URL + COMPANY_API_V1.search+ (query? ("?" + query.toString()):""), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
    }).then(response => {
        if(response.status < 200 ||  response.status>=300) {
            throw response;
        }
        return response;
    }).catch(error => {
        throw error;
    });
};
function searchAndCountReviews (id,params={}) {
    const query = new URLSearchParams(params);
    return fetchJson(ROOT_URL + format(COMPANY_API_V1.searchAndCountReviews,id) + (query? ("?" + query.toString()):""), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
    }).then(response => {
        if(response.status < 200 ||  response.status>=300) {
            throw response;
        }
        return response;
    }).catch(error => {
        throw error;
    });
};
function searchReviews (id, params={}) {
    const query = new URLSearchParams(params);
    return fetchJson(ROOT_URL + format(COMPANY_API_V1.searchReviews,id)+ (query? ("?" + query.toString()):""), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
    }).then(response => {
        if(response.status < 200 ||  response.status>=300) {
            throw response;
        }
        return response;
    }).catch(error => {
        throw error;
    });
};
function createReview(id ,dto) {
    return fetchJson(ROOT_URL + format(COMPANY_API_V1.createReview, id), {
        method: 'POST',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN),
        body: JSON.stringify(dto)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};
function getFeaturedCompanies(limit = 3) {
    let query = "?limit=" + (limit?limit:3);
    return fetchJson(ROOT_URL + COMPANY_API_V1.getFeaturedCompanies + query, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};
export default {
    getProfile,
    getLogo,
    getOpenJobs,
    getPhotos,
    searchAndCount,
    search,
    createReview,
    searchReviews,
    searchAndCountReviews,
    getFeaturedCompanies
};