import React, { useState } from 'react';
import {withTranslation} from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
const Comments = ({t, allowComment}) => {
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
		setShowModal(false);
    };
    const handleRely = (e) => {
        e.preventDefault();
        setShowModal(true);
    };
    return (
        <>
            { allowComment && <div className="row">
                <div className="col-xl-12">
                    <section className="comments">
                        <h3 className="margin-top-45 margin-bottom-0">Comments <span className="comments-amount">(5)</span></h3>
                        <ul>
                            <li>
                                <div className="avatar"><img src="/images/user-avatar-placeholder.png" alt=""/></div>
                                <div className="comment-content"><div className="arrow-comment"></div>
                                    <div className="comment-by">Kathy Brown<span className="date">12th, June 2019</span>
                                        <a href="#" className="reply" onClick = {handleRely}><i className="fa fa-reply"></i> Reply</a>
                                    </div>
                                    <p>Morbi velit eros, sagittis in facilisis non, rhoncus et erat. Nam posuere tristique sem, eu ultricies tortor imperdiet vitae. Curabitur lacinia neque non metus</p>
                                </div>

                                <ul>
                                    <li>
                                        <div className="avatar"><img src="/images/user-avatar-placeholder.png" alt=""/></div>
                                        <div className="comment-content"><div class="arrow-comment"></div>
                                            <div className="comment-by">Tom Smith<span class="date">12th, June 2019</span>
                                                <a href="#" class="reply"><i className="fa fa-reply"></i> Reply</a>
                                            </div>
                                            <p>Rrhoncus et erat. Nam posuere tristique sem, eu ultricies tortor imperdiet vitae. Curabitur lacinia neque.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="avatar"><img src="/images/user-avatar-placeholder.png" alt=""/></div>
                                        <div className="comment-content"><div class="arrow-comment"></div>
                                            <div className="comment-by">Kathy Brown<span className="date">12th, June 2019</span>
                                                <a href="#" className="reply"><i className="fa fa-reply"></i> Reply</a>
                                            </div>
                                            <p>Nam posuere tristique sem, eu ultricies tortor.</p>
                                        </div>

                                        <ul>
                                            <li>
                                                <div className="avatar"><img src="/images/user-avatar-placeholder.png" alt=""/></div>
                                                <div className="comment-content"><div class="arrow-comment"></div>
                                                    <div className="comment-by">John Doe<span className="date">12th, June 2019</span>
                                                        <a href="#" className="reply"><i className="fa fa-reply"></i> Reply</a>
                                                    </div>
                                                    <p>Great template!</p>
                                                </div>
                                            </li>
                                        </ul>

                                    </li>
                                </ul>

                            </li>

                            <li>
                                <div className="avatar"><img src="/images/user-avatar-placeholder.png" alt=""/> </div>
                                <div className="comment-content"><div className="arrow-comment"></div>
                                    <div class="comment-by">John Doe<span className="date">15th, May 2015</span>
                                        <a href="#" className="reply"><i className="fa fa-reply"></i> Reply</a>
                                    </div>
                                    <p>Commodo est luctus eget. Proin in nunc laoreet justo volutpat blandit enim. Sem felis, ullamcorper vel aliquam non, varius eget justo. Duis quis nunc tellus sollicitudin mauris.</p>
                                </div>

                            </li>
                        </ul>

                    </section>
                </div>
            </div>
            }
            <div className="row">
                <div className="col-xl-12">
                    <Modal size="lg" show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('filter')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form method="post" id="add-comment">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div class="input-with-icon-left no-border">
                                            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                                            <input type="text" class="input-text input-search" name="commentname" id="namecomment" placeholder="Name" required=""/>
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="input-with-icon-left no-border">
                                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                            <input type="text" class="input-text input-search" name="emailaddress" id="emailaddress" placeholder="Email Address" required=""/>
                                        </div>
                                    </div>
                                </div>
                                <textarea className="input-search h-auto" name="comment-content" cols="30" rows="5" placeholder="Comment"></textarea>
                            </form>
                            <button class="button button-sliding-icon ripple-effect margin-bottom-40" type="submit" form="add-comment" style={{width: "176.203px"}}>Add Comment <i class="icon-material-outline-arrow-right-alt"></i></button>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    )
};
export default withTranslation()(Comments)