import React from 'react'

const CallToAction = ({title, text, phone}) => (
    <div className="cta">
		<div className="container">
			<div className="row">
				<div className="col">
					<div className="cta_container d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start">
						<div className="cta_content">
							<div className="cta_title">{title}</div>
							<div className="cta_text">{text}</div>
						</div>
                        <div className="cta_phone ml-lg-auto">{phone}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)
export default CallToAction