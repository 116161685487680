import React, { useState, useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import {NEWS_DEFAULT_FEATURE_IMAGE}  from '../../../constants';
import * as utils from '../../../utils';
import promotionService from '../../../services/PromotionService';

import {
    Link
  } from "react-router-dom";
const PromotionListItem = ({t , item, ids}) => {
    const [bannerUrl, setBannerUrl] = useState(NEWS_DEFAULT_FEATURE_IMAGE);
    useEffect( () => {
        promotionService.getPromotionBanner(item.id)
        .then (resObj => {
            if (resObj.status === "OK") {
                setBannerUrl(resObj.data.url);
            }
        })
        .catch(err => {
            setBannerUrl("");
        })
        
    }, []);
    return (
        <>
            <Link className="blog-post" to={"/products/details/" + item.id +"?ids=" + ids.toString()}>
                <div className="blog-post-thumbnail">
                    <div className="blog-post-thumbnail-inner">
                        {/*<span className="blog-item-tag"></span>*/}
                        <img src={bannerUrl? bannerUrl :(item.bannerUrl ?item.bannerUrl: NEWS_DEFAULT_FEATURE_IMAGE)} alt={item.name?item.name:""} loading="lazy"/>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="blog-post-date">{moment(item.publishedAt).format('YYYY-MM-DD, h:mm:ss a')}</span>
                    <h3>{item.name}</h3>
                    <div dangerouslySetInnerHTML={{__html: item.shortDescription?item.shortDescription:""}} />
                </div>
                <div class="entry-icon">
                    
                </div>
            </Link>
            {/*<a href="pages-blog-post.html" className="blog-post">
                
            </a>*/}
        </>
    )
};
export default withTranslation()(PromotionListItem)