import React from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
const NumberBox = ({number, title, description, tips}) => {
    return (
        <>
            <div className="service text-center">
                <div className="service">
                    <div className="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                    <OverlayTrigger placement="top"
                    overlay={
                        <Tooltip>
                            {tips}
                        </Tooltip>
                      }
                    >
                    <div className="icon" style={{color: 'white', fontWeight: "600"}}>{number}</div>
                    </OverlayTrigger>
                    </div>
                    <div className="service_title">{title}</div>
                    <div className="service_text">
                        <p>{description}</p>
                    </div>
                </div>
            </div>  
        </>
    )
};
export default NumberBox