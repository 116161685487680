import React, { useState, useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import newsService from '../../../services/NewsService';
import {HTTP_RESPONSE_STATUS} from '../../../constants/http';
import FeaturedNewsItem from './FeaturedNewsItem';
const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};

const FeaturedNewsSlider = ({t, id}) => {
    const [items, setItems] = useState([]);
    useEffect( () => {
        newsService.getTrendingNews(5)
        .then(resObj=> {
          if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
            setItems(resObj.data);
          }
        }).catch(err => {
            setItems([]);
        });
    },[]);
    return (
        <>
            <div className="section white padding-top-0 padding-bottom-60">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="container">
                            <Slider {...settings}>
                                {
                                    items.map((item, idx) => <FeaturedNewsItem key={idx} item={item} ids={items.map(x => x.id)}/>)
                                }
                                {/*<a style={{width: "334px"}} href="pages-blog-post.html" class="blog-compact-item-container slick-slide mr-5"  data-slick-index="1" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01">
                                    <div class="blog-compact-item">
                                        <img src="images/blog-05a.jpg" alt=""/>
                                        <span class="blog-item-tag">Recruiting</span>
                                        <div class="blog-compact-item-content">
                                            <ul class="blog-post-tags">
                                                <li>28 April 2019</li>
                                            </ul>
                                            <h3>12 Dog-Friendly Companies Hiring Now</h3>
                                            <p>Compellingly embrace empowered e-business after user friendly intellectual capital. Interactively front-end.</p>
                                        </div>
                                    </div>
                                
                                </a>

                                <a style={{width: "334px"}} href="pages-blog-post.html" class="blog-compact-item-container slick-slide slick-current slick-active" data-slick-index="2" aria-hidden="false" tabindex="0" role="tabpanel" id="slick-slide02" aria-describedby="slick-slide-control02">
                                    <div class="blog-compact-item">
                                        <img src="images/blog-03a.jpg" alt=""/>
                                        <span class="blog-item-tag">Marketing</span>
                                        <div class="blog-compact-item-content">
                                            <ul class="blog-post-tags">
                                                <li>10 June 2019</li>
                                            </ul>
                                            <h3>11 Tips to Help You Get New Clients Through Cold Calling</h3>
                                            <p>Compellingly embrace empowered e-business after user friendly intellectual capital. Interactively front-end.</p>
                                        </div>
                                    </div>
                                </a>

                                <a style={{width: "334px"}} href="pages-blog-post.html" class="blog-compact-item-container slick-slide slick-active" data-slick-index="3" aria-hidden="false" tabindex="0" role="tabpanel" id="slick-slide03" aria-describedby="slick-slide-control03">
                                    <div class="blog-compact-item">
                                        <img src="images/blog-06a.jpg" alt=""/>
                                        <span class="blog-item-tag">Recruiting</span>
                                        <div class="blog-compact-item-content">
                                            <ul class="blog-post-tags">
                                                <li>9 June 2019</li>
                                            </ul>
                                            <h3>Follow Up On Job Application With This Template</h3>
                                            <p>Appropriately empower dynamic leadership skills after business portals. Globally myocardinate interactive.</p>
                                        </div>
                                    </div>
                                </a>
                                    <a style={{width: "334px"}} href="pages-blog-post.html" class="blog-compact-item-container slick-slide slick-active" data-slick-index="4" aria-hidden="false" tabindex="0" role="tabpanel" id="slick-slide04" aria-describedby="slick-slide-control04">
                                        <div class="blog-compact-item">
                                            <img src="images/blog-07a.jpg" alt=""/>
                                            <span class="blog-item-tag">Recruiting</span>
                                            <div class="blog-compact-item-content">
                                                <ul class="blog-post-tags">
                                                    <li>3 June 2019</li>
                                                </ul>
                                                <h3>What It Really Takes to Make $100k Before You Turn 30</h3>
                                                <p>Appropriately empower dynamic leadership skills after business portals. Globally myocardinate interactive.</p>
                                            </div>
                                        </div>
                                    </a>*/}
                            </Slider>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
};
export default withTranslation()(FeaturedNewsSlider)