import ReactMarkdown from "react-markdown";
import { useEffect, useState } from 'react';

const FileContent = ({url}) => {
    const [markdown, setMarkdown] = useState("");

    console.log(url);
    useEffect(() => {
        console.log(url);
        fetch(url)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
    }, [url]);
    return (
        <>
            <div className="helps_content">
                <div className="container">
                    <ReactMarkdown source={markdown} />
                </div>
            </div>
        </>
    );
};
export default FileContent;