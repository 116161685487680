export const COMPANY_LOGO_PLACEHOLDER = '/images/company-logo-placeholder.png';
export const SUPPORT_LANGUAGES = [
    {
        code: 'vi',
        name: 'vietnamese',
        flag: '/images/flags/vn.svg'
    },
    {
        code: 'en',
        name: 'english',
        flag: '/images/flags/us.svg'
    }
];

export const NUMEROLOGY_IMAGE_DEFAULT = 'https://res.cloudinary.com/topworks/image/upload/v1627800729/website/numerology/numerology-meanings.jpg';
export const NUMEROLOGY_IMAGES = {
    "1": "https://res.cloudinary.com/topworks/image/upload/v1627799964/website/numerology/numerology-01.png",
    "2": "https://res.cloudinary.com/topworks/image/upload/v1627799964/website/numerology/numerology-02.png",
    "3": "https://res.cloudinary.com/topworks/image/upload/v1627799964/website/numerology/numerology-03.png",
    "4": "https://res.cloudinary.com/topworks/image/upload/v1627799964/website/numerology/numerology-04.png",
    "5": "https://res.cloudinary.com/topworks/image/upload/v1627799964/website/numerology/numerology-05.png",
    "6": "https://res.cloudinary.com/topworks/image/upload/v1627799964/website/numerology/numerology-06.png",
    "7": "https://res.cloudinary.com/topworks/image/upload/v1627799964/website/numerology/numerology-07.png",
    "8": "https://res.cloudinary.com/topworks/image/upload/v1627799964/website/numerology/numerology-08.png",
    "9": "https://res.cloudinary.com/topworks/image/upload/v1627799964/website/numerology/numerology-09.png",
    "11": "https://res.cloudinary.com/topworks/image/upload/v1627799964/website/numerology/numerology-11.png",
    "22": "https://res.cloudinary.com/topworks/image/upload/v1627799964/website/numerology/numerology-22.png"
}
export const BUTTON_ACTIONS = {
    "1": "button_booking",
    "2": "button_show_now",
    "3": "button_learn_more",
    "4": "button_register",
    "5": "button_call_now",
    "6": "button_make_appointment"
};
export const NEWS_DEFAULT_FEATURE_IMAGE = "https://res.cloudinary.com/topworks/image/upload/v1628302234/website/news/default-news-unsplash.jpg";

export const LANGUAGES = [
    {
        code: "en",
        name: "English"
    },
    {
        code: "vi",
        name: "Vietnamese"
    },
    {
        code: "ja",
        name: "Japanese"
    },
    {
        code: "ko",
        name: "Korean"
    },
    {
        code: "de",
        name: "German"
    },
    {
        code: "fr",
        name: "French"
    },
    {
        code: "zh",
        name: "Chinese"
    }
]
export const CURRENCIES = [
    {
        code: "USD",
        name: "USD"
    },
    {
        code: "VND",
        name: "VND"
    }
]
export const COMPANY_SIZES = [
    {
        id: 1,
        name: "< 10"
    },
    {
        id: 2,
        name: "10-30"
    },
    {
        id: 3,
        name: "30-50"
    },
    {
        id: 4,
        name: "50-100"
    },
    {
        id: 5,
        name: "100-500"
    },
    {
        id: 6,
        name: "500-1000"
    },
    {
        id: 7,
        name: "1000-2000"
    },
    {
        id: 8,
        name: "> 2000"
    }
];

export const DATE_FORMATS = {
    "vi": "dd/mm/yyyy",
    "en": "mm/dd/yyyy",
    "en-US": "mm/dd/yyyy",
    "en-Br": "mm/dd/yyyy",
};