import React, { useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

const Terms = ({t}) => {
    const [markdown, setMarkdown] = useState("");
    useEffect(() => {
        fetch("/Terms.md")
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
    }, []);
    return (
        <>
            <div className="header_wrapper">
            </div>
            <div className="clearfix"></div>
            <div id="titlebar" className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <h2>{t('terms_conditions')}</h2>

                            <nav id="breadcrumbs" className="dark">
                                <ul>
                                    <li><a href="/">{t('home')}</a></li>
                                    <li>{t('terms_conditions')}</li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">

                <div className="row">
                    <div className="col-xl-12">
                        <div className="terms">
                            <ReactMarkdown source={markdown} />
                        </div>
                    </div>
                </div>

            </div>
            <div class="margin-top-70"></div>
        </>
    );
}
export default withTranslation()(Terms)
