//import './App.css';
import React, { Component } from 'react';
import Header from './containers/Header';
import Footer from './containers/Footer';
import NewsLetter from './containers/NewsLetter';
import Layout from './views/Layout';
import FingerprintJS from 'fingerprintjs2';
import deviceService  from './services/DeviceService'
import withLanguage from './utils/Language';
import { withRouter } from "react-router";
import queryString from 'query-string'
import ScrollTopButton from './components/ScrollTopButton';
/*import { Switch as RouterSwitch, Route, Redirect } from 'react-router-dom'
import NumerologyCard from './views/pages/NumerologyCard';
import Main from './views/pages/Main';
*/
class App extends Component {
  calcClientFingerprint() {
    if (localStorage.getItem("X-Zetgoo-Client-ID")) {
      return;
    }
    if (window.requestIdleCallback) {
      requestIdleCallback(function () {
        FingerprintJS.get(function (components) {
            let values = components.map(function (component) { return component.value })
            let visitorId = FingerprintJS.x64hash128(values.join(''), 31)
            localStorage.setItem("X-Zetgoo-Client-Fingerprint",visitorId)
            deviceService.signUp({
              fingerprint: visitorId,
              components: components
            }).then(resObj => {
              if (resObj.status === "OK") {
                localStorage.setItem("X-Zetgoo-Client-ID",resObj.data)
              }
            });
          })
      })
    } else {
      setTimeout(function () {
        FingerprintJS.get(function (components) {
            let values = components.map(function (component) { return component.value })
            let visitorId = FingerprintJS.x64hash128(values.join(''), 31)
            localStorage.setItem("X-Zetgoo-Client-Fingerprint",visitorId)
            //Request to Server for DeviceID
            deviceService.signUp({
              fingerprint: visitorId,
              components: components
            }).then(resObj => {
              if (resObj.status === "OK") {
                localStorage.setItem("X-Zetgoo-Client-ID",resObj.data)
              }
            });
          })  
      }, 500)
    }
  }
  componentDidMount() {
    this.calcClientFingerprint();
    const values = queryString.parse(this.props.location.search)
    if (values.authToken) {
      localStorage.setItem("X-Auth-Token", values.authToken)
      //Fetch MetaInfo
      //ROLES, scope, resumeId, companyId -> Context/Session
    }
  }
  render() {
    return (
      <>
        {/*<RouterSwitch>
          <Route path="/numerology-card/:uuid" component={NumerologyCard}/>
          <Route path="*" component={Main}/>
        </RouterSwitch>*/}
        <Header />
        <Layout />
        <NewsLetter />
        <Footer />
        <ScrollTopButton scrollStepInPx="50" delayInMs="16.66"/>
      </>
    );
  }
}

export default withLanguage(withRouter(App));

/**
 * Web Design
 * https://css-tricks.com/app-from-scratch-1-design/
 * 
 * 
 * https://www.aihr.com/blog/category/articles/
 * https://123job.vn/
 * https://www.bamboohr.com/employee-database-software/
 * https://viecoi.vn/ho-so/linh-vuc-nhan-su-67.html
 * https://talentbrand.vn
 * https://www.toptal.com/enterprise#talent
 * http://preview.themeforest.net/item/hireo-job-board-freelance-services-marketplace-html-template/full_screen_preview/22202212?clickid=TMN2gMSF6xyLUEQVtuydD0F3UkBRmh3pZWaIwY0&iradid=275988&iradtype=ONLINE_TRACKING_LINK&irgwc=1&irmptype=mediapartner&irpid=369282&mp_value1=&utm_campaign=af_impact_radius_369282&utm_medium=affiliate&utm_source=impact_radius
 */