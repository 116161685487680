import React, { useEffect, useState } from 'react'
import { withTranslation } from "react-i18next"
import classNames from 'classnames';
const Menu = ({t, open, close}) => {
    const [menuOverlayClasses, setMenuOverlayClasses] = useState(classNames("menu_overlay","trans_400"));
    const [menuClasses, setMenuClasses] = useState(classNames("menu","trans_400"));
    useEffect(()=> {
        if (open === true) {
            setMenuOverlayClasses(classNames("menu_overlay","trans_400", "active"));
            setMenuClasses(classNames("menu","trans_400", "active"));
        } else {
            setMenuOverlayClasses(classNames("menu_overlay","trans_400"));
            setMenuClasses(classNames("menu","trans_400"));
        }
    }, [open]);
    return (
    
    <>
        <div className={menuOverlayClasses}></div>
        <div className={menuClasses}>
            <div className="menu_close_container" onClick={close}><div className="menu_close"><div></div><div></div></div></div>
            <nav className="menu_nav">
                <ul>
                    <li><a href="/">{t('home')}</a></li>
                    <li><a href="/jobs">{t('jobs')}</a></li>
                    <li><a href="/recruiters">{t('recruiters')}</a></li>
                    <li><a href="/blogs">{t('news')}</a></li>
                    <li><a href="/contact">{t('contact')}</a></li>
                </ul>
            </nav>
            <div className="menu_extra">
                <div className="menu_link">Mo - Fri: 8:00am - 6:00pm</div>
                <div className="menu_link">+8490 866 77 96</div>
                <div className="menu_link"><a href="/make-an-appointment">{t('make_an_appointment')}</a></div>
            </div>
            <div className="social menu_social">
                <ul className="d-flex flex-row align-items-center justify-content-start">
                    <li><a href="https://www.facebook.com/TopWorksVN" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                    <li><a href="https://twitter.com/TopWorksVN" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/top-works-50569320b" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                </ul>
            </div>
        </div>
    </>
)
}
export default withTranslation()(Menu);