import React from 'react'
import { withTranslation } from "react-i18next"

const Team = ({t}) => {
	return (
		<>
			<div class="team">
				<div class="container">
					<div class="row">
						<div class="col">
							<div class="section_title_container text-center">
							<div className="section_subtitle">{t('about_us_this_is_topworks')}</div>
								<div className="section_title"><h2>{t('about_meet_our_team')}</h2></div>
							</div>
						</div>
					</div>
					<div class="row team_row">						
						<div class="col-lg-4 team_col">
							<div class="team_item text-center d-flex flex-column aling-items-center justify-content-end">
								<div class="team_image"><img src="/images/team_1.jpg" alt=""/></div>
								<div class="team_content text-center">
									<div class="team_name"><a href="#">Nguyen Van A</a></div>
									<div class="team_title">CEO</div>
									<div class="team_text">
										<p>Odio ultrices ut. Etiam ac erat ut enim maximus accumsan vel ac nisl. Duis feugiat bibendum orci, non elementum urna.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 team_col">
							<div class="team_item text-center d-flex flex-column aling-items-center justify-content-end">
								<div class="team_image"><img src="/images/team_2.jpg" alt=""/></div>
								<div class="team_content text-center">
									<div class="team_name"><a href="#">Tran Thi C</a></div>
									<div class="team_title">Customer Services</div>
									<div class="team_text">
										<p>Ultrices ut. Etiam ac erat ut enim maximus accumsan vel ac nisl. Duis feugiat bibendum orci, non elementum urna.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-4 team_col">
							<div class="team_item text-center d-flex flex-column aling-items-center justify-content-end">
								<div class="team_image"><img src="/images/team_3.jpg" alt=""/></div>
								<div class="team_content text-center">
									<div class="team_name"><a href="#">Nguyen Van D</a></div>
									<div class="team_title">CTO</div>
									<div class="team_text">
										<p>Etiam ac erat ut enim maximus accumsan vel ac nisl. Duis feugiat bibendum orci, non elementum urna. Odio ultrices ut.</p>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</>
	)
};
export default withTranslation()(Team)