import React from 'react'
import ReviewListItem from './ReviewListItem'
const ReviewList = ({items}) => {
    return (
    <>
        <ul className="boxed-list-ul">
            {items.map( (item, idx) => (<ReviewListItem key={idx} item={item} />))}
        </ul>
    </>
)}
export default ReviewList