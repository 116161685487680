import {ROOT_URL} from '../config'
import * as authConstants from '../constants/auth'
import {fetchJson} from '../utils/fetch'
import format from 'string-format'
import queryString from 'query-string';
const NUMEROLOGY_API_V1 = {
    createProfile: "/api/numerology/v1/profiles/summary",
    getDetails: "/api/numerology/v1/profiles/uuid/{0}",
};

function createProfile(reqData) {
    return fetchJson(ROOT_URL + NUMEROLOGY_API_V1.createProfile, {
        method: 'POST',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN),
        body: JSON.stringify(reqData)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getDetails(uuid) {
    return fetchJson(ROOT_URL + format(NUMEROLOGY_API_V1.getDetails, uuid), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}

function calcBirthdayChart(year, month, day) {
    let charts = {
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
        "6": 0,
        "7": 0,
        "8": 0,
        "9": 0
    };
    let text = year.toString() + month.toString() + day.toString();
    for(let i = 0;i <text.length; i++) {
        let num = charts[text.chartAt(i)];
        if (num) {
            charts[text.chartAt(i)] = num + 1;
        }
    }
    return charts;
}
export default {
    createProfile,
    getDetails
};
/**
 * https://learn.feliciabender.com/shop-pnr1
 * https://seventhlifepath.com/hearts-desire-soul-urge-number/
 * https://ybox.vn/gia-vi/bookademy-review-sach-mat-ma-than-so-hoc-nhung-con-so-thay-doi-cuoc-doi-ban-tyousz4sj1
 */