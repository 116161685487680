import {HTTP_HEADER} from './../constants/http'
import {ROOT_URL} from '../config'
import * as authConstants from '../constants/auth'
import {fetchJson} from '../utils/fetch'
import format from 'string-format'

const RESUME_API_V1 = {
    getDetailsByMe: "/api/resumes/v1/me"
}
function getDetailsByMe() {
    return fetchJson(ROOT_URL + RESUME_API_V1.getDetailsByMe, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}

export default {
    getDetailsByMe   
}