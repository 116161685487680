import {HTTP_HEADER} from '../constants/http';
import {ROOT_URL} from '../config';
import * as authConstants from '../constants/auth';
import {fetchJson} from '../utils/fetch';
import format from 'string-format';
const ADS_API_V1 = {
    getPublicAds: "/api/ads/v1/publish", 
};

function getPublicAds(limit) {
    return fetchJson(ROOT_URL + ADS_API_V1.getPublicAds+"?limit="+limit, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
export default {
    getPublicAds
};