import React, { useState } from 'react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import {NEWS_DEFAULT_FEATURE_IMAGE}  from '../../../constants';
const FeaturedNewsItem = ({t , item, ids}) => {
    return (
        <>
            <a href={"/blogs/details/" + item.id +"?ids=" + ids.toString()} className="blog-compact-item-container slick-slide mr-5"  data-slick-index="1" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide01" aria-describedby="slick-slide-control01">
                <div className="blog-compact-item" style={{marginRight: "4px", marginLeft: "4px"}}>
                    <img src={item.featuredImageUrl?item.featuredImageUrl:NEWS_DEFAULT_FEATURE_IMAGE} alt={item.title} loading="lazy"/>
                    <span className="blog-item-tag">{item.tags}</span>
                    <div className="blog-compact-item-content">
                        <ul className="blog-post-tags">
                            <li>{moment(item.publishedAt).format('YYYY-MM-DD')}</li>
                        </ul>
                        <h3>{item.title}</h3>
                        {/*<p>{item.excerpt}</p>*/}
                    </div>
                </div>
                                
            </a>
        </>
    )
};
export default withTranslation()(FeaturedNewsItem)