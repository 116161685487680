import React, { useEffect, useState } from 'react';
import { withTranslation, useTranslation } from 'react-i18next'
import jobService from '../../../services/JobService';
import ticketService from '../../../services/TicketService';
import {HTTP_RESPONSE_STATUS} from '../../../constants/http';
import {DATE_FORMATS} from '../../../constants'
import { useAlert } from 'react-alert';
import * as validations from '../../../constants/validations';
import validate from 'validate.js';
import * as utils from '../../../utils';
import moment from 'moment';

const INIT_VALUES = {
    name: "",
    phone: "",
    email: "",
    birthday: "",
    major: "",
    content: ""
};

const INIT_ERRORS = {
    name: "",
    phone: "",
    email: "",
    birthday: "",
    major: "",
    content: ""
};
const constrainsts = {
    name: validations.FIELD_REQUIRED_STRING_160,
    email: validations.EMAIL,
    phone: validations.FIELD_REQUIRED_STRING_100,
    birthday: {
        presence: {
            allowEmpty: false,
            message: "^field_required"
        },
        datetime: {
            dateOnly: true,
            message: "^date_required_format"
        }
    }
};
// Before using it we must add the parse and format functions
// Here is a sample implementation using moment.js
// Before using it we must add the parse and format functions
// Here is a sample implementation using moment.js
validate.extend(validate.validators.datetime, {
    // The value is guaranteed not to be null or undefined but otherwise it
    // could be anything.
    parse: function(value, options) {
      return +moment.utc(value);
    },
    // Input is a unix timestamp
    format: function(value, options) {
      var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
      return moment.utc(value).format(format);
    }
});
const RegisterForm = ({t, i18n}) => {
    const toaster = useAlert();
    const [majors, setMajors] = useState([]);
    const [values, setValues] = useState(INIT_VALUES);
    const [errors, setErrors] = useState(INIT_ERRORS);
    const [alert, setAlert] = useState('');

    useEffect(()=> {
        jobService.getAllMajors()
          .then(response=> {
            if (response.status === HTTP_RESPONSE_STATUS.OK) {
				setMajors(response.data)
            }
          }).catch(err => {

		});
    },[]);
    const handleChangeFields = (e) => {
        values[e.target.name] = e.target.value;
        setValues({...values});
    };
    const hanleSumit = (e) => {
        e.preventDefault();
        let reqData = {
            name: values.name?values.name: null,
            email: values.email?values.email: null,
            phone: values.phone?values.phone: null,
            birthday: values.birthday?values.birthday: null,
            major: values.major?values.major: null,
            content: values.content? values.content: null
        };
        const fieldErrors = validate(reqData, constrainsts);
		if (fieldErrors) {
            //const flatErrrors = utils.convertErrors2Object(fieldErrors);
            //console.log(flatErrrors);
            toaster.show('Đã có lỗi. Vui lòng kiểm tra dữ liệu nhâp và thử lại!');
            return;
        }
        ticketService.register(reqData)
		.then(resObj => {
			if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
				toaster.show('Bạn đã đăng ký thành công!');
                setValues({...INIT_VALUES});
                setErrors({...INIT_ERRORS});
			} else {
				toaster.show('Đã có lỗi. Vui lòng thử lại!');
			}
		})
		.catch(err => {
			toaster.show('Đã có lỗi. Vui lòng thử lại!');
		});
        
    };
    return (
        <>
            <div className="intro_form_container">
                <div className="intro_form_title">Đăng ký nhận ưu đãi</div>
                <form className="intro_form" id="intro_form">
                    <div className="d-flex flex-row align-items-start justify-content-between flex-wrap">
                        <input type="text" name="name" value={values.name} className="intro_input" placeholder="Họ tên (*)" required="required" onChange={handleChangeFields}/>
                        <input type="email" name="email" value={values.email} className="intro_input" placeholder="Địa chỉ E-mail (*)" required="required" onChange={handleChangeFields}/>
                        <input type="tel" name="phone" value={values.phone} className="intro_input" placeholder="Số điện thoại (*)" required="required" onChange={handleChangeFields}/>
                        <input type="text" name="birthday" value={values.birthday} className="intro_input" placeholder={"Ngày sinh" +  (DATE_FORMATS[i18n.language]? (" (" + DATE_FORMATS[i18n.language] + ") ") : " (mm/dd/yyyy) ") +"(*)"} required="required" onChange={handleChangeFields}/>
                        <select name="major" value={values.major} className="intro_select intro_input" onChange={handleChangeFields}>
                            <option disabled={true} selected={true} value="" >Nghề nghiệp</option>
                            {
                                majors.map((item, index) => {
                                    return (
                                    <option value={item.name} key={index}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                        <input type="text" name="content" value={values.content} className="intro_input" placeholder="Thông điệp" onChange={handleChangeFields}/>
                    </div>
                    {alert && <div className="alert text-danger">{t(alert)}</div>}
                    <button type="submit" className="button button_1 intro_button trans_200" onClick={hanleSumit}>Đăng ký</button>
                </form>
            </div>
        </>
    );
};
export default withTranslation()(RegisterForm)