import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import StarRatingComponent from 'react-star-rating-component';
import companyService from '../../services/CompanyService';
import countryService from '../../services/CountryService';
import JobsList from './components/JobList';
import * as constants from './../../constants';
import ReactPlayer from 'react-player';
import Gallery from "react-photo-gallery";
import ImageGallery from 'react-image-gallery';

import Carousel, { Modal as ImageModal, ModalGateway } from "react-images";
import { useCallback } from 'react';
import Alert from 'react-bootstrap/Alert';
import Reviews from './components/Reviews';
import "react-image-gallery/styles/css/image-gallery.css";

const BACKGROUND_IMAGE = '/images/single-company.jpg';

const CompantDetails = ({t, match}) => {
   
	const [details, setDetails] = useState({});
	const [country, setCountry] = useState({});
	const [openJobs, setOpenJobs] = useState([]);
	const [photos, setPhotos] = useState([]);
	const [backgroundImage, setBackgroundImage] = useState("url(/images/single-company.jpg)");
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	const closeLightbox = () => {
		setCurrentImage(0);
		setViewerIsOpen(false);
	};
    
	const loadCompany = () => {
		companyService.getProfile(match.params.id)
		.then( resObj => {
			if (resObj.status === "OK") {
				setDetails(resObj.data)
				let bgImage = "url(" + (resObj.data.coverPhotoUrl?resObj.data.coverPhotoUrl:BACKGROUND_IMAGE)+ ")";
				setBackgroundImage(bgImage);
				/// Calc prev Post
                countryService.getCountry(resObj.data.countryId)
                .then(rs => {
					setCountry(rs.data);
                })
			}
		})
		.catch (err => {

		});
	};
	const loadOpenJobs = () => {
		companyService.getOpenJobs(match.params.id)
		.then( resObj => {
			if (resObj.status === "OK") {
				setOpenJobs([...resObj.data]);
			} else {
				setOpenJobs([]);
			}
		})
		.catch (err => {
			setOpenJobs([]);
		});
	};
	const loadPhotos = () => {
        companyService.getPhotos(match.params.id)
        .then( resObj => {
            if (resObj.status === "OK") {
                if (resObj.data) 
                {
                    setPhotos(resObj.data.map(item => ({original: item.url, width: 1, height: 1})));
                } else {                                        
                    setPhotos([]);
                }
                
            } else {
                setPhotos([]);
            }
        })
        .catch( err => {
			setPhotos([]);
        });
    }
	useEffect( () => {
		loadCompany();
		loadOpenJobs();
		loadPhotos();
	}, []);
    return (
        <>
        <div className="clearfix"></div>
        <div className="single-page-header" data-background-image="/images/single-company.jpg">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="single-page-header-inner">
                            <div className="left-side">
                                <div className="header-image"><img width={"140px"} loading="lazy" src={details.logoUrl?details.logoUrl:constants.COMPANY_LOGO_PLACEHOLDER} alt={details.name}/></div>
                                <div className="header-details">
									<h3>{details.name} <span>{ details.industry?details.industry.name: null}</span></h3>
                                    <ul>
                                        <li>
											<div className="star-rating" data-rating={details.numOfReviews > 0? ((details.sumOfRating/details.numOfReviews).toFixed(2)): 0}>
											<StarRatingComponent name="rating" starCount={5} value={details.numOfReviews > 0? ((details.sumOfRating/details.numOfReviews).toFixed(2)): 0}/>
											{/*<span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span>*/}
											</div>
										</li>
										<li><img className="flag" src={"/images/flags/"+ (country.code?country.code.toLowerCase():"")+".svg"} alt={country.name} loading="lazy"/> {country.name}</li>
                                        { details.verified === true && <li><div className="verified-badge-with-title"><i className="fa fa-check" aria-hidden="true"></i> {t('verified')}</div></li> }
                                    </ul>
                                </div>
                            </div>
                            <div className="right-side">
                                <nav id="single-page__breadcrumbs" className="white">
                                    <ul>
                                        <li><a href="/">{t('home')}</a></li>
                                        <li><a href="/companies">{t('companies')}</a></li>
                                        <li>{details.name}</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="background-image-container" style={{backgroundImage: backgroundImage}}></div>
        </div>
        <div className="container">
			<div className="row">
		
		<div className="col-xl-8 col-lg-8 content-right-offset">

			<div className="single-page-section">
				<h3 className="margin-bottom-25">{t('introduction')}</h3>
				{
					!details.overview && <Alert className="mt-3" variant="info">
						{t('no_items_found')}
					</Alert>
				}
				<div dangerouslySetInnerHTML={{__html: details.overview}} />
			</div>
			{ details.benefits &&
			<div className="single-page-section">
				<h3 className="margin-bottom-25">{t('benefits')}</h3>
				{
					!details.benefits && <Alert className="mt-3" variant="info">
						{t('no_items_found')}
					</Alert>
				}
				<div dangerouslySetInnerHTML={{__html: details.benefits}} />
			</div>
			}
			
			{/*<div className="boxed-list margin-bottom-60">
				<div className="boxed-list-headline">
					<h3><i className="fa fa-briefcase" aria-hidden="true"></i> {'Vị trí đăng tuyển'}</h3>
				</div>

				<div className="listings-container compact-list-layout">
					<JobsList items={openJobs} />
				</div>

	</div>*/}
			<div className="boxed-list margin-bottom-60">
				<div className="boxed-list-headline mb-3">
					<h3><i className="fa fa-picture-o" aria-hidden="true"></i> {t('gallery')}</h3>
				</div>
				{
					photos.length === 0 && !details.video && <Alert className="mt-3" variant="info">
						{t('no_items_found')}
					</Alert>
				}
				{/*<Gallery photos={photos} onClick={openLightbox} />*/}
				<ImageGallery items={photos} />
	  	{	details.video && 
                    <div className="video-player-wrapper mt-5"><ReactPlayer controls className='video-react-player' url={details.video} width='100%' height='100%'/></div>
					}
			</div>
			<Reviews companyId={match.params.id} companyName={details.name}/>
		</div>

		<div className="col-xl-4 col-lg-4">
			<div className="sidebar-container">

				<div className="sidebar-widget">
					<h3>{t('location')}</h3>
					{
					<div id="single-job-map-container">
						{
							/*<a href="#" id="streetView">Street View</a>*/
						}
						<a href={"https://www.google.com/maps/search/"+details.street + ", "+ details.city} target="_blank">
							<address><i className="fa fa-map-marker" aria-hidden="true"></i> {details.street + ", "+ details.city}
</address>
						</a>
					</div>
					}
				</div>

				{/*<div className="sidebar-widget">
					<h3>Social Profiles</h3>
					<div class="freelancer-socials margin-top-25">
						<ul>
							<li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="Dribbble"><i class="icon-brand-dribbble"></i></a></li>
							<li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="Twitter"><i class="icon-brand-twitter"></i></a></li>
							<li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="Behance"><i class="icon-brand-behance"></i></a></li>
							<li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="GitHub"><i class="icon-brand-github"></i></a></li>
						
						</ul>
					</div>
				</div>*/}
				{/*
				<div class="sidebar-widget">
                        <h3>{t('bookmark_or_share')}</h3>

                        <button class="btn bookmark-button margin-bottom-25 pl-3">
                            <i class="fa fa-bookmark-o" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;
                            {t('bookmark')}
                            <span class="bookmark-text">Bookmark</span>
                            <span class="bookmarked-text">Bookmarked</span>
                        </button>

                        <div class="copy-url">
                            <input id="copy-url" type="text" value="" class="input w-100 with-border"/>
                            <button class="btn copy-url-button ripple-effect" data-clipboard-target="#copy-url" data-tippy-placement="top" data-tippy="" data-original-title="Copy to Clipboard"><i class="fa fa-clipboard" aria-hidden="true"></i></button>
                        </div>

                        <div class="share-buttons margin-top-25">
                            <div class="share-buttons-trigger"><i class="fa fa-share-alt" aria-hidden="true"></i></div>
                            <div class="share-buttons-content">
                                <span>{t('share_interesting')} <strong>{t('share_it')}</strong></span>
                                <ul class="share-buttons-icons">
                                    <li><a href="#" data-button-color="#3b5998" data-tippy-placement="top" data-tippy="" data-original-title="Share on Facebook" style={{backgroundColor: "rgb(59, 89, 152)"}}><i class="fa fa-facebook-square" aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-button-color="#1da1f2" data-tippy-placement="top" data-tippy="" data-original-title="Share on Twitter" style={{backgroundColor: "rgb(29, 161, 242)"}}><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="#" data-button-color="#0077b5" data-tippy-placement="top" data-tippy="" data-original-title="Share on LinkedIn" style={{backgroundColor: "rgb(0, 119, 181)"}}><i class="fa fa-linkedin-square" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
				</div>
				*/}
				<div className="sidebar-widget">
					<div className="boxed-list margin-bottom-60">
						<div className="boxed-list-headline">
							<h3><i className="fa fa-briefcase" aria-hidden="true"></i> {'Vị trí đăng tuyển'}</h3>
						</div>

						<div className="listings-container compact-list-layout">
							<JobsList items={openJobs} />
						</div>
					</div>
				</div>
				<div className="sidebar-widget">
					<div class="fb-page" data-href="https://www.facebook.com/TopWorksVN" data-tabs="timeline" data-width="" data-height="100" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/TopWorksVN" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/TopWorksVN">TopWorks</a></blockquote></div>						
				</div>

			</div>
		</div>

	</div>
</div>
    </>
    );
    
}
export default withTranslation()(CompantDetails)