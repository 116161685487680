import React from 'react'
const ServerError = () => {
    return (
        <>
            <div className="header_wrapper">
            </div>
            <div className="clearfix"></div>
            <div id="titlebar" class="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <h2>500 Server Error</h2>

                            <nav id="breadcrumbs" claclassNamess="dark">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>500 Server Error</li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">

                <div className="row">
                    <div className="col-xl-12">

                        <section id="not-found" className="center margin-top-50 margin-bottom-25">
                            <h2>500 <i className="fa fa-question-circle-o"></i></h2>
                            <p>We're sorry, but the page you were looking for doesn't exist</p>
                        </section>

                        <div class="row">
                            <div class="col-xl-8 offset-xl-2">
                                    <div class="intro-banner-search-form not-found-search margin-bottom-50">
                                        <div class="intro-search-field ">
                                            <input id="intro-keywords" type="text" placeholder="What Are You Looking For?"/>
                                        </div>
                                        <div class="intro-search-button">
                                            <button class="button ripple-effect">Search</button>
                                        </div>
                                    </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="margin-top-70"></div>
        </>
    );
}
export default ServerError