import React from 'react'
import { withTranslation } from "react-i18next";
import Testimonials from '../about/Testimonials';
import CallToAction from '../../containers/CallToAction';
import Team from '../../components/team/Team';
const About = ({t}) => (
    <>
		<div class="home d-flex flex-column align-items-start justify-content-end">
			<div class="background_image" style={{backgroundImage: "url(/images/home_overlay.png)"}}></div>
			{/*<div class="parallax_background parallax-window" data-parallax="scroll" data-image-src="images/about.jpg" data-speed="0.8"></div>*/}
			<div class="home_overlay"><img src="/images/home_overlay.png" alt="TopWorks - Change your life" style={{maxHeight: "40vh"}}/></div>
			<div class="home_container">
				<div class="container">
					<div class="row">
						<div class="col">
							<div class="home_content">
								{/*<div class="home_title">{t('about_us')}</div>
								<div class="home_text">{t('about_us_subtitle')}</div>*/}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="intro">
			<div className="container">
				<div className="row">
					<div className="col-lg-8">
						<div className="intro_content">
							<div className="section_title_container">
								<div className="section_subtitle">{t('about_us_this_is_topworks')}</div>
								<div className="section_title"><h2>{t('about_us_welcome')}</h2></div>
							</div>
							<div className="intro_text">
								<p>{t('about_us_intro')}</p>
							</div>
							<div className="milestones">
								<div className="row milestones_row">							
									<div className="col-md-3 milestone_col">
										<div className="milestone">
											<div className="milestone_counter" data-end-value="5000" data-sign-before="+">+5000</div>
											<div className="milestone_text">{t('about_us_numergolory_reports')}</div>
										</div>
									</div>
									<div class="col-md-3 milestone_col">
										<div class="milestone">
											<div className="milestone_counter" data-end-value="352">+1000</div>
											<div className="milestone_text">{t('about_us_satisfied_customers')}</div>
										</div>
									</div>
									<div class="col-md-3 milestone_col">
										<div className="milestone">
											<div class="milestone_counter" data-end-value="718">718</div>
											<div class="milestone_text">{t('about_us_enterprises')}</div>
										</div>
									</div>
									<div class="col-md-3 milestone_col">
										<div class="milestone">
											<div className="milestone_counter" data-end-value="5">+100</div>
											<div className="milestone_text">{t('about_us_partnership')}</div>
										</div>
									</div>

								</div>
							</div>

						</div>
					</div>
					<div class="col-lg-3 offset-lg-1">
						<div className="intro_image"><img src="images/intro_1.jpg" alt=""/></div>
					</div>
				</div>
			</div>
		</div>
		<div class="services">
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <div class="section_title_container">
                                <div class="section_subtitle">Nền tảng <strong>TopWORKS</strong></div>
                                <div class="section_title"><h2>Nguyên lý hoạt động</h2></div>
                            </div>
                        </div>
                    </div>
                    <div class="row services_row">                    
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/clipboard.svg" alt="https://www.flaticon.com/authors/prosymbols" loading="lazy"/></div>
                                    </div>
                                    <div class="service_title">Hồ sơ công ty</div>
                                    <div class="service_text">
                                        <p>Trình bày thông tin về công ty như tên, ngày thành lập, lĩnh vực hoạt động, quá trình phát triển...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/file.svg" alt="https://www.flaticon.com/authors/prosymbols" loading="lazy"/></div>
                                    </div>
                                    <div class="service_title">Mô tả công việc</div>
                                    <div class="service_text">
                                        <p>Văn bản mô tả các nhiệm vụ chung hoặc các nhiệm vụ liên quan khác và trách nhiệm của một vị trí.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/curriculum.svg" alt="https://www.flaticon.com/authors/prosymbols" loading="lazy"/></div>
                                    </div>
                                    <div class="service_title">Hồ sơ ứng viên</div>
                                    <div class="service_text">
                                        <p>Trình bày thông tin cá nhân, kiến thức, kỹ năng, thái độ, kinh nghiệm làm việc và thành tựu đạt được của ứng viên.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/idea.svg" alt="https://www.flaticon.com/authors/prosymbols" loading="lazy"/></div>
                                    </div>
                                    <div class="service_title">Trí tuệ nhân tạo</div>
                                    <div class="service_text">
                                        <p>Mô tả cách máy móc (hoặc máy tính) bắt chước các chức năng "nhận thức" mà con người liên kết với tâm trí con người, chẳng hạn như "học tập" và "giải quyết vấn đề.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/calculator.svg" alt="https://www.flaticon.com/authors/prosymbols" loading="lazy"/></div>
                                    </div>
                                    <div class="service_title">Thần số học</div>
                                    <div class="service_text">
                                        <p>Khoa học nghiên cứu về mối liên hệ của các con số tự nhiên đến các thực thể (con người, doanh nghiệp, ...) tồn tại trong thế giới của các sung vận động.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-6 service_col">
                            <div class="service text-center">
                                <div class="service">
                                    <div class="icon_container d-flex flex-column align-items-center justify-content-center ml-auto mr-auto">
                                        <div class="icon"><img src="/images/files.svg" alt="https://en.wikipedia.org/wiki/Data" loading="lazy"/></div>
                                </div>
                                <div class="service_title">Khoa học dữ liệu</div>
                                <div class="service_text">
                                    <p>Một lĩnh vực liên ngành về các quá trình và các hệ thống rút trích tri thức hoặc hiểu biết từ dữ liệu ở các dạng khác nhau, kể ở dạng cấu trúc hay phi cấu trúc.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
		{/*<Testimonials />*/}
		<CallToAction/>
		<Team/>
    </>
)
export default withTranslation()(About)