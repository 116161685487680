import React, { useState, useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import ReviewList from './ReviewList';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import StarRatingComponent from 'react-star-rating-component';
import validate from 'validate.js';
import * as validations from '../../../constants/validations';
import Pagination from '../../../components/Pagination';
import companyService from '../../../services/CompanyService';
import {HTTP_RESPONSE_STATUS} from '../../../constants/http';
import Alert from 'react-bootstrap/Alert';
import * as utils from '../../../utils';
const INIT_VALUES = {
	username: "",
	title: "",
	content: "",
	rating: 0
};
const INIT_ERRORS = {
	username: "",
	title: "",
	content: "",
	rating: ""
};
const constraints_review = {
	//username: validations.FIELD_REQUIRED_STRING_100,
	title: validations.FIELD_REQUIRED_STRING_160,
	content: validations.FIELD_REQUIRED_STRING_500,
	rating: {
		presence: {
			allowEmpty: false,
			message: "^field_required"
		},
		numericality: {
			greaterThan: 0,
			message: "^field_required"
		}
	}
};
const ERRORS_MAP = {
	"1051": {
		field: "title",
		message: "field_required"
	},
	"1052": {
		field: "title",
		message: "field_too_long_max_160"
	},
	"1053": {
		field: "content",
		message: "field_required"
	},
	"1054": {
		field: "content",
		message: "field_too_long_max_500"
	},
	"1055": {
		field: "rating",
		message: "field_required"
	},
	"1056": {
		field: "rating",
		message: "field_value_incorrect"
	}
};
const Reviews = ({t, companyId, companyName}) => {
    //const authentication = useSelector(state => state.authentication)
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
	const [values, setValues] = useState(INIT_VALUES);
	const [errors, setErrors] = useState(INIT_ERRORS);
	const [alert, setAlert] = useState("");
    const [items, setItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState({});
    const [pageSize, setPageSize] = useState(6);
    const handleClose = () => {
		if (isLoading)
		{
			return;
		}
		setShowModal(false);
		setErrors({...INIT_ERRORS});
		setValues({...INIT_VALUES});
	};
	useEffect( () => {
		loadReviews();
	}, []);
	const loadReviews = () => {
        setIsLoading(true);
        companyService.searchAndCountReviews(companyId, {...searchParams, page: currentPage, size: pageSize})
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setItems(resObj.data.result);
                    setCurrentPage(resObj.data.page);
                    setTotalPages(Math.ceil(resObj.data.total/pageSize));
                } else {
                    setItems([]);
                    setCurrentPage(0);
                    setTotalPages(0);
                }
            }
        })
        .catch(err => {
            setItems([]);
            setCurrentPage(1);
            setTotalPages(0);
            setIsLoading(false);
        });
    };
	const handleChangeFields = (e) => {
        values[e.target.name] = e.target.value;
        setValues({...values});
	};
	const handleStarClick = (nextValue, prevValue, name) => {
		values['rating'] = nextValue;
		setValues({...values});	
	};
	const handleSave = () => {
		if (isLoading)
		{
			return;
		}
		let reqData = {
			username: values.username? values.username: null,
			title: values.title? values.title: null,
			content: values.content? values.content: null,
			rating: values.rating? values.rating: null
		}
		let fieldErrors = validate(reqData, constraints_review);
		if (fieldErrors) {
			setErrors({...utils.convertErrors2Object(fieldErrors)});
			return;
		}
		companyService.createReview(companyId, reqData)
		.then(resObj => {
			if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
				setErrors({...INIT_ERRORS});
				setValues({...INIT_VALUES});
				setAlert("");
				loadReviews();
				setShowModal(false);
			} else {
				if (resObj.status === HTTP_RESPONSE_STATUS.INVALID_REQUEST) {
					setErrors({...utils.createErrorsFromErrorCodes(ERRORS_MAP,resObj.errors)});
				} else {
					setAlert("try_again");
				}
			}
		})
		.catch (err => {
			setAlert("try_again");
		})
    };
    const onPageChanged = (e, page) => {
        e.preventDefault();
        setCurrentPage(page);
    };
    return (
    <>
        <div className="boxed-list margin-bottom-60">
				<div className="boxed-list-headline">
					<h3><i className="fa fa-thumbs-up" aria-hidden="true"></i> {t('review')}</h3>
				</div>
				{
					items.length === 0 && <Alert className="mt-3" variant="info">
						{t('review_no_records')}
					</Alert>
            	}
				<ReviewList items={items}/>
                <div className="centered-button margin-top-35 mb-3 mt-3">
                    <Button variant="primary" type="submit" onClick={() => setShowModal(true)}>{t('leave_review')}</Button>				
                </div>
				{
					totalPages > 1 && 
					<>
					<div className="clearfix"></div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pagination-container margin-top-10 margin-bottom-20">
                                <Pagination limit={2} onPageChanged={onPageChanged} activePage={currentPage} pages={5}/>
                            </div>
                        </div>
                	</div>
					</>
				}
                
				<Modal show={showModal} onHide={handleClose}>
					<Modal.Header closeButton>
					<Modal.Title>{t("company_review")} <strong>{companyName}</strong></Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<h3 className="text-center">{t('company_review_title') + " " + companyName}?</h3>
							<div className="text-center">
								<small className="text-danger">{t(errors.rating)}</small>
							</div>
							<div className="text-center">
								<StarRatingComponent name="rating" starCount={5} value={values.rating} onStarClick={handleStarClick}/>
							</div>
							<Form.Group controlId="lastName" hasValidation>
								<InputGroup>
									<InputGroup.Prepend>
									<InputGroup.Text><i class="fa fa-user-circle-o" aria-hidden="true"></i></InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control name="username" placeholder={t("username")} onChange={handleChangeFields} isInvalid={!!errors.username}/>
									<Form.Control.Feedback type="invalid">{t(errors.username)}</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>
							<Form.Group controlId="title" hasValidation>
								<InputGroup>
									<InputGroup.Prepend>
									<InputGroup.Text><i class="fa fa-pencil-square-o" aria-hidden="true"></i></InputGroup.Text>
									</InputGroup.Prepend>
									<Form.Control name="title" placeholder={t('title')} onChange={handleChangeFields} isInvalid={!!errors.title}/>
								</InputGroup>
								<Form.Control.Feedback type="invalid">{t(errors.title)}</Form.Control.Feedback>
							</Form.Group>
							<Form.Group controlId="content" hasValidation>
								<Form.Control as="textarea" placeholder={t('content')} name="content" onChange={handleChangeFields} isInvalid={!!errors.content}/>
								<Form.Control.Feedback type="invalid">{t(errors.content)}</Form.Control.Feedback>
							</Form.Group>
						</Form>
						{
							alert.length >0 && <Alert className="mt-3" variant="danger">
								{t(alert)}
							</Alert>
						}
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t("cancel")}
						</Button>
						<Button variant="primary" onClick={handleSave}>
							{t('submit')}
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
    </>
)
};
export default withTranslation()(Reviews);