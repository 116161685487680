import {HTTP_HEADER} from '../constants/http'
import {ROOT_URL} from '../config'
import * as authConstants from '../constants/auth'
import {fetchJson} from '../utils/fetch'
import format from 'string-format'

const APLLICANT_API_V1 = {
    create: "/api/applicants/v1",
    isApplied: "/api/applicants/v1/applied"
}
function create(dto) {
    return fetchJson(ROOT_URL + APLLICANT_API_V1.create, {
        method: 'POST',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN),
        body: JSON.stringify(dto)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};
async function isApplied(resumeId) {
    if (!resumeId)
        return false;
    //Check in localStorage first
    let resObj = await fetchJson(ROOT_URL + APLLICANT_API_V1.isApplied+"?resumeId="+resumeId, {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN),
    });
    if (resObj.status === "OK") {
        return resObj.data;
    }
    //console.log(resObj);
    /*.then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });*/
    return false;
};
export default {
    create,
    isApplied
}