import React from 'react';
import moment from 'moment';
import StarRatingComponent from 'react-star-rating-component';
const ReviewListItem = ({item}) => {
    return (
        <li>
        <div className="boxed-list-item">
            <div className="item-content">
                <h4>{item.title} <span>{item.username? item.username:"Anonymous"}</span></h4>
                <div className="item-details margin-top-10">
                    <div className="star-rating" data-rating={item.rating}>
                        <StarRatingComponent name="rating" starCount={5} value={item.rating}/>
                    </div>
                    <div className="detail-item"><i className="icon-material-outline-date-range"></i> {moment(item.createdAt).format('YYYY-MM-DD')}</div>
                </div>
                <div className="item-description">
                    <p>{item.content}</p>
                </div>
            </div>
        </div>
        </li>
    );
};
export default ReviewListItem;