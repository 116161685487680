import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import {SUPPORT_LANGUAGES} from '../constants';
import languageService from '../services/LanguageService';
function getYear () {
	return new Date().getFullYear()
}
const Footer = ({t ,i18n}) => {
	const [language, setLanguage] = useState({code: "vi" , name: "vietnamese"});

	const changeLanguage = (lang,e) => {
		e.preventDefault();
		setLanguage(lang);
		i18n.changeLanguage(lang.code);

	};
	useEffect(() => {
		let langCode = languageService.readLang();
		const rs = SUPPORT_LANGUAGES.find(item => item.code === langCode);
		if (rs) {
			setLanguage(rs);
		}
	},[])
	return (
    <footer class="footer">
		<div class="footer_content">
			<div class="container">
				<div class="row">

					{/*<!-- Footer About -->*/}
					<div class="col-lg-3 footer_col">
						<div class="footer_about">
							<div class="footer_logo">
								<a href="#">
									<div>Top<span>WORKS</span></div>
									<div>{t('change_your_life')}</div>
								</a>
							</div>
							<div class="footer_about_text">
								<p>{t('footer_intro_short')}</p>
							</div>
							<div class="footer_social mt-2">
								<div className="d-flex flex-row align-items-center justify-content-start">
									<a href="https://www.facebook.com/TopWorksVN" target="_blank"><i className="fa fa-facebook-square fa-2x" aria-hidden="true"></i></a>
									<a href="https://twitter.com/TopWorksVN" target="_blank"><i className="fa fa-twitter-square fa-2x ml-1" aria-hidden="true"></i></a>
									<a href="https://www.linkedin.com/in/top-works-50569320b" target="_blank"><i className="fa fa-linkedin-square fa-2x ml-1" aria-hidden="true"></i></a>
									<a href="https://www.youtube.com/channel/UC1yehWDpoKyzvdgtEsbXtJg" target="_blank"><i className="fa fa-youtube-square fa-2x ml-1" aria-hidden="true"></i></a>
								</div>
							</div>
						</div>
					</div>

					{/*<!-- Footer Contact Info -->*/}
					

					{/*<!-- Footer Locations -->*/}
					<div class="col-lg-3 footer_col">
						<div class="footer_contact">
							<div class="footer_title">{t('links')}</div>
							<ul class="contact_list">
								<li><a href="/products">{t('products')}</a></li>
								<li><a href="/promotions">{t('promotions')}</a></li>
								<li><a href="/jobs/it-viec">IT Viec</a></li>
								<li><a href="/jobs/top-dev">Top Dev</a></li>
								<li><a href="/jobs/vietnam-works">Vietnamworks</a></li>
								<li><a href="/numerology">{t('numerology')}</a></li>
								<li><a href="/companies">{t('companies')}</a></li>
								<li><a href="/terms">{t('terms_conditions')}</a></li>
								<li><a href="/privacy">{t('privacy')}</a></li>
								<li><a href="/sitemap.xml">Sitemap</a></li>
							</ul>
						</div>
					</div>
					<div class="col-lg-3 footer_col">
						<div class="footer_contact">
							<div class="footer_title">{t('contact')}</div>
							<ul class="contact_list">
								<li>+8490 866 77 96</li>
								<li>locvuk@gmail.com</li>
								<li>+84 982 43 71 72</li>
								<li>vohoaiviet@yahoo.com</li>
							</ul>
							<ul class="contact_list">
								<li><a href="/contact">{t('contact')}</a></li>
								<li><a href="/about">{t('about_us')}</a></li>
								<li><a href="/helps">{t('helps')}</a></li>

							</ul>
						</div>
						
					</div>
					{/*<!-- Footer Opening Hours -->*/}
					<div class="col-lg-3 footer_col">
						
						<div class="opening_hours">
							<div class="footer_title">{t('support_time')}</div>
							<ul class="opening_hours_list">
								<li class="d-flex flex-row align-items-start justify-content-start">
									<div>{t('monday') + ":"}</div>
									<div class="ml-auto">8:00am - 9:00pm</div>
								</li>
								<li class="d-flex flex-row align-items-start justify-content-start">
									<div>{t('tuesday') + ":"}</div>
									<div class="ml-auto">8:00am - 9:00pm</div>
								</li>
								<li class="d-flex flex-row align-items-start justify-content-start">
									<div>{t('wednesday') + ":"}</div>
									<div class="ml-auto">8:00am - 9:00pm</div>
								</li>
								<li class="d-flex flex-row align-items-start justify-content-start">
									<div>{t('thursday') + ":"}</div>
									<div class="ml-auto">8:00am - 9:00pm</div>
								</li>
								<li class="d-flex flex-row align-items-start justify-content-start">
									<div>{t('friday') + ":"}</div>
									<div class="ml-auto">8:00am - 7:00pm</div>
								</li>
							</ul>
						</div>
						<div className="mt-3">
							<Dropdown>
								<Dropdown.Toggle>
									<i className="fa fa-globe"></i> {t(language.name)}
								</Dropdown.Toggle>
								<Dropdown.Menu>
										{
											SUPPORT_LANGUAGES.map((item,idx) => {
												if (item.code === language.code) {
													return <Dropdown.Item className="d-flex active" onClick={ (e) => changeLanguage(item,e)}><img src={item.flag} with="30" height="30" className="d-inline-block align-top mr-2" alt={t(item.name)}/><div className="my-auto">{t(item.name)}</div></Dropdown.Item>
												}
												return <Dropdown.Item className="d-flex" onClick={ (e) => changeLanguage(item,e)}><img src={item.flag} with="30" height="30" className="d-inline-block align-top mr-2" alt={t(item.name)}/><div className="my-auto">{t(item.name)}</div></Dropdown.Item>

											}) 
										}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
					<div class="col-lg-3 footer_col">
						<div class="footer_location">
							<div class="footer_title">{t('address')}</div>
							<ul class="locations_list">
								<li>
									<div class="location_title">{t('headquarter')}</div>
									<div class="location_text">{t('get_touch_us_address')}</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-lg-3 footer_col">
						<div class="footer_location">
						<div class="fb-page" data-href="https://www.facebook.com/TopWorksVN" data-tabs="timeline" data-width="" data-height="100" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/TopWorksVN" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/TopWorksVN">TopWorks</a></blockquote></div>						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="footer_bar">
			<div class="container">
				<div class="row">
					<div class="col">
						<div class="footer_bar_content  d-flex flex-md-row flex-column align-items-md-center justify-content-start">
							<div class="copyright">
Bản quyền ©<script type="text/javascript" async="" src="https://www.google-analytics.com/analytics.js"></script><script>document.write(new Date().getFullYear());</script>2020. Tất cả được bảo vệ và phát triển bởi <i class="fa fa-heart-o" aria-hidden="true"></i> by <a href="https://toplabs.io" target="_blank">TopLabs</a></div>
							<nav class="footer_nav ml-md-auto">
								<ul class="d-flex flex-row align-items-center justify-content-start">
									<li><a href="/">{t('home')}</a></li>
									<li><a href="/about">{t('about_us')}</a></li>
									<li><a href="/services">{t('services')}</a></li>
									<li><a href="/blogs">{t('news')}</a></li>
									<li><a href="/contact">{t('contact')}</a></li>
								</ul>
							</nav>
						</div>
					</div>
				</div>
			</div>			
		</div>
	</footer>
)
}
export default withTranslation()(Footer)