export const HTTP_HEADER = {
    CLIENT_FINGERPRINT: "X-Zetgoo-Client-Fingerprint",
    CLIENT_ID: "X-Zetgoo-Client-ID",
    AUTHORIZATION: "X-Authorization"

};
export const HTTP_RESPONSE_STATUS = {
    OK: 'OK',
    UNKNOW_ERROR: 'UNKNOW_ERROR',
    INVALID_REQUEST: 'INVALID_REQUEST',
    ZERO_RESULTS: 'ZERO_RESULTS',
    OVER_QUERY_LIMIT: 'OVER_QUERY_LIMIT'
};