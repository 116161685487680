import React from 'react';
import JobListItem from './JobListItem';
import Alert from 'react-bootstrap/Alert';
import {withTranslation} from 'react-i18next';

const JobsList = ({t,items}) => {
    return (
    <>
        {
            items.length === 0 && <Alert className="mt-3" variant="info">
                {t('no_items_found')}
            </Alert>
        }
        {items.map( (item, idx) => (<JobListItem key={idx} item={item} />))}
    </>
)}
export default withTranslation()(JobsList)