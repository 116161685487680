import {ROOT_URL} from '../config';
import * as authConstants from '../constants/auth';
import {fetchJson} from '../utils/fetch';
import format from 'string-format';
import queryString from 'query-string';
const TALENT_API_V1 = {
    getProfile: "/api/talents/v1/{0}/profile",
    getLatestQuote: "/api/talents/v1/{0}/quotes/latest",
    searchAndCount: "/api/talents/v1/search_and_count",
    search: "/api/talents/v1/search",
};
function getProfile(id) {
    return fetchJson(ROOT_URL + format(TALENT_API_V1.getProfile, id), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
function getLatestQuote(id) {
    return fetchJson(ROOT_URL + format(TALENT_API_V1.getLatestQuote, id), {
        method: 'GET',
        authToken: authConstants.AUTH_TYPE_BEARER + localStorage.getItem(authConstants.AUTH_TOKEN)
      }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
}
export default {
    getProfile,
    getLatestQuote
}