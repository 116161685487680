import React from 'react';
const SimilarJobItem = () => {
    return (
        <>
            <a href="/jobs/details" class="job-listing">

                <div class="job-listing-details">
                        <img class="job-listing-company-logo" src="/images/company-logo-02.png" alt=""/>

                    <div class="job-listing-description">
                        <h4 class="job-listing-company">Coffee</h4>
                        <h3 class="job-listing-title">Barista and Cashier</h3>
                    </div>
                </div>

                <div class="job-listing-footer">
                    <ul>
                        <li><i class="fa fa-map-marker" aria-hidden="true"></i> San Francisco</li>
                        <li><i class="fa fa-briefcase"></i> Full Time</li>
                        <li><i class="fa fa-money"></i> $35000-$38000</li>
                        <li><i class="fa fa-clock-o"></i> 2 days ago</li>
                    </ul>
                </div>
            </a>
        </>
    );
}
export default SimilarJobItem