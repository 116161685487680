import React from 'react';
import { withTranslation } from "react-i18next";
const WorkHistory = ({t, items}) => {
    return (
        <>
            <div class="boxed-list-headline">
                <h3><i class="icon-material-outline-thumb-up"></i> Work History and Feedback</h3>
            </div>
            <ul class="boxed-list-ul">
                <li>
                    <div class="boxed-list-item">
                        <div class="item-content">
                            <h4>Web, Database and API Developer <span>Rated as Freelancer</span></h4>
                            <div class="item-details margin-top-10">
                                <div class="star-rating" data-rating="5.0"><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span></div>
                                <div class="detail-item"><i class="icon-material-outline-date-range"></i> August 2019</div>
                            </div>
                            <div class="item-description">
                                <p>Excellent programmer - fully carried out my project in a very professional manner. </p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="boxed-list-item">
                        <div class="item-content">
                            <h4>WordPress Theme Installation <span>Rated as Freelancer</span></h4>
                            <div class="item-details margin-top-10">
                                <div class="star-rating" data-rating="5.0"><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span></div>
                                <div class="detail-item"><i class="icon-material-outline-date-range"></i> June 2019</div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="boxed-list-item">
                        <div class="item-content">
                            <h4>Fix Python Selenium Code <span>Rated as Employer</span></h4>
                            <div class="item-details margin-top-10">
                                <div class="star-rating" data-rating="5.0"><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span></div>
                                <div class="detail-item"><i class="icon-material-outline-date-range"></i> May 2019</div>
                            </div>
                            <div class="item-description">
                                <p>I was extremely impressed with the quality of work AND how quickly he got it done. He then offered to help with another side part of the project that we didn't even think about originally.</p>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="boxed-list-item">
                        <div class="item-content">
                            <h4>PHP Core Website Fixes <span>Rated as Freelancer</span></h4>
                            <div class="item-details margin-top-10">
                                <div class="star-rating" data-rating="5.0"><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span><span class="star"></span></div>
                                <div class="detail-item"><i class="icon-material-outline-date-range"></i> May 2019</div>
                            </div>
                            <div class="item-description">
                                <p>Awesome work, definitely will rehire. Poject was completed not only with the requirements, but on time, within our small budget.</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="clearfix"></div>
            <div class="pagination-container margin-top-40 margin-bottom-10">
                <nav class="pagination">
                    <ul>
                        <li><a href="#" class="ripple-effect current-page">1</a></li>
                        <li><a href="#" class="ripple-effect">2</a></li>
                        <li class="pagination-arrow"><a href="#" class="ripple-effect"><i class="icon-material-outline-keyboard-arrow-right"></i></a></li>
                    </ul>
                </nav>
            </div>
            <div class="clearfix"></div>
        </>
    );
}
export default withTranslation()(WorkHistory)