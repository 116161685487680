import React, { useState, useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import Pagination from '../../components/Pagination';
import LetterList from './components/LetterList';
import { useHistory, useLocation } from 'react-router-dom';
import companyService from '../../services/CompanyService';
import {HTTP_RESPONSE_STATUS} from '../../constants/http';
import CompanyList from './components/CompanyList';
import Alert from 'react-bootstrap/Alert';
const LETTER_LIST = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I' , 'J',
    'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
];
const Companies = ({t}) => {
    const history = useHistory();
    const search = useLocation().search;
    const [items, setItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams, setSearchParams] = useState({});
    const [pageSize, setPageSize] = useState(6);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        //Fetch total & count.
        setIsLoading(true);
        companyService.searchAndCount({page: currentPage, size: pageSize})
        .then(resObj => {
            setIsLoading(false);
            console.log(resObj);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setItems([...resObj.data.result]);
                    setCurrentPage(resObj.data.page);
                    setPageSize(resObj.data.size);
                    setTotalPages(Math.ceil(resObj.data.total / resObj.data.size));
                } else {
                    setItems([]);
                    setCurrentPage(0);
                    setTotalPages(0);
                }
            }
        })
        .catch(err => {
            setItems([]);
            setCurrentPage(0);
            setTotalPages(0);
            setIsLoading(false);
        });

		///Consider query from param status, pages, size, 
	}, []);
    const handleChangeFields = (e) => {
        searchParams[e.target.name] = e.target.value;
        setSearchParams({...searchParams});
    };
    const handleChangeLetter = (e, value) => {
        e.preventDefault();
        searchParams['letter'] = value;
        searchParams['page'] = 1;
        searchParams['size'] = pageSize;
        setSearchParams({...searchParams});
        const params = new URLSearchParams(searchParams);
        history.push({search: params.toString()});
    };
    const onPageChanged = (e, page) => {
        e.preventDefault();
        setCurrentPage(page);
        ///Call
        /*let query = queryString.stringify({...searchParams, page: page, size: pageSize},
            {
                skipEmptyString: true,
                skipNull: true
            });*/
        setIsLoading(true);
        companyService.search({...searchParams, page: page, size: pageSize})
        .then(resObj => {
            setIsLoading(false);
            if (resObj.status === HTTP_RESPONSE_STATUS.OK) {
                if (resObj.data) {
                    setItems(resObj.data);
                } else {
                    setItems([]);
                }
            }
        })
        .catch(err => {
            setItems([]);
            setIsLoading(false);
        });
        history.push("/companies?" + new URLSearchParams({...searchParams, page: page, size: pageSize}).toString());
    };
    
    return (
        <>
            <div className="header_wrapper"></div>
            <div className="clearfix"></div>
            <div id="titlebar" className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>{t('companies_browse')}</h2>
                            <nav id="breadcrumbs" className="dark">
                                <ul>
                                    <li><a href="/">{t('home')}</a></li>
                                    <li>{t('companies')}</li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <LetterList items={LETTER_LIST} onSelect={handleChangeLetter}/>
                    </div>
                    <div className="col-xl-12">
                        <CompanyList items={items} />
                    </div>
                </div>
                <div className="clearfix"></div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pagination-container margin-top-10 margin-bottom-20">
                                <Pagination limit={2} onPageChanged={onPageChanged} activePage={currentPage} pages={totalPages}/>
                            </div>
                        </div>
                </div>
            </div>
        </>
    )
}
export default withTranslation()(Companies)