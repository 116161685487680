import React from 'react';
const JobCategryItem = ({item}) => {
    return (
        <>
            <a href="/jobs/finder" className="photo-box small" data-background-image={item.photoUrl} style={{backgroundImage: 'url(' + item.photoUrl +')'}}>
                <div class="photo-box-content">
                    <h3>{item.name}</h3>
                    <span>{item.numOfJobs}</span>
                </div>
			</a>
        </>
    );
}
export default JobCategryItem