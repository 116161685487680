import { withTranslation } from 'react-i18next';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
const FAQ = ({t}) => {
    return (
        <>
            <div className="header_wrapper"></div>
            <div className="clearfix"></div>
            <div id="titlebar" className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <h2>{t('faqs')}</h2>

                            <nav id="breadcrumbs" className="dark">
                                <ul>
                                    <li><a href="/">{t('home')}</a></li>
                                    <li>{t('faqs')}</li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
            <div className="faq">
                <div className="container">
                    <div className="faq-list">
                    <ListGroup as="ul"> 
                    <Accordion defaultActiveKey="0"> 
                        <ListGroup.Item as="li" className="border-0 mb-2">
                            <Accordion.Toggle as="div" variant="link" eventKey="0" className="my-auto d-flex">
                                <i className="fa fa-question-circle-o"></i><a>Đăng ký tài khoản</a>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <div className="pt-1">
                                    Để đăng ký tài khoản bạn cần một địa chỉ email. Email được sử dụng như là tên đăng nhập vào website. Lưu ý rằng bạn nên đặt mật khẩu đủ khó để đảm bảo tính an toàn khi sử dụng hệ thống.
                                </div>
                            </Accordion.Collapse>
                            
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="border-0 mb-2">
                            <Accordion.Toggle as="div" variant="link" eventKey="1" className="my-auto d-flex">
                                <i className="fa fa-question-circle-o"></i><a>Mua hàng online</a>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <div className="pt-1">
                                    Để mua hàng online, trước tiên bạn cần tạo một tài khoản trên hệ thông của chúng tôi. Sau đó, bạn cỏ thể tìm kiếm sản phẩm phù hợp để đặt hàng, thanh toán và nhận sản phẩm của mình.
                                </div>
                            </Accordion.Collapse>
                            
                        </ListGroup.Item>
                        </Accordion>

                    </ListGroup>
                    </div>
                </div>
            </div> 
        </>
    );
};
export default withTranslation() (FAQ);