import numeral from 'numeral';
import moment from 'moment';
export function formatName(lastName, middleName, firstName, language) {
    if (language === "vi") {
        let rs = (lastName?lastName: "") + " " + (middleName?middleName: "") + " " + (firstName?firstName: "");
        return rs.trim();
    }
    let rs = (firstName?firstName: "") + " " + (middleName?middleName: "") + " " + (lastName?lastName: "");
    return rs.trim();
};
export function formatSalary(from, to, currencyCode) {
    ///numeral(1000).format('0,0');
    let rs = "negotiate";
    if (!from) {
        rs = "Up to"
        if (to) {
            rs += " " + numeral(to).format('0.0a').toUpperCase() + " " + currencyCode;
        } else {
            rs = "negotiate";
        }
    } else {
        rs = numeral(from).format('0.0a').toUpperCase();
        if (to) {
            rs += " - " + numeral(to).format('0.0a').toUpperCase() + " " + currencyCode;
        }
    }
    return rs;
};
/**
 * 
 * @param {*} errors 
 */
export function convertErrors2Object(errors){
    let vals = {}
    Object.keys(errors).forEach(key => {
        vals[key] = errors[key][0];
    });
    return vals;
}
export function createErrorsFromErrorCodes(map, errors){
    let vals = {}
    Object.keys(errors).forEach(key => {
        if (map[key]) {
            vals[map[key].field] = map[key].message;
        }
    });
    return vals;
}

export function formatPublishedAt(date) {
    if (!date) {
        return 'published_at_today'
    }
    //2019-03-17T07:00:00.000Z
    let publishedAt = moment(date,moment.ISO_8601)
    let now = moment()
    let diff = now.diff(publishedAt, 'days')
    if (diff < 1)
    {
        return 'published_at_today';
    }
    if (diff == 1) {
        return 'published_at_1_day_ago';
    }
    if (diff <= 2)
        return 'published_at_2_days_ago';
    if (diff <= 3) {
        return 'published_at_3_days_ago';
    }
    if (diff == 7)
        return 'published_at_7_days_ago';
    if (diff >= 30 && diff <=32) 
        return 'published_at_month_ago';
    return publishedAt.format('DD/MM/YYYY');
}