import React, { useState, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import * as utils from '../../utils';
import * as constants from "../../constants";
import {HTTP_RESPONSE_STATUS} from '../../constants/http';
import talentService from '../../services/TalentService';
import countryService from '../../services/CountryService';
import { useHistory } from 'react-router-dom';
import EmployeeHistory from './components/EmployeeHistory';
import EducationHistory from './components/EducationHistory';
import WorkHistory from './components/WorkHistory';
import Skills from './components/Skills';
import Languages from './components/Languages';
import StarRatingComponent from 'react-star-rating-component';
const Profile = ({t, match}) => {
    const history = useHistory();
    const [details, setDetails] = useState({});
    const [quote, setQuote] = useState({});
    const [country, setCountry] = useState({});
    const loadDetails = () => {
        talentService.getProfile(match.params.id)
		.then( resObj => {
			if (resObj.status === "OK") {
                setDetails(resObj.data);
                loadLatestQuote();
                countryService.getCountry(resObj.data.countryId)
                .then(rs => {
					setCountry(rs.data);
                })
			} else {
                if (resObj.status === HTTP_RESPONSE_STATUS.ZERO_RESULTS) {
                    history.push('/404')
                }
            }
		})
		.catch (err => {

		});
    };
    const loadLatestQuote = () => {
        talentService.getLatestQuote(match.params.id)
		.then( resObj => {
			if (resObj.status === "OK") {
                setDetails(resObj.data);
			}
		})
		.catch (err => {

		});
    };
    useEffect( () => {
        loadDetails();
    }, []);
    return(
        <>
            <div class="single-page-header freelancer-header" data-background-image="/images/single-freelancer.jpg">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="single-page-header-inner">
                                <div class="left-side">
                                    <div class="header-image freelancer-avatar"><img src="/images/user-avatar-big-02.jpg" alt=""/></div>
                                    <div class="header-details">
                                        <h3>{details.firstName + " " + details.lastName} <span>{details.title?details.title:""}</span></h3>
                                        <ul>
                                            <li>
                                                <div className="star-rating" data-rating={details.numOfReviews > 0? ((details.sumOfRating/details.numOfReviews).toFixed(2)): 0}>
                                                <StarRatingComponent name="rating" starCount={5} value={details.numOfReviews > 0? ((details.sumOfRating/details.numOfReviews).toFixed(2)): 0}/>
                                                </div>
                                            </li>
                                            <li><img className="flag" src={"/images/flags/"+ country.code+".svg"} alt={country.name} loading="lazy"/>{country.name}</li>
                                            {
                                                details.verified === true &&
                                                <li><div className="verified-badge-with-title">Verified</div></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="background-image-container" style={{backgroundImage: "url(/images/single-freelancer.jpg)"}}></div>
            </div>
            <div class="container">
                <div class="row">		
                    <div class="col-xl-8 col-lg-8 content-right-offset">			
                        <div class="single-page-section">
                            <h3 class="margin-bottom-25">{t('about_me')}</h3>
                            <div dangerouslySetInnerHTML={{__html: details.overview}} />
                        </div>
                        {/*<div class="boxed-list margin-bottom-60">
                            <WorkHistory />
                        </div>	*/}		
                        <div class="boxed-list margin-bottom-60">
                            <EmployeeHistory items={details.experiences?details.experiences: []}/>
                        </div>
                        <div class="boxed-list margin-bottom-60">
                            <EducationHistory items={details.educations?details.educations: []}/>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4">
                        <div className="sidebar-container">
                            {
                                details.freelancerStatus == 2 && 
                                <>
                                    <div className="profile-overview">
                                        <div className="overview-item"><strong>{details.hourWageMin? (details.hourWageMin + " " + (details.currencyCode?details.currencyCode: "USD")): t("negotiate") }</strong><span>{t('freelancer_hourly_rate')}</span></div>
                                        <div className="overview-item"><strong>{quote.doneTaskCount?quote.doneTaskCount: 0}</strong><span>{t('freelancer_jobs_done')}</span></div>
                                        <div className="overview-item"><strong>{quote.rehiredCount?quote.rehiredCount: 0}</strong><span>{t('freelancer_rehired')}</span></div>
                                    </div>
                                    <a href="" className="apply-now-button popup-with-zoom-anim margin-bottom-50">Make an Offer <i class="icon-material-outline-arrow-right-alt"></i></a>
                                    <div className="sidebar-widget">
                                        <div className="freelancer-indicators">
                                            <div className="indicator">
                                                <strong>{ (quote.jobSuccessRate?quote.jobSuccessRate: 0) + "%"}</strong>
                                                <div className="indicator-bar" data-indicator-percentage={quote.jobSuccessRate?quote.jobSuccessRate: 0}><span style={{width: (quote.jobSuccessRate?quote.jobSuccessRate: 0)+"%"}}></span></div>
                                                <span>{t('freelancer_jobs_success')}</span>
                                            </div>
                                            <div className="indicator">
                                                <strong>{ (quote.recommendationRate?quote.recommendationRate: 0) + "%"}</strong>
                                                <div className="indicator-bar" data-indicator-percentage={(quote.recommendationRate?quote.recommendationRate: 0)}><span style={{width: (quote.recommendationRate?quote.recommendationRate: 0)+"%"}}></span></div>
                                                <span>{t('freelancer_jobs_recommendation')}</span>
                                            </div>						
                                            <div className="indicator">
                                                <strong>{ (quote.onTimeRate?quote.onTimeRate: 0) + "%"}</strong>
                                                <div className="indicator-bar" data-indicator-percentage={(quote.onTimeRate?quote.onTimeRate: 0)}><span style={{width: (quote.onTimeRate?quote.onTimeRate: 0)+"%"}}></span></div>
                                                <span>{t('freelancer_jobs_on_time')}</span>
                                            </div>												
                                            <div className="indicator">
                                                <strong>{ (quote.onBudgetRate?quote.onBudgetRate: 0) + "%"}</strong>
                                                <div className="indicator-bar" data-indicator-percentage={(quote.onBudgetRate?quote.onBudgetRate: 0)}><span style={{width: (quote.onBudgetRate?quote.onBudgetRate: 0)+"%"}}></span></div>
                                                <span>{t('freelancer_jobs_on_budget')}</span>
                                            </div>
                                        </div>
                                    </div>	
                                </>
                            }			
                            			
                            {/*<div class="sidebar-widget">
                                <h3>Social Profiles</h3>
                                <div class="freelancer-socials margin-top-25">
                                    <ul>
                                        <li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="Dribbble"><i class="icon-brand-dribbble"></i></a></li>
                                        <li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="Twitter"><i class="icon-brand-twitter"></i></a></li>
                                        <li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="Behance"><i class="icon-brand-behance"></i></a></li>
                                        <li><a href="#" data-tippy-placement="top" data-tippy="" data-original-title="GitHub"><i class="icon-brand-github"></i></a></li>
                                    
                                    </ul>
                                </div>
                            </div>*/}
                            <div class="sidebar-widget">
                                <Skills items={details.skills?details.skills: []}/>
                            </div>
                            <div class="sidebar-widget">
                                <Languages items={details.languages?details.languages: []}/>
                            </div>
                            {/*<div class="sidebar-widget">
                                <h3>Attachments</h3>
                                <div class="attachments-container">
                                    <a href="#" class="attachment-box ripple-effect"><span>Cover Letter</span><i>PDF</i></a>
                                    <a href="#" class="attachment-box ripple-effect"><span>Contract</span><i>DOCX</i></a>
                                </div>
                            </div>
                            <div class="sidebar-widget">
                                <h3>Bookmark or Share</h3>
                                <button class="bookmark-button margin-bottom-25">
                                    <span class="bookmark-icon"></span>
                                    <span class="bookmark-text">Bookmark</span>
                                    <span class="bookmarked-text">Bookmarked</span>
                                </button>
                                <div class="copy-url">
                                    <input id="copy-url" type="text" value="" class="with-border"/>
                                    <button class="copy-url-button ripple-effect" data-clipboard-target="#copy-url" data-tippy-placement="top" data-tippy="" data-original-title="Copy to Clipboard"><i class="icon-material-outline-file-copy"></i></button>
                                </div>
                                <div class="share-buttons margin-top-25">
                                    <div class="share-buttons-trigger"><i class="icon-feather-share-2"></i></div>
                                    <div class="share-buttons-content">
                                        <span>Interesting? <strong>Share It!</strong></span>
                                        <ul class="share-buttons-icons">
                                            <li><a href="#" data-button-color="#3b5998" data-tippy-placement="top" data-tippy="" data-original-title="Share on Facebook" style={{backgroundColor: "rgb(59, 89, 152)"}}><i class="icon-brand-facebook-f"></i></a></li>
                                            <li><a href="#" data-button-color="#1da1f2" data-tippy-placement="top" data-tippy="" data-original-title="Share on Twitter" style={{backgroundColor: "rgb(29, 161, 242)"}}><i class="icon-brand-twitter"></i></a></li>
                                            <li><a href="#" data-button-color="#dd4b39" data-tippy-placement="top" data-tippy="" data-original-title="Share on Google Plus" style={{backgroundColor: "rgb(221, 75, 57)"}}><i class="icon-brand-google-plus-g"></i></a></li>
                                            <li><a href="#" data-button-color="#0077b5" data-tippy-placement="top" data-tippy="" data-original-title="Share on LinkedIn" style={{backgroundColor: "rgb(0, 119, 181)"}}><i class="icon-brand-linkedin-in"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>*/}
    
                        </div>
                    </div>
    
                </div>
            </div>
        </>
    )
} 
export default withTranslation()(Profile);
/***Ref
 * https://bootstrapmade.com/demo/Laura/
 * https://bootstrapmade.com/demo/iPortfolio/
 */